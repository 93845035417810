import request from "@/utils/request"

export default class ApiOrder {
  // 获取定制订单列表
  static getOrderList(params) {
    return request({
      url: "api/v2/customized/order/userlist",
      method: "GET",
      params
    })
  }
  // 获取订单列表
  static getOrderListNew(params) {
    return request({
      url: "api/v2/order/all/list",
      method: "GET",
      params
    })
  }
  // 获取订单详情
  static getOrderDetail(id,otherParams) {
    return request({
      url: "api/v2/customized/order/info/" + id,
      method: "GET",
      params: {
        noAuth: true,
        ...otherParams
      }
    })
  }
  // 获取订单详情
  static getNotCustomOrderDetail(id,otherParams) {
    return request({
      url: "api/order/detail/" + id,
      method: "GET",
      params: {
        noAuth: true,
        ...otherParams
      }
    })
  }
  // 获取橱窗列表
  static getShopWindowList(params) {
    return request({
      url: "api/v2/user/design/list",
      method: "GET",
      params
    })
  }
  // 删除橱窗商品
  static delShopWindowItem(id) {
    return request({
      url: "api/v2/user/design/del/" + id,
      method: "DELETE"
    })
  }

  /**
   * 加入购物车
   * @param {object} data
   * @param {number} data.productId 商品id
   * @param {number} data.cartNum 商品数量
   * @param {number} data.new 0：加购，1：立即下单
   * @param {array} data.parts_attr_val_ids 选配的属性id
   * @param {array} data.measure_record_id 测量报告
   * @param {string} data.uniqueId 多规格属性唯一值
   *
   */
  static addShopCar(data) {
    return request({
      url: "api/cart/add",
      method: "POST",
      data
    })
  }
  /**
   * 计算订单价格
   * @param {object} data orderCreateNew
   */
  static computedOrderNew(data) {
    return request({
      url: "api/order/computed_new",
      method: "POST",
      data
    })
  }
  /**
   * 创建订单
   * @param {object} data
   */
  static orderCreateNew(data) {
    return request({
      url: "api/order/create_new",
      method: "POST",
      data
    })
  }
  /**
   * @description 定制单取消支付
   * @param data { object }
   * @param data.id { number } 订单id
   *
   * */
  static cancelCustomOrder(data) {
    return request({
      url: "api/v2/order/customized/cancelPay/" + data.id,
      method: "PUT",
      data
    })
  }

  /**
   * @description 标准单取消支付
   * @param data { object }
   * @param data.id { number } 订单id
   *
   * */
  static cancelNotCustomOrder(data) {
    return request({
      url: "api/order/cancel",
      method: "POST",
      data
    })
  }

  /**
   * 获取购物车列表
   * @param {object} params
   */
  static getShopCarList(params) {
    return request({
      url: "api/cart/list",
      method: "GET",
      params
    })
  }

  /**
   * 对购物车商品数量加减
   * @param {object} data
   * @param {number} data.id 购物车id
   * @param {number} data.number
   */
  static updateShopCartNum(data) {
    return request({
      url: "api/cart/num",
      method: "POST",
      data
    })
  }

  /**
   * 删除购物车商品
   * @param {array} ids 删除的id数组
   */
  static deleteShopCar(idArr) {
    const ids = idArr.join(",")
    return request({
      url: "api/cart/del",
      method: "POST",
      data: { ids }
    })
  }
  /**
   * 重选购物车商品
   * @param { object } data
   * @param { number } data.id  购物车id
   * @param { number } data.product_id  商品id
   * @param { number } data.num  数量
   * @param { string } data.unique  商品规格唯一值
   * @param { number } data.type  修改类型
   */
  static changeShopCarGoods(data) {
    return request({
      url: "api/v2/reset_cart",
      method: "POST",
      data
    })
  }

  /**
   * 查询物流信息
   * @param params
   */
  static getExpressInfo(params) {
    return request({
      url: "api/order/express/" + params.orderId,
      method: "GET"
    })
  }

  /**
   * paypal 下单支付
   * @param data
   */
  static paypalCheckout(data) {
    return request({
      url: "api/v2/braintree/checkout",
      method: "POST",
      data
    })
  }

  /**
   * paypal 创建订单
   * @param data
   * @param data.amount { number } 订单金额
   * @param data.order_id { number } 订单号
   */
  static paypalCreatedOrder(data) {
    return request({
      url: "api/v2/braintree/createOrder",
      method: "POST",
      isTranslate: false,
      data
    })
  }
  /**
   * paypal 捕获支付订单
   * @param data
   * @param data.id { string } 支付订单id
   * @param data.order_id { string } 后台创建订单id
   * @param data.type { number } 交易类型(10:订单支付，20:充值支付)
   */
  static paypalCaptureOrder(data) {
    return request({
      url: "api/v2/braintree/captureOrder",
      method: "POST",
      data
    })
  }
  /**
   * paypal 定制单确认收货
   * @data data
   */
  static confirmReceiptForCustom(data) {
    return request({
      url: "api/v2/customized/order/take",
      method: "PUT",
      data
    })
  }
  /**
   * @description paypal 标准款确认收货
   * @data data
   */
  static confirmReceiptForNotCustom(data) {
    return request({
      url: "api/order/take",
      method: "POST",
      data
    })
  }

  /**
   * @description 支持的快递公司编号列表
   * @param params { object }
   * @param params.company { string }  快递公司名称: 搜索参数
   * @param params.is_page  { number } 是否分页 0 : 1
   * @param params.page { number }   页码 1
   * @param params.[limit || per_page] { number }  每页数据量 10
   */
  static getExpressList(params) {
    return request({
      url: "api/v2/order/express/companyCode",
      method: "GET",
      params
    })
  }
  /**
   * @description 快递查询地图轨迹
   * @param data { object }
   * @param data.com { string } 快递公司编号
   * @param data.num { string } 快递单号
   * @param data.from { string } 发货地址
   * @param data.to { string } 收货地址
   * @param data.phone { string } 手机号
   */
  static getExpressMapQuery(data) {
    return request({
      url: "api/v2/order/express/mapQuery",
      method: "POST",
      data
    })
  }
  // 获取后台设置的发货地址
  static getSenderAddress(params) {
    return request({
      url: "api/v2/system/getSenderAddress",
      method: "GET",
      params
    })
  }
  /**
   * @description 修改购物车商品的测量报告
   * @param data { object }
   * @param data.cart_id { number } 购物车id
   * @param data.measure_record_id { number } 足部测量报告id
   * @param data.step_record_id { number } 步态测量报告id
   *
   * */
  static setReportForCart(data) {
    return request({
      url: "api/cart/report_edit",
      method: "POST",
      data
    })
  }
  /**
   * @description 待支付订单立即支付
   * @param data { object }
   * @param data.uni { number } 订单id
   * @param data.paytype { number } 支付方式类型
   *
   * */
  static orderPay(data){
    return request({
      url: "api/order/pay",
      method: "POST",
      data
    })
  }

  /**
   * @description 退换货
   * @param data { object }
   * @param data.id { number } 订单id
   * @param data.text { string } 订单退货描述
   * @param data.refund_reason_wap_img { array } 订单退货商品凭证
   * @param data.refund_type { string } 服务类型
   * @param data.refund_price { string } 退款价格
   * @param data.uni { string } 订单orderId
   * @param data.exchange_attr { string }  订单换货规格
   * @param data.cart_ids { string }  订单购物车id
   *
   * */
  static returnGoodsSubmit(data){
    return request({
      url: "api/order/refund/apply/" + data.id,
      method: "POST",
      data
    })
  }

  /**
   * @description 申请维修
   * @param data { object }
   * @param data.store_order_id { number } 订单id
   * @param data.cart_id { number } 购物车id
   * @param data.repair_reason { string } 维修原因
   * @param data.img { array } 维修图片凭证
   *
   * */
  static repairGoodsSubmit(data){
    return request({
      url: "api/v2/order/repair/apply",
      method: "POST",
      data
    })
  }

  /**
   * @description 退回物流信息
   * @param data { object }
   * @param data.id { number } 订单id
   * @param data.refund_express { number } 物流单号
   * @param data.refund_express_name { number } 物流公司名称
   *
   * */
  static refundExpress(data){
    return request({
      url: "api/order/refund/express",
      method: "POST",
      data
    })
  }
}
