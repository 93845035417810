import { createRouter, createWebHistory } from "vue-router"
import routes from "@/router/routers"
import caches from "@/utils/caches"
import store from "@/store"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scroll(0, 0)
    return { x: 0, y: 0 }
  }
})

let userInfoPaths = [
  "/user/shopwindow",
  "/user/shop-bag",
  "/user/personal_account/order_list",
  "/user/personal_account/my_report",
  "/user/personal_account/my_report",
  "/user/personal_account/my_account",
  "/user/personal_account/my_delivery_address"
]
router.beforeEach((to, from, next) => {
  let expires_time = caches.getCacheExpiresTimeIsTrue("expires_time") // 过期时间
  if (expires_time) {
    const userInfo = caches.getCache("userInfo")
    userInfo && store.commit("SET_USER_INFO", userInfo)
    next()
  } else {
    caches.removeCache("userInfo")
    if (userInfoPaths.indexOf(to.path) !== -1) {
      const token = caches.getCache("global_token")
      window.$message.warning(token ? "登录已过期,请重新登录" : "请登录后再进行操作")
      store.commit("LOGIN_OUT")
      //caches.removeCache("expires_time")
      //caches.removeCache("global_token")
      caches.removeCache("userInfo")
      setTimeout(() => {
        store.commit("SET_SHOW_PANEL", true)
      }, 800)
      next({ name: "homePage", query: { redirect: to.href } })
    } else {
      next()
    }
  }
})

export default router
