import "core-js/modules/es.number.constructor.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, nextTick } from "vue";
import iconLoading from "@/assets/images/loading-page.json";
export default {
  __name: 'index',
  props: {
    backgroundColor: {
      type: String,
      default: "#fff"
    },
    isFixed: {
      type: Boolean,
      default: true
    },
    loadingText: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    bodyOverflowHidden: {
      type: Boolean,
      default: true
    },
    opacity: {
      type: Number,
      default: 1
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    var _getCurrentInstance = getCurrentInstance(),
      proxy = _getCurrentInstance.proxy;
    var loadingRef = ref(null);
    var animation = null;
    onMounted(function () {
      if (props.isLoading) {
        start();
      }
    });
    onBeforeUnmount(function () {
      animation && animation.destroy();
      animation = null;
    });
    var show = ref(false);
    var start = function start() {
      show.value = true;
      if (props.bodyOverflowHidden) {
        document.body.style.overflow = "hidden";
      }
      if (animation == null) {
        nextTick(function () {
          animation = proxy.$lottie.loadAnimation({
            container: loadingRef.value,
            renderer: "canvas",
            loop: true,
            autoplay: true,
            animationData: iconLoading
          });
        });
      } else {
        animation.play();
      }
    };
    var close = function close() {
      animation.stop();
      show.value = false;
      document.body.style.overflow = "auto";
    };
    expose({
      start: start,
      close: close
    });
    return function (_ctx, _cache) {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "container",
        style: _normalizeStyle({
          backgroundColor: __props.backgroundColor,
          position: __props.isFixed ? 'fixed' : 'absolute',
          opacity: __props.opacity
        })
      }, [_createElementVNode("div", {
        class: "default",
        ref_key: "loadingRef",
        ref: loadingRef
      }, null, 512), _createElementVNode("div", null, _toDisplayString(__props.loadingText), 1)], 4)), [[_vShow, show.value]]);
    };
  }
};