export default {
  monetaryUnit: "￥",
  navMenus: {
    homePage: "トップページ",
    solution: "解決策",
    digitalShoe: "デジタル靴カスタマイズサービス",
    digitalClothing: "デジタル衣類カスタマイズサービス",
    brandDigital: "ブランドデジタルサービス",
    channelDigital: "チャネルデジタル効率化サービス",
    digitalCustomization: "デジタルカスタマイズ",
    UNIRecommended: "UNI推薦",
    brandHigh: "ブランド高定",
    designer: "デザイナーモデル",
    globalDesigner: "グローバルデザイナー",
    ET: "デジタル賦能計画",
    reservationSurvey: "測定の予約をします",
    searchText: "検索します"
  },
  loginModal: {
    memberLogin: "会員登録",
    existingAccountTips: "既にアカウントを登録しています",
    account: "アカウント(携帯/メールアドレス)",
    areaCodeTips: "国際局番をお願いします",
    accountTips: "アカウント入力をお願いします",
    verificationCodeText: "認証コード",
    verificationCodeTextTips: "認証コードを入力してください",
    getCodeText: "認証コードを取得します",
    resend: "再送します",
    loggingTips: "登録ということは受諾ということ",
    privacyPolicy: "プライバシーポリシー",
    loginText: "登録",
    registeredNot: "アカウントを登録したことはありません",
    mainTips: "最初のアカウント認証ログインで自動的にMyUNIアカウントが作成されます",
    checkTips: "オンライン註文と過去の購入履歴を調べます",
    manageTips: "個人情報を管理します",
    adviceTips: "最新のUNI電子相談にアクセスします",
    designsTips: "ショッピングバッグや個人用ウィンドウデザインを保存します"
  },
  userModal: {
    hello: "はじめまして",
    clickPurchaseTips: "「すべての追加購入履歴を確認する」をクリックしてください",
    logOut: "ログアウトします",
    personalItem: "個人情報",
    myShopWindow: "私の窓",
    myOrder: "私の注文",
    myReport: "私の報告書",
    manage: "配送先住所を管理する"
  },
  home: {
    notSupportedVideo: "お使いのブラウザはビデオタグをサポートしていません",
    pickupText1: "靴とアパレル業界の C2M を構築する",
    pickupText2: "フルチェーンのデジタルカスタマイズプラットフォーム",
    secondDesc1: "「一足一ラスト」「一人一バージョン一職人」のフレキシブルなものづくりを実現します",
    secondDesc2: "靴や衣類の大規模なパーソナライズカスタマイズサービスを実現",
    clickLearnMore: "クリックして詳細を確認する",

    pickupFootwearText1: "フットウェアデジタルテクノロジーサービス",
    pickupFootwearText2: "UNI がデジタルシューズとデジタル衣料を製造",
    pickupFootwearText3: "あなたが見たもの、それがあなたの手に入れたものだ",
    pickupFootwearText4: "革新的で成熟したデジタル計測、デジタル 3D、デジタル製造、柔軟な生産のフルリンク デジタル カスタマイズ サービスを提供。",
    costumeTitle: "正確な 3D デザインと衣服のカスタマイズ",
    costumeDesc: "「1人、1バージョン、1クラフト」の正確な衣料品のカスタム製造を実現し、AIを活用したデジタル衣料品、Zhiquが新しいメタバースの世界を生み出す",
    learnMore: "もっと詳しく知る",
    footwearTitle: "正確な 3D デザインと靴のカスタマイズ",
    footwearDesc: "「一足一足」のフレキシブルな靴づくりを実現し、大規模でパーソナライズされた緻密な靴のカスタマイズサービスを実現",
    coreTitle: "コアテクノロジー",
    technologyItem1: "3D精密スキャン",
    technologyItem2: "生物学",
    technologyItem3: "パーソナライゼーション",
    technologyItem4: "3Dオンラインリアルタイムレンダリング",
    technologyItem5: "顔再構成技術",
    technologyItem6: "MRウェア",
    technologyItem7: "自動ハンズ変換",
    technologyItem8: "フレキシブル生産",

    digitalText1: "3Dデジタルデザインプラットフォームがイノベーションの原動力となります",
    digitalText2: "計測データモデルデジタル共有いつでもどこでも共同創作デザイン",
    digitalTopDesc: "設計士のファンに新しい設計の無限の可能性を譲って、3Dはオンラインでリアルタイムにレンダリングして、大量版型、材料の素材はすぐ探して、本当の還元の設計の効果",
    consultation: "協力相談",
    worksWall: "作品の壁",
    stylist: "デザイナー",

    enabling1: "それぞれのブランドにデジタル技術を提供しています",
    enabling2: "富裕層を獲得し、個人専用のワンメイクサービスを実現します。",

    future1: "UNIとデジタル靴・服カスタマイズの未来を創ります"
  },
  solution: {
    experience: "体験申請",
    digitalShoes: "デジタル靴",
    customizedService: "カスタマイズサービス",
    offlineExperience: "一度のオフライン体験でオンライン註文放題",
    smartFootMeasurement: "スマート足のデジタル測定",
    scanning: "hd 3 dスキャン技術サービス",
    footMultiMatrix: "マルチマトリクス再構築技術サービス",
    footThreeD: "足型3Dスマートデジタル化技術",
    footData: "足データのAI分析と応用",
    plantarPressure: "足底圧力分析の数値測定",
    simulation: "バイオメカニクスのシミュレーション",
    highPrecision: "高精度圧力センサー技術",
    intelligent: "動静足動データを知能的に分析します",
    footHealth: "足の健康矯正とカスタムインソールの生成技術",
    supportVideoTips: "ブラウザはvideoタグをサポートしていません",

    digitalDesignDesc: "独自に開発した3Dカスタマイズプラットフォームは、先進的な3D可視化、高速モデリング、リアルタイム追光レンダリング、2D3Dインタラクティブ変換などのコア技術を持ち、デザイン作品の多次元個性化、デジタル創作機能を実現します",
    digitalDesign: "デジタルデザイン",
    multiScreen: "マルチスクリーン共同製作技術",
    humanoid: "デジタル人間メタ宇宙体験技術",
    ar: "リアルタイムAR試着技術",
    digitalIntelligence: "デジタル・インテリジェンス",
    automatic: "自動変換技術",
    automaticOut: "靴から出てくるボール紙技術",
    globalDigital: "グローバル・デジタル・インテリジェンス・調達システム",
    scheduling: "グローバル受注スマート生産システム",
    selfDeveloped: "独自に開発したデジタル製造システムは、ユーザーのデジタル計測データとオンラインデジタルカスタマイズプラットフォームの創作データを、高度な人工知能AI技術によって靴を標準化できるカスタマイズ靴、型紙、カスタマイズ仕様表などのコア製作標準部品に変換し、「一型一脚一型」、「一人一版一工程」のフレキシブルな製作を実現します。大規模な服のカスタマイズを実現します",
    flexibleProduction: "フレキシブル生産"
  },
  digitalClothing: {
    digitalClothingTitle: "デジタル衣料",
    customizedService:"カスタマイズサービス",
    fullScene: "全シーンのデジタル展示、カスタマイズの新しい生態系を再構築します",
    applicationExperience: "体験申請",

    digitalVolume: "デジタルマス",
    volumeAI: "マスAI解析",
    virtualFitting: "AIのバーチャル試着",
    intelligentAiCode: "スマートAIコードリリース",
    digitalAutomatic: "デジタル自動排出",
    flexible: "フレキシブル生産"
  },
  contactUs: {
    contactUsText: "私たちに連絡します",
    submitting: "お申し込み後、専門のオペレーターがご連絡いたします。UNIサービスへのご関心、ありがとうございます",
    countryText: "国",
    checkCountryTips: "国を選びます",
    district: "地域",
    checkDistrictTips: "地域をお願いします",
    type: "タイプ",
    checkTypeTips: "タイプをお願いします",
    brandName: "ブランド名",
    brandNameCheckTips: "ブランド名の入力をお願いします",
    enterprise: "現在の企業規模",
    checkEnterpriseTips: "現在の企業規模を入力していただきます",
    appellation: "呼び方",
    checkAppellationTips: "お名前を入力願います",
    phone: "電話を連絡",
    checkPhoneTips: "ご連絡先を入力願います",
    brandIntroduction: "ブランド概要",
    checkBrandIntroductionTips: "ブランド概要",
    businessProfile: "経営状況の概要",
    checkBusinessProfileTips: "経営状況の概要を入力願います",
    application: "申請を提出",
    dealer: "ディーラー",
    channel: "ルート商",
    applicationApproved:"申し込み成功",
    formFieldsTips: "必須項目を記入してから作業をお願いします"
  },
  applicationModal: {
    contactUsText: "私たちに連絡します",
    submitting: "お申し込み後、専門のオペレーターがご連絡いたします。UNIサービスへのご関心、ありがとうございます",
    countryText: "国",
    checkCountryTips: "国を選びます",
    district: "地域",
    checkDistrictTips: "地域をお願いします",
    enterprise: "企業名",
    checkEnterpriseTips: "企業名を入力願います",
    enterpriseSize: "現在の企業規模",
    checkEnterpriseSizeTips: "現在の企業規模を入力していただきます",
    appellation: "呼び方",
    checkAppellationTips: "お名前を入力願います",
    phone: "電話を連絡",
    checkPhoneTips: "ご連絡先を入力願います",
    closeText: "閉鎖します",
    application: "申請を提出",
    applicationApproved:"申し込み成功",
    formFieldsTips: "必須項目を記入してから作業をお願いします"
  },
  brandServer: {
    cooperationServices: "ブランドデジタル賦能提携サービス",
    brandServerText: "ブランドデジタルサービス",
    brandedThreeServerText: "ブランドのデジタル3Dサービス",
    cardboard: "靴箱やボール紙を世界にデジタルで伝送します",
    netWorth: "富裕層が協力して流れを引き寄せるの"
  },
  efficiencyServices: {
    efficiencyServicesText: "チャネルデジタル効率化サービス",
    approach: "チャネルマーケティングのデジタルチャネル",
    rentalService: "3D計測機器レンタルサービス",
    salesOrder: "このように単純に受注販売で儲かるの",
    generation: "全世界で一着代理発送ゼロ在庫も先取りできます"
  },
  uniRecommend: {
    uniGolf: "優ちゃんゴルフ",
    newShoes: "新品の靴",
    newScreenings: "新規上映",
    loading: "",

    productGuide: "商品案内",
    searchTips: "お探しの商品キーワードをクリックします",
    wantCustomize: "カスタマイズします",
    customization: "正確にカスタマイズします",
    notDataTips: "現在のキーワードでは検索できませんが、別のキーワードで検索してみましょう",
    checkSearchTips: "商品キーワードの入力をお願いします",

    threeDisplay: "3Dディスプレイ",
    notStock: "当商品は在庫がありません",
    rise: "起",
    productID: "商品番号",
    updateShopping: "ショッピングバッグ商品をリニューアルします",
    addShopping: "買い物袋に入れます",
    buyNow: "即購入",
    soldOut: "売り切れました",
    moreLeather: "皮や色やアクセサリーをカスタマイズして、発見をしてみませんか",
    personalizedCustomization: "カスタマイズカスタマイズ",
    specifications: "仕様パラメータの選択をお願いします",
    contactCustomer: "カスタマーに連絡します",
    reservation: "測量の予約は無料",
    lastsTips: "一款一脚一型",
    privateCustom: "カスタムデザイン",
    defaultText: "黙認",
    directly: "リンクをクリックして開きます",
    copyTips: "複製成功",
    loginOperation: "ログインして操作をお願いします",
    updatedCartInfos: "カート情報の更新に成功しました",
    selectProductTips: "商品の仕様を選んでからレジ袋に入れます",
    purchase: "追加購入成功",
    purchasingTips: "商品仕様をお選びいただいてからのご購入となります",

    goodsDetails: "商品詳細",
    packagingInfo: "包装情報",
    shippingInfo: "配送情報",
    cancel: "取り消",
    confirm: "選択を確認",
    coBranded: "連名シューズ新発売",
    searchTipsBrand: "お探しの商品名を入力します",
    styleNum: "型番",
    personal: "私設高校をお願いします",
    coBrandedNotDataTips: "現在連名ブランドには関連内容がありませんので、別のブランドにしてみましょう",
    warmReminder: "暖かいヒント",
    customizedTips: "オペレーターに連絡して私的権限を取得します"
  },
  customerModal: {
    closeText: "閉鎖します",
    warmReminder: "暖かいヒント",
    customizedTips: "オペレーターに連絡して私的権限を取得します",
    designWorksTips: "箇人の紹介と箇人のデザインの作品をお送りします",
    designWorksTips2: "追ってオペレーターがご連絡いたします。ユー・ユー・テクノロジーへのご関心、ありがとうございます",
  },
  designer: {
    mainTitle: "契約デザイナーのコラボレーションアイテム",
    searchTips: "お探しの商品キーワードをクリックします",
    designerGauding: "デザイナーの高定をお願いします"
  },
  GlobalDesigner: {
    globalDesignerText: "グローバルデザイナー",
    greationPlatform: "創作の場",
    possibilities: "あなたのデザインを新しいものに変えることが可能",
    continuous: "一回のデザインで収益を上げることができます",
    admission: "入居申請をします",
    accessPlatform: "プラットフォームに入ります",
    pleaseLogin: "登録をお願いします",
    accessPlatformTips: "あなたはまだ入居デザイナーではありません、先に入居申請を提出してください、後続は顧客サービスの人員があなたと連絡します、優你科技に対する関心を感謝します",
    viewingWall: "作品の壁を見ます",
    viewingDesigner: "デザイナー",
    searchDesignerTitle: "デザイナータイトルの入力をお願いします",
    searchDesignerName: "デザイナー名をお願いします",
    searchNotData: "現在のキーワードでは検索できませんが、別のキーワードで検索してみましょう",
    concern: "関心を持ちます",
    followed: "注目しました",
    focusOnSuccess: "成功しました",
    unfollowed: "フォローキャンセルしました",
    collected: "収蔵に成功しました",
    cancelCollection: "収蔵取り消しになりました"
  },
  empowerment: {
    digging: "一緒に発掘につきあいます",
    bigHealth: "大きな健康数値",
    oceanMarket: "ブルー・オーシャン市場",
    metaverse:"メタ宇宙のデジタル機能配置",
    futureShoes:"未来を先取りして新しいサーキットをカスタマイズします",
    buyer: "バイヤー",
    channeler: "私はディーラー/チャネル",
    partner: "私はブランド側"
  },
  empowermentModal: {
    cooperationRequirement: "協力要請",
    conditionOfCooperation: "ご協力条件",
    cooperationProcess: "協力のプロセス",
    closeText: "閉鎖します",
    contactToApply: "申請に連絡します",
    contactToApplyTips: "お申し込み後、専門のオペレーターがご連絡いたします。UNIサービスへのご関心、ありがとうございます",
    typeText: "タイプ",
    brandName: "ブランド名",
    checkBrandNameTips: "ブランド名の入力をお願いします",
    currentFlowSize: "現在の流量規模",
    checkCurrentFlowSizeTips: "現在の企業規模を入力していただきます",
    appellation: "呼び方",
    checkAppellationTips: "呼び方入力お願いします",
    phone: "電話を連絡します",
    checkPhoneTips: "連絡先の入力をお願いします",
    country: "国",
    checkCountryTips: "国を選びます",
    district: "地域",
    checkDistrictTips: "地域をお願いします",
    brandEx: "ブランドマネジメント経験があります",
    businessProfile: "経営状況の概要",
    brandIntroduction: "ブランド概要",
    cancelText: "取り消します",
    applyNow: "直ちに申請します",
    checkCurrentTraffic:"現在のフロー規模を入力していただきます",
    checkBusinessTips: "営業情報/ブランド情報の入力をお願いします",
    professional: "プロのバイヤー",
    commercial: "商業バイヤー",
    freeBuyer: "フリーのバイヤー",
    distributorText: "ディーラー",
    channelText: "ルート商",

    buyerStepDesc1: "靴と服の組み合わせに精通して、靴と服の設計理念を理解します",
    merchantStepDesc1: "本社と合資して子会社化します",
    merchantTitle1: "合弁で子会社を設立します",
    brandStepDesc1: "靴服類のブランド、一定の知名度と評判を持っています",

    buyerStepDesc2: "ある程度の顧客層を持っています",
    merchantStepDesc2: "現在の地域における一定の資源調達の優位性を備えています",
    merchantTitle2: "地域資源の優位性",
    brandStepDesc2: "ブランドの露出度を上げ、富裕層を取り込みたいと考えています",

    buyerStepDesc3: "経験豊富で1、2ヶ国語を知っています",
    merchantStepDesc3: "企業文化理念に共感し、製品と市場に自信を持っています",
    merchantTitle3: "知行合一",
    brandStepDesc3: "統合モデルの承認、統合吸粉、現金化、資源置換の実現",

    buyerLabel1: "申請します",
    merchantLabel1: "申請します",
    brandLabel1: "申請します",

    buyerLabel2: "予備打ち合わせ",
    merchantLabel2: "予備打ち合わせ",
    brandLabel2: "予備打ち合わせ",

    buyerLabel3: "契約に調印する",
    merchantLabel3: "契約に調印する",
    brandLabel3: "契約に調印する",

    buyerLabel4: "企画ドッキング",
    merchantLabel4: "企画ドッキング",
    brandLabel4: "札款/連名款企画ドッキング",

    buyerLabel5: "商品が店頭に並びます",
    merchantLabel5: "商品の代理販売",
    brandLabel5: "商品が店頭に並びます",

    buyerLabel6: "利益配当をします",
    merchantLabel6: "利益配当をします",
    brandLabel6: "利益を分配します",

    applicationApproved: "申し込み成功",
    formFieldsTips: "必須項目を記入してから作業をお願いします"
  },
  AICollocation: {
    collocationText: "AIデジタル組み合わせ",
    designer: "お近くの専属デザイナー",
    digitalFootwear: "リアルな3Dデジタルウェア",
    dressDesign: "一足早く着こなすデザインの魅力をお届けします",

    analysis: "パーソナルイメージ分析",
    digitalPerson: "デジタルマン作成",
    intelligentAI: "スマートAI着こなしのススメ",
    outfitMatching: "コーディネートはワンクリックで註文できます"
  },
  reservation: {
    measurementBannerText1: "数字的根拠があります",
    measurementBannerText2: "カスタマイズカスタマイズ",
    measurementBannerText3: "新しい贅沢になるでしょう",
    measurementBannerText4: "UNIはデジタル計測の未来カスタム体験を提供します",

    uniOfflineService:"UNIオフラインサービスガイドライン",
    viewService:"全てのオフラインサービスを調べます",
    nearest: "一番近くにいます",
    surveyService: "計測サービスの予約をします",
    fromYou: "あなたから離れます",
    digitalMeasurement: "デジタル測定の紹介",
    scannerForBoth: "二本足の3Dスキャナー",
    scannerForBothDesc: "独自のフィルター技術を採用し、スキャンプロセスを遮る必要はありません。3-5秒以内に両足の高精度3 dデータと40項目以上のサイズデータを再構築できます。弓、かかと、足底の荷重を自働的に測定し、測定データをリアルタイムでアップロードできます",
    singleFoot: "一本足の3Dスキャナー",
    singleFootDesc: "新しい多視360°構造設計を採用し、足の全方位スキャンを実現し、3-5秒で足の高精度3 dデータを再構築し、自働的に測定結果を出力します",
    gaitAnalyzer: "歩行分析器",
    gaitAnalyzerDesc: "足の着地過程を正確かつ詳細に記録し、目に見えない生体力学的情報をフィードバックします。異なる部位(前足、かかと、中央部)、異なる状態(立つ、歩く、走る)、異なる時間(着地、支持、キック)に受ける力の大きさ、面積の変化、力線のずれ、移動速度など",
    bodyScanner: "人体3Dスキャナー",
    bodyScannerDesc: "非接触型光学三角測量技術に基づき、復数の安全な光源、復数のレンズ、復数のセンサーを用いて同期して働作することにより、数十項目の人体の重要な寸法の自働測定を、速く、正確に、死角なく、接触なく行うことができます",

    smartFoot: "スマート足測定サービス",
    plantarPressure: "足底圧力測定サービス",
    bodyMeasurementServices: "3D体形測定サービス",
    surveyServiceIntroduction: "測定サービスの紹介",
    reservationSurvey: "測定の予約をします",
    storeService: "店舗サービス/訪問サービス",
    processGuide: "測定の流れを見ます",
    startMeasuring: "測定を開始します",
    reportView: "オンライン上の測定レポートを閲覧します",
    selfService: "セルフオーダーメード型サービス",

    queryServicePoint:"照会サービスポイント",
    searchTips: "キーワードを入力して素早く検索します",
    storeNotData: "この地区はまだ出店していませんので、他の地区にも行ってみてください",
    serviceType: "サービスの種類",

    name: "名前",
    checkNameTips: "お名前をお願いします",
    appellation: "呼び方",
    checkAppellationTips: "呼び方をお願いします",
    phone: "電話を連絡します",
    checkPhoneTips: "連絡先の入力をお願いします",
    bookingDate: "サービスの予約期日",
    bookingTime: "サービスの予約時間",

    checkBookingTime: "サービス時間の選択をお願いします",
    contactAddress: "連絡先",
    checkContactAddress: "連絡先をお願いします",
    submitAnApplication: "申請を提出します",
    onSiteService: "訪問サービス",
    storeServiceText: "店舗サービス"
  },
  searchPage: {
    searchText: "お探しの商品キーワードをクリックします",
    searchResults: "検索結果が出ました",
    searchRes: "検索結果",
    customizeText: "カスタマイズします",
    preciseCustomization: "正確にカスタマイズします",
    notData: "現在のキーワードでは検索できませんが、別のキーワードで検索してみましょう",
    guess: "お気に召しますか",
    searchTips: "検索商品キーワードをお願いします"
  },
  shopBag: {
    notQuantityd: "商品の未選択数がありますのでチェックしてください",
    shoppingBag: "ショッピングバッグ",
    edit: "編集",
    delete: "削除",
    saveChanges: "修正を保存します",
    cancel: "取り消します",
    specification: "商品仕様データに修正がありました",
    pleaseReSelect: "選択し直します",
    quantity: "数",
    notStock: "在庫不足",
    notCartData: "ショッピングカートの中身はまだありません",
    orderSubtotal: "註文小計",
    beenSelectedGoods: "{num}件の商品を選びました",
    subtotalGoods: "商品小計",
    freight: "運賃",
    total: "合計",
    explain: "説明",
    explainContent: "オンライン決済註文が提出されてから30分以内に入金されなかった場合、註文はシステムによって自動的にキャンセルされますので、できるだけ早く商品が届くように支払いを済ませてください。現品と特注生産品は別々にお送りします",
    continueShopping: "買い物の続き",
    settlement: "即座に決済する",
    delTips: "削除後は復旧できませんので、削除するかどうか確認してください",
    availableStock: "販売可能在庫数を超過しました",
    modifySuccessfully: "修正成功",
    deleteSuccessfully: "削除成功",
    settlementCommodity: "決済商品の選択をお願いします",
    notQuantity: "品数0の商品がありますのでチェックしてください"
  },
  myAccount: {
    myAccountText: "私の口座",
    yourInfo: "あなたの情報",
    personal: "プロフィール画像",
    clickReset: "ここをクリックして再設定します",
    appellation: "呼び方",
    checkAppellationTips: "お名前をお願いします",
    surname: "姓",
    checkSurnameTips:"お姓を入力願います",
    name: "名",
    checkNameTips: "お名前を入力願います",
    nickname: "ニックネーム",
    checkNicknameTips: "面白いニックネームを作ることができます",
    birthday: "誕生日",
    checkBirthdayTips: "お誕生日の入力をお願いします",
    height: "身長",
    checkHeightTips: "身長をご入力願います",
    weight: "体重",
    checkWeightTips: "体重を入力していただきます",
    leftFoot: "左足は常にヤードを穿きます",
    checkLeftFootTips: "左足の常用数を入力していただきます",
    rightFoot: "右足は常に履きます",
    checkRightFootTips: "右足の着用コードを入力していただきます",
    personalInfoTips: "あなたの箇人情報を設定して、より正確に、より適切な商品ガイドを提供します",
    country: "国/地域",
    checkCountryTips: "お国をお選びくださいますよう",
    checkCityTips: "お住まいの都市をお願いします",
    checkAreaTips: "エリアの選択をお願いします",
    phone: "電話を連絡します",
    checkPhoneTips: "ご連絡先を入力願います",
    detailsAccount: "口座の詳細",
    mobilePhone: "携帯電話",
    uniWebsiteLogin: "UNIの公式サイトに登録されている携帯電話番号",
    email: "メールアドレス",
    emailLoginTips: "メールアドレスを入力すると、メールアドレスから登録できます",
    privacyRights: "プライバシー権限",
    uniInvitationsTips: "UNIの新しいイベントへの招待、独自の製品、正確なカスタマイズサービス、そして私の興味に合わせてカスタマイズされたカスタマーエクスペリエンスのアップデートを期待しています",
    morePrivacyPractices: "プライバシーに関する法律は、あなたにいくつかの権利を与えています。あなたの箇人情報の取得、削除、修正、訂正、または私たちの処理または送信の権利の制限を要求することができます。また、関連規制機関に苦情を申し立てたり、いつでも同意を撤回したり、アカウントを削除したりすることもできます。私たちのプライバシーの実践とあなたの権利について、電子メールで私たちとさらに情報を得ることができます",
    cancelingChanges: "修正を取り消",
    savingChanges: "修正を保存",
    gentleman: "先生",
    lady: "ご婦人",
    unknown: "未知",

    checkNickNameTips: "ニックネームをお願いします",
    selectBirthdayTips: "生年月日をお願いします",
    checkAreaCodeTips: "局番をお願いします",
    checkCorrectPhoneTips: "正しい連絡先の入力をお願いします",
    agreeTips: "プライバシー同意をクリックしてからお願いします",
    userUpdated: "ユーザー情報の更新完了",
    submitFieldsTips: "必須項目を記入してから作業をお願いします"
  },
  myPurse: {
    myPurseText: "私の財布",
    cumulativeRecharge: "積算チャージ",
    cumulativeConsumption: "累積消費",
    totalAssets: "総資産",
    recharge: "チャージします",
    notData: "記録情報はありません",
    all: "全部",
    billingRecord: "請求書記録",
    consumptionRecord: "消費履歴",
    rechargeRecord: "チャージ記録",
    rechargeAmount: "チャージ金額を選択します",
    gift: "贈ります",
    otherAmount: "その他の金額",
    ofThisRecharge: "チャージ金額の入力をお願いします",
    paymentMethod: "支払い方法",
    cancel: "取り消",
    close: "閉鎖します",
    topUpImmediately: "すぐにチャージします",
    topUpInstructions: "チャージの説明",
    mallForNegotiation: "チャージ後の口座の金額は現金で引き出すことができなくて、ショッピングモールとオフラインの店舗での消費に使うことができます。アカウントのチャージに問題がありますショッピングセンターの顧客サービスに連絡して処理を協議します",
    selectThePayment: "支払い方法を選択してからお願いします",
    rechargeSuccessfully: "チャージ成功"
  },
  myShopWindow: {
    backToPersonal: "個人センターに戻ります",
    myShopWindowText: "私のショーウィンドウ",
    loading: "ロード中",
    delete: "削除",
    collaborativeCreation: "共同創作",
    preciseCustomization: "オーダーメイド、カスタマイズ",
    redesign:"再設計します",
    buyNow: "即購入",
    notData: "あなたのショーウィンドウはまだ中身がありません",
    delTips: "削除後は復旧できませんので、削除するかどうか確認してください",
    creativeInvitation: "共同創作の依頼",
    close: "閉鎖します",
    checkAccountTips: "デザイナー番号/メールアドレスをお願いします",
    sendInvitation: "招待状を送ります",
    invitationIsSuccessful: "誘いが成功し,相手は協力に参加しました",
    personalDesign: "パーソナルデザイン",
    designerCollaborative: "デザイナーのコラボレーションモデル",
    checkAreaCode: "局番をお願いします",
    delItem : "当該ウィンドウ商品は削除しました",
    onlySelfCreated: "デザイナーに協力してもらうのは自分の記録だけ"
  },
  myOrder: {
    myOrderText: "私の註文",
    orderType: "註文タイプ",
    orderNumber: "註文番号",

    notHasStock: "商品があり在庫がありません",
    pendingPayment: "註文待ち",
    orderCancelled: "註文はキャンセルされました",
    orderDeleted: "註文を削除しました",
    notApproved: "註文審査が通らないん",
    pendingReview: "註文審査待ち",
    orderFilled: "註文は補充待ち",
    inProduction: "受注生産中",
    leaving: "註文出庫中",
    pendingDelivery: "ご註文は配送待ち",
    maintenance: "メンテナンス後中",
    beReceived: "註文待ち",
    beShipped: "ご註文は配送待ち",
    completed: "註文完了しました",
    beEvaluated: "註文待ち",
    readyForShipment: "ご註文待ち",
    afterSale: "ご註文後中",
    refunded: "払い戻し済み",
    refundOfReturned: "返品・返金されました",
    quantity: "数",
    returnAndExchange: "返品と交換",
    maintain: "メンテナンス",
    cancelAnOrder: "註文をキャンセルします",
    payImmediately: "ただちに支払います",
    buyAgain: "再購入",
    confirmReceipt: "受け取り確認",
    orderDetails: "ご註文の詳細",
    viewLogistics: "物流を調べます",
    delete: "削除",
    customerService:"お客様サービスにお問い合わせします",
    returnOfGoods: "商品を返送します",
    notData: "ご註文の内容はまだありません",
    allOrders: "全部註文します",
    bePaid: "支払い待ち",
    inProductionText: "生産中",
    beReceivedText: "受取待ち",
    beEvaluatedText: "評価を待ちます",
    completedText: "完成しました",
    shipment: "発送待ち",
    standards: "基準",
    customization: "オーダーメイド",
    informationIsWrong: "アフターサービスの註文情報が間違っています",
    warmReminder: "暖かいヒント",
    interests: "権益の保障のため,商品を受け取って間違いがないことを確認してから,商品を受け取ります",
    cancellingTips:"註文をキャンセルした後は復旧できませんので、キャンセルをご確認ください",
    paymentSuccess: "支払い成功",
    back: "戻ります",
    confirm: "確認します",
    cancel: "取り消します",
    checkPaymentText: "お支払い方法の選択をお願いします",
    orderAmount: "ご註文金額",
    payType: "支払い方法",
    goToPay: "支払いに行きます",
    balanceWillBePaid: "残額のご確認をお願いします",
    currentBalance: "現在の残高",
    balanceAfterPayment: "支払い後に残ります",
    confirmPayment: "支払いを確認します",
    balanceIsInsufficient: "残高が足りませんので,他のお支払い方法をお選びください",
    insufficientBalance: "残高が不足しています",
    anotherPaymentMethod: "他の支払い方法をお願いします",
    pleaseSelect: "先にお願いします",
    topUpNow: "チャージします",
    balance: "残額",
    paymentBeingProcessed: "支払い処理中",
    selectPaypalTips: "paypalでのお支払いをお願いします",
    cancelPayment: "支払いを取り消すこと",
    viewDetails: "詳細を調べます",
    ViewOrder:"註文を見ます",
    keepOrder: "註文を続けます",
    close: "閉鎖します",
    orderSuccessful: "註文成功",
    orderSuccessfulTips: "できるだけ早く受注生産の手配をさせていただきますので、お待ちください",
    goodsToBeReturned: "商品の返送待ち",
    toBeRefunded: "払い戻しを待ちます",
    applyingForReplacement: "交換をお申し込み中"
  },
  afterSale: {
    styleNumber: "型番",
    style: "デザイン",
    size: "サイズ",
    goToOrderDetails: "サイズ",
    applyMaintenance: "メンテナンスを申請します",
    applyReturnExchange: "取替えの申し込みをします",
    orderNumber: "註文番号",
    serviceType: "サービスの種類",
    returnSize: "サイズを戻します",
    statusOfGoods: "荷物の状態",
    refundAmount: "返金金額",
    atMost: "修正不可,最大",
    otherInfoPrice: "送料込み￥0.00、クーポン、ポイント割引などが含まれます",
    maintenanceDesc: "商品メンテナンス説明書",
    exchangeReason: "交換理由",
    refundReason: "返金の理由",
    checkRepairTips: "商品メンテナンス説明をお願いします",
    checkExchangeTips: "交換理由の入力をお願いします",
    refundReasonTips: "返金理由の入力をお願いします",
    uploadCredentials: "信用証明をアップロードします",
    credentialsMaxTips: "資格証は3枚までアップします",
    cancel: "取り消",
    submit: "提出",

    refundOnly: "返金のみ",
    notReceived: "未受領品",
    selectSize: "サイズをお願いします",
    returnGoodsForRefund: "返品返金します",
    exchangeGoods: "交換します",
    receivedGoods: "受け取りました",
    appliedForRepair:"修理を申請しました",
    appliedForReplacement: "交換を申し込みました",
    refundApplied: "払い戻しを申請しました",
    appliedForReturn: "返品・払い戻しを請求しました",

    returnLogisticsInfo: "物流情報記入を戻します",
    logisticsCompany: "物流会社",
    checkLogisticsCompanyTips: "物流会社と入力をお願いします",
    logisticsTrackingNumber: "物流伝票番号",
    logisticsTrackingNumberTips: "物流伝票の入力をお願いします",
    confirmReturn: "返送を確認します",
    infoSubmitted: "情報を提出しました",
    formField:"必須項目を入力して作業をお願いします",
    beenConfirmed: "受け取り確認しました"
  },
  orderDetails: {
    returnToPrevious: "前のページに戻ります",
    sweetheart: "親愛なります",
    checkTheFollowing: "下記の註文情報のご確認をお願いします",
    aboutYourOrderInfo: "ご註文の全ての情報",

    pendingPayment: "註文待ち",
    orderCancelled: "註文はキャンセルされました",
    orderDeleted: "註文を削除しました",
    notApproved: "註文審査が通らないん",
    pendingReview: "註文審査待ち",
    orderFilled: "註文は補充待ち",
    inProduction: "受注生産中",
    leaving: "註文出庫中",
    pendingDelivery: "ご註文は配送待ち",
    maintenance: "メンテナンス後中",
    beReceived: "註文待ち",
    beShipped: "ご註文は配送待ち",
    completed: "註文完了しました",
    beEvaluated: "註文待ち",
    readyForShipment: "ご註文待ち",
    afterSale: "ご註文後中",

    commodityInformation: "商品情報",
    orderNumber: "註文番号",
    customizedForTA: "TAにカスタマイズします",
    personalCustomization: "個人カスタマイズ",
    viewCustomizationDetails: "カスタマイズの詳細を見ます",
    quantity: "数",
    measurementReport: "測量報告書",
    notReport: "測定記録がありませんので、測定予約をクリックします",
    reselect: "選択し直します",
    keepChoosing: "選択を続けます",
    selectMeasurementReport: "測定レポートを選択します",
    subtotal: "商品小計",
    otherInfo: "その他の情報",
    deliveryAddress: "配送先の住所",
    notAddressData: "住所記録がありませんので,先に配送先を添付してください",
    addAnAddress: "アドレスを追加します",
    selectAddress: "アドレスを選択します",
    notBeShippedTips: "デモ註文は出荷不要",
    logisticsFreight: "物流運賃",
    logisticsFreightTips: "物流運賃サービスは国によって異なり、配送先によって若干異なります",
    checkRemarkTips: "ここに註文要求備考情報を入力できます",
    submittingTips: "注文書を提出していただくということは、承っていただくということ",
    privacyPolicy: "プライバシーポリシー",
    and: "と",
    termsOfSale: "販売規約",
    totalOrder: "註文合計",
    actualPayment:"実際に支払います",
    immediatePayment: "ただちに支払います",
    customerService: "お客様サービスにお問い合わせします",
    viewLogistics: "物流を調べます",
    balance: "残額",
    customInsoles:"靴底を註文します",
    checkShippingTips: "配送先の選択をお願いします",
    paymentMethod: "お支払い方法の選択をお願いします",
    notCheckPrivacyPolicyTips: "支払いはプライバシーポリシーと販売規約にチェックを入れてからお願いします",
    notQuantity: "品数0の商品がありますのでチェックしてください",
    checkMeasurementReportTips: "測定レポートを選択してからお支払いをお願いします",
    checkMeasurementBodyReportTips: "姿勢測定レポートを選択してからお支払いをお願いします",
    notCheckReportData: "まだ歩行測定レポートを選択していませんが、引き続きご註文をお支払いしますか",
    tips: "小ヒント:註文を支払った後に、400-686-6899カスタマー・サービスに連絡して測定サービスを予約することができます",
    returnOrder: "註文を返します",
    continueToPay: "支払いを続けます",
    arrangeDelivery: "できるだけ早く出荷の手配をしますので、お待ちください",
    availableStock: "販売可能在庫数を超過しました",
    uniPrivacyPolicy: "UNIプライバシーポリシー",
    close: "閉鎖します",
    personalizationOptions: "カスタマイズオプション",
    subtotal2: "小計",
    collapseCustomizationDetails: "カスタマイズの詳細をクリックします",
    logisticsSituation: "物流状況",
    putAwayLogistics: "物流状況をクリックしま",
    shipping: "配送先の選択をお願いします",
    addAddress: "住所を追加します",
    default: "黙認",
    confirmSelection: "選択を確認",
    myReport: "私のレポート",
    gauger: "りょうたい師",
    notReportData: "あなたの報告はまだ内容がありません",
    footMeasurement: "足の測定",
    gaitMeasurement: "歩行測定",
    confirmBalanceTips: "残額のご確認をお願いします",
    balanceIsInsufficientTips: "残高が足りませんので,他のお支払い方法をお選びください",
    currentBalance: "現在の残高",
    insufficientBalance: "残高が不足しています",
    orderAmount: "ご註文金額",
    balanceAfterPayment: "支払い後に残ります",
    chooseAnother: "他の支払い方法をお願いします",
    selectFirst: "先にお願いします",
  },
  myReport: {
    myReportTitle: "私のレポート",
    gauger: "りょうたい師",
    viewDetails: "詳細を調べます",
    delete: "削除",
    notData: "あなたの報告はまだ内容がありません",
    delTips: "削除後は復旧できませんので、削除するかどうか確認してください",
    footMeasurement: "足の測定",
    gaitMeasurement: "歩行測定",
    bodyMeasurement: "からだつき測定",
    deletedReport: "この測定結果は削除されました",

    back: "戻ります",
    footMeasurementReport: "足の測定レポート",
    phone: "電話",
    reportNumber: "報告番号",
    date: "期日",
    measurementResult: "測定結果",
    leftFoot: "左足",
    rightFoot: "右足",
    reference : "参考値",
    severeInternal: "重度の内・外転",
    moderateInternal: "中程度の内・外回転",
    mildInternal: "軽度の内転/外転",
    normalHeel: "正常なかかと",
    normal: "正常",
    mild: "軽度",
    moderate: "中度",
    severe: "重度",
    footData: "足のデータ",
    lengthDimension: "長さ寸法",
    name:"名前",
    widthSize: "幅寸法",
    girthDimension: "まわり寸法",
    heightDimension: "高さ寸法",
    heelSize: "かかとサイズ",
    measurementSuggestion: "測定提案",
    suggestionTips: "左足:44ヤード、右足:43.5ヤード、適切な靴と靴の中敷きを装備して運動することをお勧めします、あなたの足首と関節を保護する",
    threeDFootModel: "3D足型モデル",
    archReport: "足弓レポート",
    heelReport: "十分に報告します",
    bunionValgusReport: "外反母趾報告書",
    footFormReport: "足型レポート",

    ArchType: "弓型",
    heelType: "ヒールタイプ",
    halluxValgus: "外反母趾",
    footShape: "足型",
    fiveNormalFoot: "5型正常足",
    archEvaluation: "弓の評価",
    rolloverEvaluation: "足と内と外で評価します",
    thumbValgus: "親指内めくりで評価します",
    footShapeEvaluation: "足型評価",
    mildFlatFeet: "7型軽い扁平足",
    archedFoot: "弓形足",
    normalFoot: "常態足",
    mildFlatFoot: "軽い扁平足",
    moderateFlatFoot: "中度扁平足",
    severeFlatFoot: "重度扁平足",
    squareFoot: "重度扁平足",
    greekFoot: "ギリシャ足",
    romanFoot: "ローマ足",
    egyptFoot: "エジプト足",

    lengthOfFoot: "足が長",
    halluxConvex: "外反母趾の長さ",
    littleToe: "つま先の長さ",
    firstMetatarsophalangeal: "第一足趾の長さ",
    fifthMetatarsophalangeal: "第5足趾の長さ",
    lumbarFossa: "わき腹の長さ",
    heelCenter: "踵中心部の長さ",
    tarsalSite: "足根の部分の長さ",
    circumference: "跖囲幅",
    halluxIsWide: "親指の幅が広い",
    wideOutside: "外幅小趾",
    firstMetatarsophalangealWidth: "第一足指の幅は広い",
    fifthMetatarsophalangealWidth: "第5足趾の幅は広い",
    toeWidth: "足の指が広い",
    toeDiagonalFrame: "足指の斜め枠",
    plantarCircumference: "中足囲斜幅",
    metatarsophalangealCircumference: "中足趾囲",
    circumferenceOfTarsus: "フ囲",
    pocketRoot: "兜根囲",
    heightOfPlantar: "中足囲の高さ",
    heightOfThumb: "親指の高さ",
    heightOfTarsus: "付周高さ",
    heelBump: "踵出っ張りの高さ",
    lateralMalleolus: "足首の高さ",
    heightOfArch: "弓の高さ"
  },
  myAddressList: {
    myAddress: "私の住所",
    setToDefault: "黙認にします",
    delete: "削除",
    clickAddAddress: "追加アドレスをクリックします",
    back: "戻ります",
    editTheAddress: "編集アドレス",
    addNewAddress: "新しいアドレスを追加します",
    delTips: "削除後は復旧できませんので、削除するかどうか確認してください",
    setDefaultAddress: "デフォルトアドレスを設定できました",
    cancelDefaultAddress: "デフォルトアドレスはキャンセルされました",
    beenDeleted: "このアドレスは削除されました",
    warmReminder: "暖かいヒント",
    close:"閉鎖します",
    cancel: "取り消",
    confirm: "確定"
  },
  addAddressForm:  {
    country: "国/地域",
    checkCountryTips: "国/地域を選択します",
    postcode: "郵便番号(必須ではありません)",
    checkPostcodeTips: "郵便番号をお願いします",
    appellation: "呼び方",
    checkAppellation: "お名前をお願いします",
    surname: "姓",
    checkSurname: "お名前を入力願います",
    name: "名",
    checkNameTips:"お名前を入力願います",
    province: "省",
    checkProvince: "都道府県をお願いします",
    city: "市",
    checkCity: "お住まいの都市をお願いします",
    area: "区",
    checkArea: "エリアの選択をお願いします",
    detailedAddress: "詳しい住所",
    checkDetailedAddress: "詳しいご住所をお願いします",
    phone: "電話を連絡",
    checkPhone: "ご連絡先を入力していただきます",

    cancel: "取消",
    update: "更新",
    save: "保存",
    gentleman: "先生",
    lady: "ご婦人",

    updatedAddressTips: "アドレス情報を更新しました",
    addedAddressTips: "アドレス情報付与完了",
    fieldsForm: "必須項目を記入してから作業をお願いします"
  },
  personal: {
    logOut: "ログアウトします",
    balance: "残額",
    membershipCard: "会員カード",
    integral: "積分",
    personalInfo: "個人情報",
    myShopWindow:"私のショーウィンドウ",
    myOrder: "私の註文",
    myReport: "私のレポート",
    shipping: "受取先を管理します"
  },
  footerBar: {
    newsletter: "UNI電子情報を購読します",
    bySubscribing: "登録することでUNIのプライバシーポリシーを承認します",
    checkEmail: "メールアドレスをお願いします",
    customerService: "顧客サービス",
    aboutUs: "私たちについて",
    digitalShoe: "デジタル靴カスタマイズサービス",
    digitalClothing: "デジタル衣類カスタマイズサービス",
    brandDigital: "ブランドデジタルサービス",
    channelDigital: "チャネルデジタル効率化サービス",
    brandHigh: "ブランド高定",
    designerStyle: "デザイナーモデル",
    storeInquiry: "店舗検索",
    wallOfwWorks: "作品の壁",
    designer: "デザイナー",
    becomeDealer: "ディーラーになります",
    brandCooperation: "ブランド提携",
    contactUs: "連絡してきます",
    shipping: "輸送と返品",
    payment: "価格と支払い",
    ourHistory: "私たちの歴史",
    sustainable: "持続可能な開発",
    sendSubscribeTips:"メールアドレスを入力してからお願いします"
  },
  fixedNextPage:  {
    previousPage: "前のページ",
    nextPage: "次ページ",
    digitalClothing: "デジタル衣類カスタマイズサービス",
    digitalShoe: "デジタル靴カスタマイズサービス",
    brandDigital: "ブランドデジタルサービス",
    channelDigital: "チャネルデジタル効率化サービス",
    brandHigh: "ブランド高定",
    uniRecommendation: "UNI推薦",
    designerStyle: "デザイナーモデル"
  },
  ourHistory: {
    ourHistoryText: "私たちの歴史",
    unique: "UNIは英語のUniqueを意味します",
    attractive: "「完璧」よりも「魅力」を追求するのがUNI企業のコアバリュー",
    uniDesc: "私達は工業、ネット、科学技術、万物の相互接続の速いスピードの膨脹のリズムの中で更に優しい交流の方式を探し当てて、人々を助けて探し当てて、創造を選びます。私たちは復雑な科学技術を精巧な測定設備に凝縮して、乾燥なデータを独創的な靴型に変換して、科学技術と工芸、箇性と品格を結合して、すべての人に自分のデザイナーをさせて、世界に多元と開放を抱けるようにします！",

    year: "2017年",
    mainTitle: "尋常ではない始まり",
    mainDesc: "創始者Katsuo Saito",
    desc: "Katsuo Saito先生開発チームを中心にカスタマイズを模索しています。",

    year1: "2021年",
    mainTitle1: "東莞運営センターが稼働しました",

    year2: "2021年6月",
    mainTitle2: "優你科技が設立されました",

    year3: "2021年8月",
    certificate: "ユニテクノロジーの最初の特許",
    firstPatent: "最初の特許が生まれました",

    year4: "2022年3月",
    pressConference: "ミラノ発表会",
    mainDesc4: "優你科技はミラノとニューヨークでそれぞれ発表会を行いました",

    year5: "2022年5月",
    mainTitle5: "スマート工場図",
    mainDesc5: "スマート工場が完成しました",

    year6: "2022年8月",
    mainTitle6: "UNI東莞旗艦店",
    mainDesc6: "東莞と瀋陽の旗艦店がオープンしました",
    desc6: "同年に峰景のポップアップストア、海口の旗艦店が同時オープンしました"
  },
  sustainable: {
    responsibility: "社会的責任",
    responsibilityTips1: "私たちは毎日,健康的で,環境に優しく,快適なものを作ろうとしています",
    responsibilityTips2: "地球とそこに生きる人間への愛を行動で示します",

    title: "社会的責任",
    desc: "私たちはストーリーのある製品を創造することに力を入れています。各製品が彼の靴型から外装の色、細部の彫刻から部分的なデザインまで、着用者の独特なセンスを示すことができます。私達は一つの格の選択に拘らないイタリア、日本、中国などの多くの場所の工芸技術、消費者の内心の渇望に合う完璧な靴で、品質で品格を述べます。我々は絶えず科学技術で想像力の境界を突破し、z時代と連動して新しいメディアの発言権を掌握し、メタ宇宙とデジタルイメージの抽象概念を具現化し、単影の人類にネットを通じて時空間対話を実現させ、科学技術をはるか遠くではなく、リアルで面白いものにしていきます。",

    title1: "低炭素環境にやさしい",
    desc1: "私たちは地球の保護を呼びかける時代に生まれて、自然にもっと生物の多様性に注目して、省エネと排出削減を促進して持続可能な発展を提唱して、環境にやさしい多重材料で資源の費用と損失を減らして、新しい世紀の環境の挑戦を迎えます。UNIは、デジタル計測とスマート製造を用いて、伝統的なオーダーメイドと工業的なものづくりの間で、「大規模なカスタマイズ」という包括的なソリューションを実現することから始まりました。消費側、プラットフォーム側、生産側が協働することで、工業時代に発生した不要な消耗を解消し、現代都市の人々のニーズを満たすことに努めます。",

    title2: "健康生态",
    desc2: "UNI科技は足首医学の著名な張建中教授を中心に、国内外の1000名以上の外科専門家と専門チームを構成し、3D測定、生体力学分析から足の健康管理などの技術サポートを提供しています。生体力学、体力訓練、リハビリ補助具、体力リハビリの先端技術を中核として、ドイツ、フランス、アメリカ、イタリア、スペインなどの国々と幅広い協力関系を築いています。歩態分析、体力訓練、足背検査、リハビリ訓練システムを提供し、科学研究、国家代表チーム、スポーツ、医療机関に総合的な解決案を提供しています。"
  },
  contactUsPage: {
    contactUs: "連絡してきます",
    contactUsTips1: "私たちは毎日,健康的で,環境に優しく,快適なものを作ろうとしています",
    careAbout: "地球とそこに生きる人間への愛を行動で示します",
    serviceHotline: "サービスホットライン",
    phoneCall: "手机拨打",
    landlineCall: "携帯電話からかけます",
    workTime: "月曜日から金曜日の朝8時25分から午後6時まで",

    alsoInfo: "ウェブサイトに直接メッセージを残してもいいよ",
    informationNeeded:"必要な情報",
    helpTips: "どんなお役に立てるでしょうか",
    problem: "問題",
    describe: "記述",
    contactInfo: "連絡情報",
    getPhoneTips: "ご連絡先をお願いします",
    country: "国/地域",
    checkCountry: "国/地域を選択します",
    checkDistrict: "地域をお願いします",
    name: "名前",
    checkName: "お名前をお願いします",
    appellation: "呼び方",
    checkAppellation: "呼び方をお願いします",
    InternationalAreaCode: "国際局番",
    checkInternationalAreaCode: "国際局番をお願いします",
    phone: "携帯電話番号",
    checkPhone: "携帯番号の入力をお願いします",
    email: "メールボックス",
    checkEmails: "メールアドレスを入力してください",
    send: "発送します",
    gentleman: "先生",
    lady: "ご婦人",
    applicationApproved: "申し込み成功",
    formFields: "必須項目を記入してから作業をお願いします"
  },
  design: {
    fullScreen: "全画面表示",
    modelScreenshot: "モデルのスクリーンショット",
    designSignature: "サインをデザインします",
    joinShowWindow: "ショーウィンドーを入れます",
    footFitting: "フットフィッティング",
    digitalHuman: "デジタルマン試着",
    MRFitting: "ミスター試着",
    leftClickDrag: "左ボタンをドラッグして3Dモデルを回転させ、ローラーをズームして細部を見ることができます",
    selected: "選びました",
    buyNow: "即購入",
    personalized: "カスタマイズオプション",
    subtotal: "小計",
    collapseCustomization: "カスタマイズの詳細をクリックします",
    tips: "ヒント",
    checkNameTips: "${name}を選択して再生します",
    screenshotSuccess: "スクリーンショット成功",
    pleaseLogIn: "登録をお願いします",
    development: "この機能を開発中",
    UpdateSuccessfully: "更新成功",
    joinSuccessfully: "参加できました",
    warmReminder:"暖かいヒント",
    continueEditing: "編集を続けます",
    abandonChange: "変更を放棄します",
    abandonTips: "あなたはすでに異なるデザインの${curName}デザインを選択して、生成を確認していません",
    successfulPurchase: "追加購入成功",
    previous: "前の項",
    nextItem: "次の項",
    total: "合計",

    signatureDesign: "サインデザイン",
    checkSignatureDesign: "下にサインをお願いします",
    enterCheckSignatureDesign: "下にご署名をお願いします",
    reset: "リセットします",
    checkDesignTips: "サインのデザインをお願いします",
    customizeColor: "サインカラーはカスタマイズできますので,好きな色を選んでください",
    hidden: "隠します",
    confirmGeneration: "生成を確認",
    signatureLocationDesign: "署名位置のデザイン",
    mouseTips: "マウスの左ボタンを押してドラッグできる位置、ホバリングしてスクロールする滑車を押してサイズを縮めることができます",
    printDesign: "プリントデザイン",
    favorite: "以下の方法でお好みのプリントをアップロードできます",
    selectLocalImages: "ローカル画像を選択します",
    transmitPictures: "コードスルー画像",
    historyTransfer: "歴史の伝送",
    printPositionDesign: "プリント位置デザイン",
    strugglingToLoad: "全力でロード中",
    pleaseGenerate: "プリントの生成をお願いします",
    mobileWebsiteL: "モバイルのホームページを閲覧して,好きなカスタマイズの画像をアップロードします",
    tip: "ヒント:透明な背景の写真を使うと、より効果的よ",
    step: "手順のヒント",
    tips1: "移働端は開いて、優あなたの全世界の公式サイトをクリックして、左の二次元コードをスキャンします",
    tips2: "お気に入りの写真を選びます",
    confirmSelect: "選択を確認し、画像のアップロードが完了するまで待ちます",
    tips4: "画像のアップロードが成功した後、あなたは画像を押しながら位置をドラッグして、サイズをスケーリングします",
    uploaded: "履歴転送でアップロードされた履歴画像を見ることができます",
    checkFavorite: "お好きな画像をお願いします",
    cancel: "取り消",
    confirmSelection: "選択を確認します",
    checkSignature: "署名をお願いします",
    writeSignatureTips: "署名をお願いします",
    mapToLoad: "スタンプの読み込みが完了するまでお待ちいただけます",
    error: "間違い",
    notCheckPrintPattern: "プリント柄未選択",
    tipsText:"ヒント",
    noHistorical: "転送履歴はまだありません",
    selectPicture: "先に画像をお願いします"
  }
};
