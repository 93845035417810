import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useDialog } from "naive-ui";
export default {
  __name: 'index',
  setup: function setup(__props) {
    window.$dialog = useDialog();
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div");
    };
  }
};