import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance, onMounted, ref } from "vue";
import { NConfigProvider, zhCN, dateZhCN, NMessageProvider, NDialogProvider } from "naive-ui";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useI18n } from "vue-i18n";
import MessageApi from "@/components/message-api/index.vue";
import DialogApi from "@/components/dialog-api/index.vue";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var _useI18n = useI18n(),
      t = _useI18n.t,
      locale = _useI18n.locale;
    var _getCurrentInstance = getCurrentInstance(),
      proxy = _getCurrentInstance.proxy;
    window.$t = t;
    window.$router = useRouter();
    window.$route = useRoute();
    window.$store = useStore();
    gsap.registerPlugin(ScrollTrigger);
    window.$gsap = gsap;
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(NConfigProvider), {
        locale: _unref(zhCN),
        "date-locale": _unref(dateZhCN)
      }, {
        default: _withCtx(function () {
          return [_createVNode(_unref(NMessageProvider), null, {
            default: _withCtx(function () {
              return [_createVNode(MessageApi)];
            }),
            _: 1
          }), _createVNode(_unref(NDialogProvider), null, {
            default: _withCtx(function () {
              return [_createVNode(DialogApi)];
            }),
            _: 1
          }), _createVNode(_component_router_view)];
        }),
        _: 1
      }, 8, ["locale", "date-locale"]);
    };
  }
};