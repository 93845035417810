import BasicLayout from "@/layout/basic-layout.vue"

export default {
  path: "/digital_empowerment_program",
  name: "digitalEmpowermentProgram",
  component: BasicLayout,
  children: [
    {
      path: "digital_empowerment_programPage",
      name: "digital_empowerment_programPage",
      component: () => import("@/views/digital_empowerment_program/index.vue")
    }
  ]
}
