const globalCountryAreaNum = [
  {
    "english_name": "Afghanistan",
    "chinese_name": "阿富汗",
    "japan_name": "アフガニスタン",
    "country_code": "AF",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/afghanistan.svg",
    "phone_code": "93"
  },
  {
    "english_name": "Alaska",
    "chinese_name": "阿拉斯加",
    "japan_name": "アラスカ",
    "country_code": "US",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20states.svg",
    "phone_code": "1907",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Albania",
    "chinese_name": "阿尔巴尼亚",
    "japan_name": "アルバニア",
    "country_code": "AL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/albania.svg",
    "phone_code": "355"
  },
  {
    "english_name": "Algeria",
    "chinese_name": "阿尔及利亚",
    "japan_name": "アルジェリア",
    "country_code": "DZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/Algeria.svg",
    "phone_code": "213"
  },
  {
    "english_name": "American Samoa",
    "chinese_name": "美属萨摩亚",
    "japan_name": "アメリカ領サモア",
    "country_code": "AS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/american%20samoa.svg",
    "phone_code": "1684"
  },
  {
    "english_name": "Andorra",
    "chinese_name": "安道尔",
    "japan_name": "アンドラ",
    "country_code": "AD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/andorra.svg",
    "phone_code": "376"
  },
  {
    "english_name": "Angola",
    "chinese_name": "安哥拉",
    "japan_name": "アンゴラ",
    "country_code": "AO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/angola.svg",
    "phone_code": "244"
  },
  {
    "english_name": "Anguilla",
    "chinese_name": "安圭拉",
    "japan_name": "アンギラ",
    "country_code": "AI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/anguilla.svg",
    "phone_code": "1264"
  },
  {
    "english_name": "Antigua and Barbuda",
    "chinese_name": "安提瓜和巴布达",
    "japan_name": "アンティグアとバブダ",
    "country_code": "AG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/antigua%20and%20barbuda.svg",
    "phone_code": "1268"
  },
  {
    "english_name": "Argentina",
    "chinese_name": "阿根廷",
    "japan_name": "アルゼンチン",
    "country_code": "AR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/argentina.svg",
    "phone_code": "54"
  },
  {
    "english_name": "Armenia",
    "chinese_name": "亚美尼亚",
    "japan_name": "アルメニア",
    "country_code": "AM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/armenia.svg",
    "phone_code": "374"
  },
  {
    "english_name": "Aruba",
    "chinese_name": "阿鲁巴",
    "japan_name": "アルバ",
    "country_code": "AW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/aruba.svg",
    "phone_code": "297"
  },
  {
    "english_name": "Ascension",
    "chinese_name": "阿森松",
    "japan_name": "アクセンチュア",
    "country_code": "SH",
    "phone_code": "247",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20kingdom.svg",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Australia",
    "chinese_name": "澳大利亚",
    "japan_name": "オーストラリア",
    "country_code": "AU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/australia.svg",
    "phone_code": "61"
  },
  {
    "english_name": "Austria",
    "chinese_name": "奥地利",
    "japan_name": "オーストリア",
    "country_code": "AT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/austria.svg",
    "phone_code": "43"
  },
  {
    "english_name": "Azerbaijan",
    "chinese_name": "阿塞拜疆",
    "japan_name": "アゼルバイジャン",
    "country_code": "AZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/azerbaijan.svg",
    "phone_code": "994"
  },
  {
    "english_name": "Bahamas",
    "chinese_name": "巴哈马",
    "japan_name": "バハマ",
    "country_code": "BS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bahamas.svg",
    "phone_code": "1242"
  },
  {
    "english_name": "Bahrain",
    "chinese_name": "巴林",
    "japan_name": "バーレーン",
    "country_code": "BH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bahrain.svg",
    "phone_code": "973"
  },
  {
    "english_name": "Bangladesh",
    "chinese_name": "孟加拉国",
    "japan_name": "バングラデシュ",
    "country_code": "BD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bangladesh.svg",
    "phone_code": "880"
  },
  {
    "english_name": "Barbados",
    "chinese_name": "巴巴多斯",
    "japan_name": "バルバドス",
    "country_code": "BB",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/barbados.svg",
    "phone_code": "1246"
  },
  {
    "english_name": "Belarus",
    "chinese_name": "白俄罗斯",
    "japan_name": "ベラルーシ",
    "country_code": "BY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/belarus.svg",
    "phone_code": "375"
  },
  {
    "english_name": "Belgium",
    "chinese_name": "比利时",
    "japan_name": "ベルギー",
    "country_code": "BE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/belgium.svg",
    "phone_code": "32"
  },
  {
    "english_name": "Belize",
    "chinese_name": "伯利兹",
    "japan_name": "ベリーズ",
    "country_code": "BZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/belize.svg",
    "phone_code": "501"
  },
  {
    "english_name": "Benin",
    "chinese_name": "贝宁",
    "japan_name": "ベニン",
    "country_code": "BJ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/benin.svg",
    "phone_code": "229"
  },
  {
    "english_name": "Bermuda",
    "chinese_name": "百慕大群岛",
    "japan_name": "バミューダ諸島",
    "country_code": "BM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bermuda.svg",
    "phone_code": "1441"
  },
  {
    "english_name": "Bhutan",
    "chinese_name": "不丹",
    "japan_name": "ブータン",
    "country_code": "BT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bhutan.svg",
    "phone_code": "975"
  },
  {
    "english_name": "Bolivia",
    "chinese_name": "玻利维亚",
    "japan_name": "ボリビア",
    "country_code": "BO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bolivia.svg",
    "phone_code": "591"
  },
  {
    "english_name": "Bosnia and Herzegovina",
    "chinese_name": "波斯尼亚和黑塞哥维那",
    "japan_name": "ボスニア・ヘルツェゴビナ",
    "country_code": "BA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bosnia%20and%20herzegovina.svg",
    "phone_code": "387"
  },
  {
    "english_name": "Botswana",
    "chinese_name": "博茨瓦纳",
    "japan_name": "ボツワナ",
    "country_code": "BW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/botswana.svg",
    "phone_code": "267"
  },
  {
    "english_name": "Brazil",
    "chinese_name": "巴西",
    "japan_name": "ブラジル",
    "country_code": "BR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/brazil.svg",
    "phone_code": "55"
  },
  {
    "english_name": "Brunei",
    "chinese_name": "文莱",
    "japan_name": "ブルネイ",
    "country_code": "BN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/brunei.svg",
    "phone_code": "673"
  },
  {
    "english_name": "Bulgaria",
    "chinese_name": "保加利亚",
    "japan_name": "ブルガリア",
    "country_code": "BG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/bulgaria.svg",
    "phone_code": "359"
  },
  {
    "english_name": "Burkina Faso",
    "chinese_name": "布基纳法索",
    "japan_name": "ブルキナファソ",
    "country_code": "BF",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/burkina%20faso.svg",
    "phone_code": "226"
  },
  {
    "english_name": "Burundi",
    "chinese_name": "布隆迪",
    "japan_name": "ブルンジ",
    "country_code": "BI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/burundi.svg",
    "phone_code": "257"
  },
  {
    "english_name": "Cambodia",
    "chinese_name": "柬埔寨",
    "japan_name": "カンボジア",
    "country_code": "KH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cambodia.svg",
    "phone_code": "855"
  },
  {
    "english_name": "Cameroon",
    "chinese_name": "喀麦隆",
    "japan_name": "カメルーン",
    "country_code": "CM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cameroon.svg",
    "phone_code": "237"
  },
  {
    "english_name": "Canada",
    "chinese_name": "加拿大",
    "japan_name": "カナダ",
    "country_code": "CA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/canada.svg",
    "phone_code": "1"
  },
  {
    "english_name": "Islas Canarias",
    "chinese_name": "加那利群岛",
    "japan_name": "カナリア諸島",
    "country_code": "ES",
    "phone_code": "34",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/spain.svg",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Cape Verde",
    "chinese_name": "开普",
    "japan_name": "ケープ",
    "country_code": "CV",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cape%20verde.svg",
    "phone_code": "238"
  },
  {
    "english_name": "Cayman Islands",
    "chinese_name": "开曼群岛",
    "japan_name": "ケイマン諸島",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cayman%20islands.svg",
    "country_code": "KY",
    "phone_code": "1345"
  },
  {
    "english_name": "Central African Republic",
    "chinese_name": "中非共和国",
    "japan_name": "中央アフリカ共和国",
    "country_code": "CF",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/central%20african%20republic.svg",
    "phone_code": "236"
  },
  {
    "english_name": "Chad",
    "chinese_name": "乍得",
    "japan_name": "チャド",
    "country_code": "TD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/chad.svg",
    "phone_code": "235"
  },
  {
    "english_name": "China",
    "chinese_name": "中国",
    "japan_name": "中国",
    "country_code": "CN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/china.svg",
    "phone_code": "86"
  },
  {
    "english_name": "Chile",
    "chinese_name": "智利",
    "japan_name": "チリ",
    "country_code": "CL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/chile.svg",
    "phone_code": "56"
  },
  {
    "english_name": "Christmas Island",
    "chinese_name": "圣诞岛",
    "japan_name": "クリスマス島",
    "country_code": "CX",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/kiribati.svg",
    "phone_code": "0061 9164",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Cocos Island",
    "chinese_name": "科科斯岛",
    "japan_name": "ココス島",
    "country_code": "CC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cocos%20island.svg",
    "phone_code": "0061 9162",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Colombia",
    "chinese_name": "哥伦比亚",
    "japan_name": "コロンビア",
    "country_code": "CO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/colombia.svg",
    "phone_code": "57"
  },
  {
    "english_name": "Dominican Republic",
    "chinese_name": "多米尼加共和国",
    "japan_name": "ドミニカ共和国",
    "country_code": "DO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/dominican%20republic.svg",
    "phone_code": "1809"
  },
  {
    "english_name": "Comoros",
    "chinese_name": "科摩罗",
    "japan_name": "コモロ",
    "country_code": "KM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/comoros.svg",
    "phone_code": "269"
  },
  {
    "english_name": "Republic Of The Congo",
    "chinese_name": "刚果共和国",
    "japan_name": "コンゴ共和国",
    "country_code": "CG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/republic%20of%20the%20congo.svg",
    "phone_code": "242"
  },
  {
    "english_name": "Democratic Republic of the Congo",
    "chinese_name": "刚果民主共和国",
    "japan_name": "コンゴ民主共和国",
    "country_code": "CD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/democratic%20republic%20of%20congo.svg",
    "phone_code": "243"
  },
  {
    "english_name": "Cook Islands",
    "chinese_name": "库克群岛",
    "japan_name": "クック諸島",
    "country_code": "CK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cook%20islands.svg",
    "phone_code": "682"
  },
  {
    "english_name": "Costa Rica",
    "chinese_name": "哥斯达黎加",
    "japan_name": "コスタリカ",
    "country_code": "CR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/costa%20rica.svg",
    "phone_code": "506"
  },
  {
    "english_name": "Croatia",
    "chinese_name": "克罗地亚",
    "japan_name": "クロアチア",
    "country_code": "HR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/croatia.svg",
    "phone_code": "385"
  },
  {
    "english_name": "Cuba",
    "chinese_name": "古巴",
    "japan_name": "キューバ",
    "country_code": "CU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cuba.svg",
    "phone_code": "53"
  },
  {
    "english_name": "Curacao",
    "chinese_name": "库拉索",
    "japan_name": "キュラソー",
    "country_code": "CW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/curacao.svg",
    "phone_code": "599"
  },
  {
    "english_name": "Cyprus",
    "chinese_name": "塞浦路斯",
    "japan_name": "キプロス",
    "country_code": "CY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/cyprus.svg",
    "phone_code": "357"
  },
  {
    "english_name": "Czech",
    "chinese_name": "捷克",
    "japan_name": "チェコ",
    "country_code": "CZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/czech%20republic.svg",
    "phone_code": "420"
  },
  {
    "english_name": "Denmark",
    "chinese_name": "丹麦",
    "japan_name": "デンマーク",
    "country_code": "DK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/denmark.svg",
    "phone_code": "45"
  },
  {
    "english_name": "Djibouti",
    "chinese_name": "吉布提",
    "japan_name": "ジブチ",
    "country_code": "DJ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/djibouti.svg",
    "phone_code": "253"
  },
  {
    "english_name": "Dominica",
    "chinese_name": "多米尼加",
    "japan_name": "ドミニカ",
    "country_code": "DM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/dominica.svg",
    "phone_code": "1767"
  },
  {
    "english_name": "Ecuador",
    "chinese_name": "厄瓜多尔",
    "japan_name": "エクアドル",
    "country_code": "EC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/ecuador.svg",
    "phone_code": "593"
  },
  {
    "english_name": "Egypt",
    "chinese_name": "埃及",
    "japan_name": "エジプト",
    "country_code": "EG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/egypt.svg",
    "phone_code": "20"
  },
  {
    "english_name": "El Salvador",
    "chinese_name": "萨尔瓦多",
    "japan_name": "エルサルバドル",
    "country_code": "SV",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/el%20salvador.svg",
    "phone_code": "503"
  },
  {
    "english_name": "Equatorial Guinea",
    "chinese_name": "赤道几内亚",
    "japan_name": "赤道ギニア",
    "country_code": "GQ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/equatorial%20guinea.svg",
    "phone_code": "240"
  },
  {
    "english_name": "Eritrea",
    "chinese_name": "厄立特里亚",
    "japan_name": "エリトリア",
    "country_code": "ER",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/eritrea.svg",
    "phone_code": "291"
  },
  {
    "english_name": "Estonia",
    "chinese_name": "爱沙尼亚",
    "japan_name": "エストニア",
    "country_code": "EE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/estonia.svg",
    "phone_code": "372"
  },
  {
    "english_name": "Ethiopia",
    "chinese_name": "埃塞俄比亚",
    "japan_name": "エチオピア",
    "country_code": "ET",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/ethiopia.svg",
    "phone_code": "251"
  },
  {
    "english_name": "Falkland Islands",
    "chinese_name": "福克兰群岛",
    "japan_name": "フォークランド諸島",
    "country_code": "FK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/falkland%20islands.svg",
    "phone_code": "500",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Faroe Islands",
    "chinese_name": "法罗群岛",
    "japan_name": "フェロー諸島",
    "country_code": "FO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/faroe%20islands.svg",
    "phone_code": "298"
  },
  {
    "english_name": "Fiji",
    "chinese_name": "斐济",
    "japan_name": "フィジー",
    "country_code": "FJ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/fiji.svg",
    "phone_code": "679"
  },
  {
    "english_name": "Finland",
    "chinese_name": "芬兰",
    "japan_name": "フィンランド",
    "country_code": "FI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/finland.svg",
    "phone_code": "358"
  },
  {
    "english_name": "France",
    "chinese_name": "法国",
    "japan_name": "フランス",
    "country_code": "FR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "33"
  },
  {
    "english_name": "French Guiana",
    "chinese_name": "法属圭亚那",
    "japan_name": "フランス領ガイアナ",
    "country_code": "GF",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "594"
  },
  {
    "english_name": "French Polynesia",
    "chinese_name": "法属波利尼西亚",
    "japan_name": "フランス領ポリネシア",
    "country_code": "PF",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/french%20polynesia.svg",
    "phone_code": "689"
  },
  {
    "english_name": "Gabon",
    "chinese_name": "加蓬",
    "japan_name": "ガボン",
    "country_code": "GA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/gabon.svg",
    "phone_code": "241"
  },
  {
    "english_name": "Gambia",
    "chinese_name": "冈比亚",
    "japan_name": "ガンビア",
    "country_code": "GM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/gambia.svg",
    "phone_code": "220"
  },
  {
    "english_name": "Georgia",
    "chinese_name": "格鲁吉亚",
    "japan_name": "グルジア",
    "country_code": "GE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/georgia.svg",
    "phone_code": "995"
  },
  {
    "english_name": "Germany",
    "chinese_name": "德国",
    "japan_name": "ドイツ",
    "country_code": "DE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/germany.svg",
    "phone_code": "49"
  },
  {
    "english_name": "Ghana",
    "chinese_name": "加纳",
    "japan_name": "ガーナ",
    "country_code": "GH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/ghana.svg",
    "phone_code": "233"
  },
  {
    "english_name": "Gibraltar",
    "chinese_name": "直布罗陀",
    "japan_name": "ジブラルタル",
    "country_code": "GI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/gibraltar.svg",
    "phone_code": "350"
  },
  {
    "english_name": "Greece",
    "chinese_name": "希腊",
    "japan_name": "ギリシャ",
    "country_code": "GR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/greece.svg",
    "phone_code": "30"
  },
  {
    "english_name": "Greenland",
    "chinese_name": "格陵兰岛",
    "japan_name": "グリーンランド",
    "country_code": "GL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/greenland.svg",
    "phone_code": "299"
  },
  {
    "english_name": "Grenada",
    "chinese_name": "格林纳达",
    "japan_name": "グレナダ",
    "country_code": "GD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/grenada.svg",
    "phone_code": "1473"
  },
  {
    "english_name": "Guadeloupe",
    "chinese_name": "瓜德罗普岛",
    "japan_name": "グアドループ島",
    "country_code": "GP",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "590"
  },
  {
    "english_name": "Guam",
    "chinese_name": "关岛",
    "japan_name": "グアム島",
    "country_code": "GU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/guam.svg",
    "phone_code": "1671"
  },
  {
    "english_name": "Guatemala",
    "chinese_name": "瓜地马拉",
    "japan_name": "グアテマラ",
    "country_code": "GT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/guatemala.svg",
    "phone_code": "502"
  },
  {
    "english_name": "Guinea",
    "chinese_name": "几内亚",
    "japan_name": "ギニア",
    "country_code": "GN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/guinea.svg",
    "phone_code": "224"
  },
  {
    "english_name": "Guinea-Bissau",
    "chinese_name": "几内亚比绍共和国",
    "japan_name": "ギニアビサウ共和国",
    "country_code": "GW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/guinea%20bissau.svg",
    "phone_code": "245"
  },
  {
    "english_name": "Guyana",
    "chinese_name": "圭亚那",
    "japan_name": "ガイアナ",
    "country_code": "GY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/guyana.svg",
    "phone_code": "592"
  },
  {
    "english_name": "Haiti",
    "chinese_name": "海地",
    "japan_name": "ハイチ",
    "country_code": "HT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/haiti.svg",
    "phone_code": "509"
  },
  {
    "english_name": "Hawaii",
    "chinese_name": "夏威夷",
    "japan_name": "ハワイ",
    "country_code": "US",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/hawaii.svg",
    "phone_code": "1808",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Honduras",
    "chinese_name": "洪都拉斯",
    "japan_name": "ホンジュラス",
    "country_code": "HN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/honduras.svg",
    "phone_code": "504"
  },
  // {
  //   "english_name": "Hong Kong",
  //   "chinese_name": "中国香港",
  //   "japan_name": "香港",
  //   "country_code": "HK",
  //   "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/china.svg",
  //   "phone_code": "852"
  // },
  {
    "english_name": "Hungary",
    "chinese_name": "匈牙利",
    "japan_name": "ハンガリー",
    "country_code": "HU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/hungary.svg",
    "phone_code": "36"
  },
  {
    "english_name": "Iceland",
    "chinese_name": "冰岛",
    "japan_name": "アイスランド",
    "country_code": "IS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/iceland.svg",
    "phone_code": "354"
  },
  {
    "english_name": "India",
    "chinese_name": "印度",
    "japan_name": "インド",
    "country_code": "IN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/india.svg",
    "phone_code": "91"
  },
  {
    "english_name": "Indonesia",
    "chinese_name": "印度尼西亚",
    "japan_name": "インドネシア",
    "country_code": "ID",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/indonesia.svg",
    "phone_code": "62"
  },
  {
    "english_name": "Iran",
    "chinese_name": "伊朗",
    "japan_name": "イラン",
    "country_code": "IR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/iran.svg",
    "phone_code": "98"
  },
  {
    "english_name": "Iraq",
    "chinese_name": "伊拉克",
    "japan_name": "イラク",
    "country_code": "IQ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/iraq.svg",
    "phone_code": "964"
  },
  {
    "english_name": "Ireland",
    "chinese_name": "爱尔兰",
    "japan_name": "アイルランド",
    "country_code": "IE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/ireland.svg",
    "phone_code": "353"
  },
  {
    "english_name": "Israel",
    "chinese_name": "以色列",
    "japan_name": "イスラエル",
    "country_code": "IL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/israel.svg",
    "phone_code": "972"
  },
  {
    "english_name": "Italy",
    "chinese_name": "意大利",
    "japan_name": "イタリア",
    "country_code": "IT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/italy.svg",
    "phone_code": "39"
  },
  {
    "english_name": "Ivory Coast",
    "chinese_name": "象牙海岸",
    "japan_name": "象牙海岸",
    "country_code": "CI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/ivory%20coast.svg",
    "phone_code": "225"
  },
  {
    "english_name": "Jamaica",
    "chinese_name": "牙买加",
    "japan_name": "ジャマイカ",
    "country_code": "JM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/jamaica.svg",
    "phone_code": "1876"
  },
  {
    "english_name": "Japan",
    "chinese_name": "日本",
    "japan_name": "日本",
    "country_code": "JP",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/japan.svg",
    "phone_code": "81"
  },
  {
    "english_name": "Jordan",
    "chinese_name": "约旦",
    "japan_name": "ヨルダン",
    "country_code": "JO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/jordan.svg",
    "phone_code": "962"
  },
  {
    "english_name": "Kazakhstan",
    "chinese_name": "哈萨克斯坦",
    "japan_name": "カザフスタン",
    "country_code": "KZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/kazakhstan.svg",
    "phone_code": "7"
  },
  {
    "english_name": "Kenya",
    "chinese_name": "肯尼亚",
    "japan_name": "ケニア",
    "country_code": "KE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/kenya.svg",
    "phone_code": "254"
  },
  {
    "english_name": "Kiribati",
    "chinese_name": "基里巴斯",
    "japan_name": "キリバス",
    "country_code": "KI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/kiribati.svg",
    "phone_code": "686"
  },
  {
    "english_name": "Korea Democratic Rep.",
    "chinese_name": "朝鲜",
    "japan_name": "北朝鮮",
    "country_code": "KP",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/north%20korea.svg",
    "phone_code": "85",
    "aliyunSupport": "false"
  },
  {
    "english_name": "South Korea",
    "chinese_name": "韩国",
    "japan_name": "韓国",
    "country_code": "KR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/south%20korea.svg",
    "phone_code": "82"
  },
  {
    "english_name": "Kuwait",
    "chinese_name": "科威特",
    "japan_name": "クウェート",
    "country_code": "KW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/kuwait.svg",
    "phone_code": "965"
  },
  {
    "english_name": "Kyrgyzstan",
    "chinese_name": "吉尔吉斯斯坦",
    "japan_name": "キルギス",
    "country_code": "KG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/kyrgyzstan.svg",
    "phone_code": "996"
  },
  {
    "english_name": "Laos",
    "chinese_name": "老挝",
    "japan_name": "ラオス",
    "country_code": "LA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/laos.svg",
    "phone_code": "856"
  },
  {
    "english_name": "Latvia",
    "chinese_name": "拉脱维亚",
    "japan_name": "ラトビア",
    "country_code": "LV",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/latvia.svg",
    "phone_code": "371"
  },
  {
    "english_name": "Lebanon",
    "chinese_name": "黎巴嫩",
    "japan_name": "レバノン",
    "country_code": "LB",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/lebanon.svg",
    "phone_code": "961"
  },
  {
    "english_name": "Lesotho",
    "chinese_name": "莱索托",
    "japan_name": "レソト",
    "country_code": "LS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/lesotho.svg",
    "phone_code": "266"
  },
  {
    "english_name": "Liberia",
    "chinese_name": "利比里亚",
    "japan_name": "リベリア",
    "country_code": "LR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/liberia.svg",
    "phone_code": "231"
  },
  {
    "english_name": "Libya",
    "chinese_name": "利比亚",
    "japan_name": "リビア",
    "country_code": "LY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/libya.svg",
    "phone_code": "218"
  },
  {
    "english_name": "Liechtenstein",
    "chinese_name": "列支敦士登",
    "japan_name": "リヒテンシュタイン",
    "country_code": "LI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/liechtenstein.svg",
    "phone_code": "423"
  },
  {
    "english_name": "Lithuania",
    "chinese_name": "立陶宛",
    "japan_name": "リトアニア",
    "country_code": "LT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/lithuania.svg",
    "phone_code": "370"
  },
  {
    "english_name": "Luxembourg",
    "chinese_name": "卢森堡",
    "japan_name": "ルクセンブルク",
    "country_code": "LU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/luxembourg.svg",
    "phone_code": "352"
  },
  {
    "english_name": "Macau",
    "chinese_name": "中国澳门",
    "japan_name": "中国マカオ",
    "country_code": "MO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/china.svg",
    "phone_code": "853"
  },
  {
    "english_name": "Macedonia",
    "chinese_name": "马其顿",
    "japan_name": "マケドニア",
    "country_code": "MK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/republic%20of%20macedonia.svg",
    "phone_code": "389"
  },
  {
    "english_name": "Madagascar",
    "chinese_name": "马达加斯加",
    "japan_name": "マダガスカル",
    "country_code": "MG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/madagascar.svg",
    "phone_code": "261"
  },
  {
    "english_name": "Malawi",
    "chinese_name": "马拉维",
    "japan_name": "マラウイ",
    "country_code": "MW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/malawi.svg",
    "phone_code": "265"
  },
  {
    "english_name": "Malaysia",
    "chinese_name": "马来西亚",
    "japan_name": "マレーシア",
    "country_code": "MY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/malaysia.svg",
    "phone_code": "60"
  },
  {
    "english_name": "Maldives",
    "chinese_name": "马尔代夫",
    "japan_name": "モルディブ",
    "country_code": "MV",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/maldives.svg",
    "phone_code": "960"
  },
  {
    "english_name": "Mali",
    "chinese_name": "马里",
    "japan_name": "マリ",
    "country_code": "ML",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/mali.svg",
    "phone_code": "223"
  },
  {
    "english_name": "Malta",
    "chinese_name": "马耳他",
    "japan_name": "マルタ",
    "country_code": "MT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/malta.svg",
    "phone_code": "356"
  },
  {
    "english_name": "Marshall Islands",
    "chinese_name": "马绍尔群岛",
    "japan_name": "マーシャル諸島",
    "country_code": "MH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/marshall%20island.svg",
    "phone_code": "692",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Martinique",
    "chinese_name": "马提尼克",
    "japan_name": "マルティニーク",
    "country_code": "MQ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/martinique.svg",
    "phone_code": "596"
  },
  {
    "english_name": "Mauritania",
    "chinese_name": "毛里塔尼亚",
    "japan_name": "モーリタニア",
    "country_code": "MR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/mauritania.svg",
    "phone_code": "222"
  },
  {
    "english_name": "Mauritius",
    "chinese_name": "毛里求斯",
    "japan_name": "モーリシャス",
    "country_code": "MU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/mauritius.svg",
    "phone_code": "230"
  },
  {
    "english_name": "Mayotte",
    "chinese_name": "马约特",
    "japan_name": "マヨット",
    "country_code": "YT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "269"
  },
  {
    "english_name": "Mexico",
    "chinese_name": "墨西哥",
    "japan_name": "メキシコ",
    "country_code": "MX",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/mexico.svg",
    "phone_code": "52"
  },
  {
    "english_name": "Micronesia",
    "chinese_name": "密克罗尼西亚",
    "japan_name": "ミクロネシア",
    "country_code": "FM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/micronesia.svg",
    "phone_code": "691",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Moldova",
    "chinese_name": "摩尔多瓦",
    "japan_name": "モルドバ",
    "country_code": "MD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/moldova.svg",
    "phone_code": "373"
  },
  {
    "english_name": "Monaco",
    "chinese_name": "摩纳哥",
    "japan_name": "モナコ",
    "country_code": "MC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/monaco.svg",
    "phone_code": "377"
  },
  {
    "english_name": "Mongolia",
    "chinese_name": "蒙古",
    "japan_name": "モンゴル",
    "country_code": "MN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/mongolia.svg",
    "phone_code": "976"
  },
  {
    "english_name": "Montenegro",
    "chinese_name": "黑山",
    "japan_name": "黒山",
    "country_code": "ME",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/montenegro.svg",
    "phone_code": "382"
  },
  {
    "english_name": "Montserrat",
    "chinese_name": "蒙特塞拉特岛",
    "japan_name": "モンセラット島",
    "country_code": "MS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/montserrat.svg",
    "phone_code": "1664"
  },
  {
    "english_name": "Morocco",
    "chinese_name": "摩洛哥",
    "japan_name": "モロッコ",
    "country_code": "MA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/morocco.svg",
    "phone_code": "212"
  },
  {
    "english_name": "Mozambique",
    "chinese_name": "莫桑比克",
    "japan_name": "モザンビーク",
    "country_code": "MZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/mozambique.svg",
    "phone_code": "258"
  },
  {
    "english_name": "Myanmar",
    "chinese_name": "缅甸",
    "japan_name": "ミャンマー",
    "country_code": "MM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/myanmar.svg",
    "phone_code": "95"
  },
  {
    "english_name": "Namibia",
    "chinese_name": "纳米比亚",
    "japan_name": "ナミビア",
    "country_code": "NA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/namibia.svg",
    "phone_code": "264"
  },
  {
    "english_name": "Nauru",
    "chinese_name": "拿鲁岛",
    "japan_name": "ナール島",
    "country_code": "NR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/nauru.svg",
    "phone_code": "674",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Nepal",
    "chinese_name": "尼泊尔",
    "japan_name": "ネパール",
    "country_code": "NP",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/nepal.svg",
    "phone_code": "977"
  },
  {
    "english_name": "Netherlands",
    "chinese_name": "荷兰",
    "japan_name": "オランダ",
    "country_code": "NL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/netherlands.svg",
    "phone_code": "31"
  },
  {
    "english_name": "New Caledonia",
    "chinese_name": "新喀里多尼亚",
    "japan_name": "ニューカレドニア",
    "country_code": "NC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "687"
  },
  {
    "english_name": "New Zealand",
    "chinese_name": "新西兰",
    "japan_name": "ニュージーランド",
    "country_code": "NZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/new%20zealand.svg",
    "phone_code": "64"
  },
  {
    "english_name": "Nicaragua",
    "chinese_name": "尼加拉瓜",
    "japan_name": "ニカラグア",
    "country_code": "NI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/nicaragua.svg",
    "phone_code": "505"
  },
  {
    "english_name": "Niger",
    "chinese_name": "尼日尔",
    "japan_name": "ニジェール",
    "country_code": "NE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/niger.svg",
    "phone_code": "227"
  },
  {
    "english_name": "Nigeria",
    "chinese_name": "尼日利亚",
    "japan_name": "ナイジェリア",
    "country_code": "NG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/nigeria.svg",
    "phone_code": "234"
  },
  {
    "english_name": "Niue Island",
    "chinese_name": "纽埃岛(新)",
    "japan_name": "ニウエ島（新）",
    "country_code": "NU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/niue.svg",
    "phone_code": "683",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Norfolk Island",
    "chinese_name": "诺福克岛(澳)",
    "japan_name": "ノーフォーク島（オーストラリア）",
    "country_code": "NF",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/norfolk%20island.svg",
    "phone_code": "6723",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Norway",
    "chinese_name": "挪威",
    "japan_name": "ノルウェー",
    "country_code": "NO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/norway.svg",
    "phone_code": "47"
  },
  {
    "english_name": "Oman",
    "chinese_name": "阿曼",
    "japan_name": "オマーン",
    "country_code": "OM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/oman.svg",
    "phone_code": "968"
  },
  {
    "english_name": "Palau",
    "chinese_name": "帕劳",
    "japan_name": "パラオ",
    "country_code": "PW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/palau.svg",
    "phone_code": "680"
  },
  {
    "english_name": "Panama",
    "chinese_name": "巴拿马",
    "japan_name": "パナマ",
    "country_code": "PA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/panama.svg",
    "phone_code": "507"
  },
  {
    "english_name": "Papua New Guinea",
    "chinese_name": "巴布亚新几内亚",
    "japan_name": "パプアニューギニア",
    "country_code": "PG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/papua%20new%20guinea.svg",
    "phone_code": "675"
  },
  {
    "english_name": "Paraguay",
    "chinese_name": "巴拉圭",
    "japan_name": "パラグアイ",
    "country_code": "PY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/paraguay.svg",
    "phone_code": "595"
  },
  {
    "english_name": "Peru",
    "chinese_name": "秘鲁",
    "japan_name": "ペルー",
    "country_code": "PE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/peru.svg",
    "phone_code": "51"
  },
  {
    "english_name": "Philippines",
    "chinese_name": "菲律宾",
    "japan_name": "フィリピン",
    "country_code": "PH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/philippines.svg",
    "phone_code": "63"
  },
  {
    "english_name": "Poland",
    "chinese_name": "波兰",
    "japan_name": "ポーランド",
    "country_code": "PL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/philippines.svg",
    "phone_code": "48"
  },
  {
    "english_name": "Portugal",
    "chinese_name": "葡萄牙",
    "japan_name": "ポルトガル",
    "country_code": "PT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/portugal.svg",
    "phone_code": "351"
  },
  {
    "english_name": "Pakistan",
    "chinese_name": "巴基斯坦",
    "japan_name": "パキスタン",
    "country_code": "PK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/poland.svg",
    "phone_code": "92"
  },
  {
    "english_name": "Palestine",
    "chinese_name": "巴勒斯坦",
    "japan_name": "パレスチナ",
    "country_code": "BL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/palestine.svg",
    "phone_code": "970"
  },
  {
    "english_name": "Puerto Rico",
    "chinese_name": "波多黎各",
    "japan_name": "プエルトリコ",
    "country_code": "PR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/puerto%20rico.svg",
    "phone_code": "1787"
  },
  {
    "english_name": "Qatar",
    "chinese_name": "卡塔尔",
    "japan_name": "カタール",
    "country_code": "QA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/qatar.svg",
    "phone_code": "974"
  },
  {
    "english_name": "Réunion Island",
    "chinese_name": "留尼汪",
    "japan_name": "レユニオン",
    "country_code": "RE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "262"
  },
  {
    "english_name": "Romania",
    "chinese_name": "罗马尼亚",
    "japan_name": "ルーマニア",
    "country_code": "RO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/romania.svg",
    "phone_code": "40"
  },
  {
    "english_name": "Russia",
    "chinese_name": "俄罗斯",
    "japan_name": "ロシア",
    "country_code": "RU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/russia.svg",
    "phone_code": "7"
  },
  {
    "english_name": "Rwanda",
    "chinese_name": "卢旺达",
    "japan_name": "ルワンダ",
    "country_code": "RW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/rwanda.svg",
    "phone_code": "250"
  },
  {
    "english_name": "Samoa,Eastern",
    "chinese_name": "东萨摩亚(美)",
    "japan_name": "東サモア(アメリカ)です",
    "country_code": "AS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20states.svg",
    "phone_code": "684",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Samoa",
    "chinese_name": "萨摩亚",
    "japan_name": "サモア",
    "country_code": "WS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/samoa.svg",
    "phone_code": "685"
  },
  {
    "english_name": "San Marino",
    "chinese_name": "圣马力诺",
    "japan_name": "サンマリノ",
    "country_code": "SM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/san%20marino.svg",
    "phone_code": "378"
  },
  {
    "english_name": "Saint Pierre and Miquelon",
    "chinese_name": "圣彼埃尔和密克隆岛",
    "japan_name": "サンピエル島とミクロン島",
    "country_code": "PM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/france.svg",
    "phone_code": "508"
  },
  {
    "english_name": "Saint Kitts and Nevis",
    "chinese_name": "圣基茨和尼维斯",
    "japan_name": "サンキッツとネビス",
    "country_code": "KN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/Mask%20group.svg",
    "phone_code": "1869"
  },
  {
    "english_name": "Sao Tome and Principe",
    "chinese_name": "圣多美和普林西比",
    "japan_name": "サントメプリンシペ",
    "country_code": "ST",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/sao%20tome%20and%20prince.svg",
    "phone_code": "239"
  },
  {
    "english_name": "Saudi Arabia",
    "chinese_name": "沙特阿拉伯",
    "japan_name": "サウジアラビア",
    "country_code": "SA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/saudi%20arabia.svg",
    "phone_code": "966"
  },
  {
    "english_name": "Senegal",
    "chinese_name": "塞内加尔",
    "japan_name": "セネガル",
    "country_code": "SN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/senegal.svg",
    "phone_code": "221"
  },
  {
    "english_name": "Serbia",
    "chinese_name": "塞尔维亚",
    "japan_name": "セルビア",
    "country_code": "RS",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/serbia.svg",
    "phone_code": "381"
  },
  {
    "english_name": "Seychelles",
    "chinese_name": "塞舌尔",
    "japan_name": "セーシェル",
    "country_code": "SC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/seychelles.svg",
    "phone_code": "248"
  },
  {
    "english_name": "Sierra Leone",
    "chinese_name": "塞拉利昂",
    "japan_name": "シエラレオネ",
    "country_code": "SL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/sierra%20leone.svg",
    "phone_code": "232"
  },
  {
    "english_name": "Singapore",
    "chinese_name": "新加坡",
    "japan_name": "シンガポール",
    "country_code": "SG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/singapore.svg",
    "phone_code": "65"
  },
  {
    "english_name": "Saint Maarten (Dutch Part)",
    "chinese_name": "圣马丁岛（荷兰部分）",
    "japan_name": "サンマルティン島（オランダ部分）",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/netherlands.svg",
    "country_code": "SX",
    "phone_code": "1721"
  },
  {
    "english_name": "Slovakia",
    "chinese_name": "斯洛伐克",
    "japan_name": "スロバキア",
    "country_code": "SK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/slovakia.svg",
    "phone_code": "421"
  },
  {
    "english_name": "Slovenia",
    "chinese_name": "斯洛文尼亚",
    "japan_name": "スロベニア",
    "country_code": "SI",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/slovenia.svg",
    "phone_code": "386"
  },
  {
    "english_name": "Solomon Islands",
    "chinese_name": "所罗门群岛",
    "japan_name": "ソロモン諸島",
    "country_code": "SB",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/solomon%20islands.svg",
    "phone_code": "677"
  },
  {
    "english_name": "Somalia",
    "chinese_name": "索马里",
    "japan_name": "ソマリア",
    "country_code": "SO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/somalia.svg",
    "phone_code": "252"
  },
  {
    "english_name": "South Africa",
    "chinese_name": "南非",
    "japan_name": "南アフリカ共和国",
    "country_code": "ZA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/south%20africa.svg",
    "phone_code": "27"
  },
  {
    "english_name": "Spain",
    "chinese_name": "西班牙",
    "japan_name": "スペイン",
    "country_code": "ES",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/spain.svg",
    "phone_code": "34"
  },
  {
    "english_name": "Sri Lanka",
    "chinese_name": "斯里兰卡",
    "japan_name": "スリランカ",
    "country_code": "LK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/sri%20lanka.svg",
    "phone_code": "94"
  },
  {
    "english_name": "St.Helena",
    "chinese_name": "圣赫勒拿",
    "japan_name": "セントヘレナ",
    "country_code": "SH",
    "phone_code": "290",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20kingdom.svg",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Saint Lucia",
    "chinese_name": "圣露西亚",
    "japan_name": "聖ルシア",
    "country_code": "LC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/st%20lucia.svg",
    "phone_code": "1758"
  },
  {
    "english_name": "Saint Vincent and The Grenadines",
    "chinese_name": "圣文森特和格林纳丁斯",
    "japan_name": "セントビンセント・グレナディーン",
    "country_code": "VC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/st%20vincent%20and%20the%20grenadines.svg",
    "phone_code": "1784"
  },
  {
    "english_name": "Sudan",
    "chinese_name": "苏丹",
    "japan_name": "スーダン",
    "country_code": "SD",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/sudan.svg",
    "phone_code": "249"
  },
  {
    "english_name": "Suriname",
    "chinese_name": "苏里南",
    "japan_name": "スリナム",
    "country_code": "SR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/suriname.svg",
    "phone_code": "597"
  },
  {
    "english_name": "Swaziland",
    "chinese_name": "斯威士兰",
    "japan_name": "スワジランド",
    "country_code": "SZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/swaziland.svg",
    "phone_code": "268"
  },
  {
    "english_name": "Sweden",
    "chinese_name": "瑞典",
    "japan_name": "スウェーデン",
    "country_code": "SE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/sweden.svg",
    "phone_code": "46"
  },
  {
    "english_name": "Switzerland",
    "chinese_name": "瑞士",
    "japan_name": "スイス",
    "country_code": "CH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/switzerland.svg",
    "phone_code": "41"
  },
  {
    "english_name": "Syria",
    "chinese_name": "叙利亚",
    "japan_name": "シリア",
    "country_code": "SY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/syria.svg",
    "phone_code": "963"
  },
  {
    "english_name": "Taiwan of China",
    "chinese_name": "中国台湾",
    "japan_name": "台湾",
    "country_code": "TW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/taiwan.svg",
    "phone_code": "886"
  },
  {
    "english_name": "Tajikistan",
    "chinese_name": "塔吉克斯坦",
    "japan_name": "タジキスタン",
    "country_code": "TJ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tajikistan.svg",
    "phone_code": "992"
  },
  {
    "english_name": "Tanzania",
    "chinese_name": "坦桑尼亚",
    "japan_name": "タンザニア",
    "country_code": "TZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tanzania.svg",
    "phone_code": "255"
  },
  {
    "english_name": "Thailand",
    "chinese_name": "泰国",
    "japan_name": "タイ",
    "country_code": "TH",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/thailand.svg",
    "phone_code": "66"
  },
  {
    "english_name": "Timor-Leste",
    "chinese_name": "东帝汶",
    "japan_name": "東ティモール",
    "country_code": "TL",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/East%20Timor.svg",
    "phone_code": "670"
  },
  {
    "english_name": "United Arab Emirates",
    "chinese_name": "阿拉伯联合酋长国",
    "japan_name": "アラブ首長国連邦",
    "country_code": "AE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20arab%20emirates.svg",
    "phone_code": "971"
  },
  {
    "english_name": "Togo",
    "chinese_name": "多哥",
    "japan_name": "トーゴ",
    "country_code": "TG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/togo.svg",
    "phone_code": "228"
  },
  {
    "english_name": "Tokelau Is.",
    "chinese_name": "托克劳群岛(新)",
    "japan_name": "トクロ諸島（新）",
    "country_code": "TK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tokelau.svg",
    "phone_code": "690",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Tonga",
    "chinese_name": "汤加",
    "japan_name": "トンガ",
    "country_code": "TO",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tonga.svg",
    "phone_code": "676"
  },
  {
    "english_name": "Trinidad and Tobago",
    "chinese_name": "特立尼达和多巴哥",
    "japan_name": "トリニダード・トバゴ",
    "country_code": "TT",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/trinidad%20and%20tobago.svg",
    "phone_code": "1868"
  },
  {
    "english_name": "Tunisia",
    "chinese_name": "突尼斯",
    "japan_name": "チュニジア",
    "country_code": "TN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tunisia.svg",
    "phone_code": "216"
  },
  {
    "english_name": "Turkey",
    "chinese_name": "土耳其",
    "japan_name": "トルコ",
    "country_code": "TR",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/turkey.svg",
    "phone_code": "90"
  },
  {
    "english_name": "Turkmenistan",
    "chinese_name": "土库曼斯坦",
    "japan_name": "トルクメニスタン",
    "country_code": "TM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/turkmenistan.svg",
    "phone_code": "993"
  },
  {
    "english_name": "Turks and Caicos Islands",
    "chinese_name": "特克斯和凯科斯群岛",
    "japan_name": "タークス諸島とカイコス諸島",
    "country_code": "TC",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/turks%20and%20caicos.svg",
    "phone_code": "1649"
  },
  {
    "english_name": "Tuvalu",
    "chinese_name": "图瓦卢",
    "japan_name": "ツバル",
    "country_code": "TK",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tuvalu.svg",
    "phone_code": "688",
    "aliyunSupport": "false"
  },
  {
    "english_name": "United States",
    "chinese_name": "美国",
    "japan_name": "アメリカ",
    "country_code": "US",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20states.svg",
    "phone_code": "1"
  },
  {
    "english_name": "Uganda",
    "chinese_name": "乌干达",
    "japan_name": "ウガンダ",
    "country_code": "UG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/uganda.svg",
    "phone_code": "256"
  },
  {
    "english_name": "Ukraine",
    "chinese_name": "乌克兰",
    "japan_name": "ウクライナ",
    "country_code": "UA",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/ukraine.svg",
    "phone_code": "380"
  },
  {
    "english_name": "United Kingdom",
    "chinese_name": "英国",
    "japan_name": "英国",
    "country_code": "GB",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20kingdom.svg",
    "phone_code": "44"
  },
  {
    "english_name": "Uruguay",
    "chinese_name": "乌拉圭",
    "japan_name": "ウルグアイ",
    "country_code": "UY",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/uruguay.svg",
    "phone_code": "598"
  },
  {
    "english_name": "Uzbekistan",
    "chinese_name": "乌兹别克斯坦",
    "japan_name": "ウズベキスタン",
    "country_code": "UZ",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/uzbekistán.svg",
    "phone_code": "998"
  },
  {
    "english_name": "Vanuatu",
    "chinese_name": "瓦努阿图",
    "japan_name": "バヌアツ",
    "country_code": "VU",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/vanuatu.svg",
    "phone_code": "678"
  },
  {
    "english_name": "Venezuela",
    "chinese_name": "委内瑞拉",
    "japan_name": "ベネズエラ",
    "country_code": "VE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/venezuela.svg",
    "phone_code": "58"
  },
  {
    "english_name": "Vietnam",
    "chinese_name": "越南",
    "japan_name": "ベトナム",
    "country_code": "VN",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/vietnam.svg",
    "phone_code": "84"
  },
  {
    "english_name": "Virgin Islands, British",
    "chinese_name": "英属处女群岛",
    "japan_name": "イギリス領処女諸島",
    "country_code": "VG",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/british%20virgin%20islands.svg",
    "phone_code": "1340"
  },
  {
    "english_name": "Virgin Islands, US",
    "chinese_name": "美属维尔京群岛",
    "japan_name": "アメリカ領ヴァージン諸島",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/virgin%20islands.svg",
    "country_code": "VI",
    "phone_code": "1284"
  },
  {
    "english_name": "Wake I.",
    "chinese_name": "威克岛(美)",
    "japan_name": "ウェーク島（美）",
    "country_code": "UM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/united%20states.svg",
    "phone_code": "1808",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Yemen",
    "chinese_name": "也门",
    "japan_name": "イエメン",
    "country_code": "YE",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/yemen.svg",
    "phone_code": "967"
  },
  {
    "english_name": "Zambia",
    "chinese_name": "赞比亚",
    "japan_name": "ザンビア",
    "country_code": "ZM",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/zambia.svg",
    "phone_code": "260"
  },
  {
    "english_name": "Zanzibar",
    "chinese_name": "桑给巴尔",
    "japan_name": "ザンジバル",
    "country_code": "TZ",
    "phone_code": "259",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/tanzania.svg",
    "aliyunSupport": "false"
  },
  {
    "english_name": "Zimbabwe",
    "chinese_name": "津巴布韦",
    "japan_name": "ジンバブエ",
    "country_code": "ZW",
    "country_img": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/05/country_icon/zimbabwe.svg",
    "phone_code": "263"
  }
]

export default globalCountryAreaNum
