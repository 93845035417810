import BasicLayout from "@/layout/basic-layout.vue"
export default   {
  path: "/solution",
  name: "solution",
  component: BasicLayout,
  children: [
    {
      path: "digitalCustomPage",
      name: "digitalCustomPage",
      component: () => import("@/views/solution/index.vue"),
    },
    {
      path: "digitalClothing",
      name: "digitalClothing",
      component: () => import("@/views/solution/digital-clothing.vue"),
    },
    {
      path: "brandServer",
      name: "brandServer",
      component: () => import("@/views/solution/brand-digitalenabling-server.vue"),
    },
    {
      path: "efficiencyServer",
      name: "efficiencyServer",
      component: () => import("@/views/solution/digital-efficiency-service.vue"),
    },
  ],
}
