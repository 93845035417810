import BasicLayout from "@/layout/basic-layout.vue"

export default   {
  path: "/digitalCustom",
  name: "digitalLayout",
  component: BasicLayout,
  children: [
    {
      path: "UNICustom",
      name: "UNICustomPage",
      component: () => import("@/views/digitalCustom/index.vue"),
    },
    {
      path: "goodsDetails",
      name: "goodsDetails",
      component: () => import("@/views/digitalCustom/uni-recommend-module/goods-details.vue"),
    },
    {
      path: "brandDefinition",
      name: "brandDefinition",
      component: () => import("@/views/digitalCustom/brand-high-definition.vue"),
    },
    {
      path: "designer",
      name: "designer",
      component: () => import("@/views/digitalCustom/designer.vue"),
    },
  ],
}
