import BasicLayout from "@/layout/basic-layout.vue"
export default {
  path: "/",
  component: BasicLayout,
  children: [
    {
      path: "design/:productId",
      name: "design",
      component: () => import("@/views/design/index.vue")
    }
  ]
}
