export default {
  monetaryUnit: "$",
  navMenus: {
    homePage: "Home Page",
    solution: "Solution",
    digitalShoe: "Digital shoe customization service",
    digitalClothing: "Digital clothing customization service",
    brandDigital: "Brand digital empowerment services",
    channelDigital: "Channel digital efficiency services",
    digitalCustomization: "Customization",
    UNIRecommended: "UNI recommended",
    brandHigh: "Brand high standard",
    designer: "designer",
    globalDesigner: "GlobalDesigner",
    ET: "ET",
    reservationSurvey: "Reservation Survey",
    searchText: "search"
  },
  loginModal: {
    memberLogin: "Member login",
    existingAccountTips: "Existing account please login",
    account: "Account (Mobile phone number/email address)",
    areaCodeTips: "Please select an international area code",
    accountTips: "Please enter your account number",
    verificationCodeText: "Verification code",
    verificationCodeTextTips: "Please enter the verification code",
    getCodeText: "Get verification code",
    resend: "Resend",
    loggingTips: "By logging in, you accept our offer",
    privacyPolicy: "Privacy policy",
    loginText: "Login",
    registeredNot: "I haven't registered any accounts",
    mainTips: "The first account verification login will automatically create a MyUNI account for you, which you can follow through any device",
    checkTips: "Check your online orders and purchase history",
    manageTips: "Manage your personal information",
    adviceTips: "Get the latest UNI e-advice",
    designsTips: "Store your shopping bags and personal window designs"
  },
  userModal: {
    hello: "Hello",
    clickPurchaseTips: "Click to view all purchase history",
    logOut: "LogOut",
    personalItem: "Personal Information",
    myShopWindow: "My shop window",
    myOrder: "My order",
    myReport: "My report",
    manage: "Manage the shipping address"
  },
  home: {
    notSupportedVideo: "Your browser does not support the video TAB",
    pickupText1: "Create C2M in footwear industry",
    pickupText2: "Full-chain digital customization platform",
    secondDesc1: "Achieve \"one foot and one last\", \"one person one version one process\" flexible manufacturing",
    secondDesc2: "Achieve large-scale personalized customization of shoes and clothing services",
    clickLearnMore: "Click to learn more",

    pickupFootwearText1: "Footwear digital technology services",
    pickupFootwearText2: "UNI makes digital shoes and clothing",
    pickupFootwearText3: "What you see is what you get",
    pickupFootwearText4: "Provide innovative and mature digital measurement, digital 3D, digital manufacturing, flexible production full link digital customization services。",
    costumeTitle: "Precise 3D design and customization of clothing",
    costumeDesc: "Realize \"one foot, one last\", \"one person, one version, one process\" flexible manufacturing, providing innovative and mature digital measurement, digital 3D, digital manufacturing, flexible production full-link digital customization services",
    learnMore: "Learn more",
    footwearTitle: "Shoes precise 3D design customization",
    footwearDesc: "Realize \"one foot, one last\", \"one person, one version, one process\" flexible manufacturing, providing innovative and mature digital measurement, digital 3D, digital manufacturing, flexible production full-link digital customization services",
    coreTitle: "Core technology",
    technologyItem1: "3D precision scanning",
    technologyItem2: "Biomechanics",
    technologyItem3: "Personalized customization",
    technologyItem4: "3D online real-time rendering",
    technologyItem5: "Face reconstruction technique",
    technologyItem6: "MR Wear",
    technologyItem7: "Automatic foot last change",
    technologyItem8: "Flexible production",

    digitalText1: "The 3D digital design platform empowers innovation",
    digitalText2: "Measurement data model digital sharing collaborative design creation anytime, anywhere",
    digitalTopDesc: "Allow designers to refresh the design of unlimited possibilities, 3D online real-time rendering, massive version, material material search and use, real restore the design effect",
    consultation: "Cooperative consultation",
    worksWall: "Works wall",
    stylist: "Designer",

    enabling1: "Provide digital technology empowerment services for their brands",
    enabling2: "Seize the proportion of high net worth customer base, and create a private version of high custom service.",

    future1: "Create the future of digital shoe customization with UNI"
  },
  solution: {
    experience: "Application experience",
    digitalShoes: "Digital shoes",
    customizedService: "Customized service",
    offlineExperience: "One offline experience unlimited online orders",
    smartFootMeasurement: "Smart foot measurement for digital measurement",
    scanning: "Hd 3D scanning technology services",
    footMultiMatrix: "Foot multi-matrix reconstruction technical services",
    footThreeD: "Foot 3D intelligent digital technology",
    footData: "Foot data AI analysis and application",
    plantarPressure: "Digital measurement of plantar pressure analysis",
    simulation: "Simulation biomechanical techniques",
    highPrecision: "High precision pressure sensing technology",
    intelligent: "Intelligent analysis of static and foot movement data",
    footHealth: "Foot health correction and custom insole generation technology",
    supportVideoTips: "Your browser does not support the video TAB",

    digitalDesignDesc: "Independently developed 3D personalized customization platform, with leading 3D visualization, rapid modeling, real-time light pursuit rendering, 2D3D interactive conversion and other core technologies, can achieve multi-dimensional personalized design works, digital creation functions",
    digitalDesign: "Digital design",
    multiScreen: "Multi-screen collaborative authoring technology",
    humanoid: "Digital humanoid universe experience technology",
    ar: "Real-time AR fitting technology",
    digitalIntelligence: "Digital intelligence",
    automatic: "Automatic foot last conversion technology",
    automaticOut: "Automatic out of the upper board technology",
    globalDigital: "Global digital intelligence deployment system",
    scheduling: "Global order intelligent scheduling system",
    selfDeveloped: "The self-developed digital manufacturing system converts users' digital measurement data and online digital customization platform creation data into core production standard parts such as customized shoes, shoe patterns, personalized configuration tables that can be standardized for footwear production through advanced artificial intelligence AI technology, and realizes flexible production of \"one style, one foot, one last\" and \"one person, one version, one process\". Achieve large-scale personalized clothing customization services",
    flexibleProduction: "Flexible production"
  },
  digitalClothing: {
    digitalClothingTitle: "Digital clothing",
    customizedService: "Customized service",
    fullScene: "Full-scene digital exhibition and marketing, reshaping the new ecology of clothing customization",
    applicationExperience: "Application experience",

    digitalVolume: "Digital volume",
    volumeAI: "Volume AI analysis",
    virtualFitting: "AI virtual fitting",
    intelligentAiCode: "Intelligent AI code placement",
    digitalAutomatic: "Digital automatic discharge",
    flexible: "Flexible production"
  },
  contactUs: {
    contactUsText: "Contact us",
    submitting: "After submitting your application, a customer service professional will contact you to thank you for your interest in UNI's services",
    countryText: "country",
    checkCountryTips: "Please select a country",
    district: "district",
    checkDistrictTips: "Please select a district",
    type: "type",
    checkTypeTips: "Please select a type",
    brandName: "Brand name",
    brandNameCheckTips: "Please enter the brand name",
    enterprise: "Current size of enterprise",
    checkEnterpriseTips: "Please enter the current size of your business",
    appellation: "appellation",
    checkAppellationTips: "Please enter your name",
    phone: "phone",
    checkPhoneTips: "Please enter your contact number",
    brandIntroduction: "Brand introduction",
    checkBrandIntroductionTips: "Please enter the brand description",
    businessProfile: "Business profile",
    checkBusinessProfileTips: "Please enter a business summary",
    application: "Submit an application",
    dealer: "dealer",
    channel: "Channel business",
    applicationApproved:"application approved",
    formFieldsTips: "Please complete the required fields before operation"
  },
  applicationModal: {
    contactUsText: "Contact us",
    submitting: "After submitting your application, a customer service professional will contact you to thank you for your interest in UNI's services",
    countryText: "country",
    checkCountryTips: "Please select a country",
    district: "district",
    checkDistrictTips: "Please select a district",
    enterprise: "Enterprise name",
    checkEnterpriseTips: "Please enter the business name",
    enterpriseSize: "Current size of enterprise",
    checkEnterpriseSizeTips: "Please enter the current size of your business",
    appellation: "appellation",
    checkAppellationTips: "Please enter your name",
    phone: "phone",
    checkPhoneTips: "Please enter your contact number",
    closeText: "close",
    application: "Submit an application",
    applicationApproved:"application approved",
    formFieldsTips: "Please complete the required fields before operation"
  },
  brandServer: {
    cooperationServices: "Brand digital empowerment cooperation services",
    brandServerText: "Brand digital empowerment services",
    brandedThreeServerText: "Branded digital 3D services",
    cardboard: "Global digital transmission of foot last and upper cardboard",
    netWorth: "High net worth customer base cooperation drainage",
  },
  efficiencyServices: {
    efficiencyServicesText: "Channel digital efficiency services",
    approach: "Channel marketing digital approach",
    rentalService: "3D measuring equipment rental service",
    salesOrder: "Sales order Making a profit is that simple",
    generation: "The world's one generation 0 inventory can also get a head start"
  },
  uniRecommend: {
    uniGolf: "Golf for UNI",
    newShoes: "New shoes",
    newScreenings: "New screenings",
    loading: "Image loading, please wait...",

    productGuide: "Product Guide",
    searchTips: "Click enter the product keywords you want to find",
    wantCustomize: "customize",
    customization: "Precision customization",
    notDataTips: "The current keyword did not search content, try another try",
    checkSearchTips: "Please enter the key words",

    threeDisplay: "3D Display",
    notStock: "This item is out of stock at the moment",
    rise: "Rise",
    productID: "Product ID",
    updateShopping: "Update shopping bag items",
    addShopping: "Add shopping bags",
    buyNow: "Buy Now",
    soldOut: "Sold out",
    moreLeather: "More leather, color and accessories personalized to create the discovery",
    personalizedCustomization: "Personalized customization",
    specifications: "Please select specifications",
    contactCustomer: "Contact customer service",
    reservation: "Free reservation measurement",
    lastsTips: "One style, one foot, one last",
    privateCustom: "Private custom design",
    defaultText: "default",
    directly: "Click on the link to open directly",
    copyTips: "Copy successfully",
    loginOperation: "Please login before operation",
    updatedCartInfos: "Shopping cart information updated successfully",
    selectProductTips: "Please select the product specification before adding the shopping bag",
    purchase: "Successful purchase",
    purchasingTips: "Please select specifications before purchasing",

    goodsDetails: "Goods details",
    packagingInfo: "Packaging info",
    shippingInfo: "Shipping info",
    cancel: "Cancel",
    confirm: "Confirm",
    coBranded: "New co-branded shoes on the market",
    searchTipsBrand: "Click enter the co-branded product you want to find",
    styleNum: "style number",
    personal: "want private order",
    coBrandedNotDataTips: "The current co-branded brand has no associated content, try another one",
    warmReminder: "Warm reminder",
    customizedTips: "Please contact customer service to obtain private customized permissions"
  },
  customerModal: {
    closeText: "close",
    warmReminder: "Warm reminder",
    customizedTips: "Please contact customer service to obtain private customized permissions",
    designWorksTips: "Please send your personal introduction and personal design works to",
    designWorksTips2: "A customer service staff will contact you later. Thank you for your interest in UNI Technology",
  },
  designer: {
    mainTitle: "Contract designer cooperation payment",
    searchTips: "Click to enter the keyword of the product you are looking for",
    designerGauding: "designer Gauding"
  },
  GlobalDesigner: {
    globalDesignerText: "Global designer",
    greationPlatform: "Creation platform",
    possibilities: "Make your design new unlimited possibilities",
    continuous: "Continuous revenue from one design",
    admission: "Apply for admission",
    accessPlatform: "Access platform",
    pleaseLogin: "Please login",
    accessPlatformTips: "You are not a settled designer, please submit an application to settle in first, there will be a customer service staff to contact you later, thank you for your attention to Youtech",
    viewingWall: "Viewing Wall",
    viewingDesigner: "Viewing Designer",
    searchDesignerTitle: "Please enter the title of designer's work",
    searchDesignerName: "Please enter the designer name",
    searchNotData: "The current keyword does not search content, try another",
    concern: "Concern",
    followed: "Followed",
    focusOnSuccess: "Focus on success",
    unfollowed: "Unfollowed",
    collected: "Collected successfully",
    cancelCollection: "Removed from collection"
  },
  empowerment: {
    digging: "Digging with you",
    bigHealth: "Big Health Numbers",
    oceanMarket: "Billions of blue ocean market",
    metaverse:"Digital empowerment, layout of the metaverse",
    futureShoes:"Seize the future shoes and clothing customization new track",
    buyer: "I'm the buyer",
    channeler: "I am a dealer/channeler",
    partner: "I am a brand partner"
  },
  empowermentModal: {
    cooperationRequirement: "Cooperation requirement",
    conditionOfCooperation: "Condition of cooperation",
    cooperationProcess: "Cooperation process",
    closeText: "Close",
    contactToApply: "Contact to apply",
    contactToApplyTips: "After submitting your application, a customer service professional will contact you to thank you for your interest in UNI's services",
    typeText: "Type",
    brandName: "Brand name",
    checkBrandNameTips: "Please enter the brand name",
    currentFlowSize: "Current flow size",
    checkCurrentFlowSizeTips: "Please enter the current size of your business",
    appellation: "Appellation",
    checkAppellationTips: "Please enter appellation",
    phone: "Phone",
    checkPhoneTips: "Please enter a contact number",
    country: "Country",
    checkCountryTips: "Please select a country",
    district: "district",
    checkDistrictTips: "Please select a district",
    brandEx: "Brand management experience",
    businessProfile: "Business profile",
    brandIntroduction: "Brand introduction",
    cancelText: "cancel",
    applyNow: "Apply now",
    checkCurrentTraffic:"Please enter your current traffic size",
    checkBusinessTips: "Please enter your business/brand profile",
    professional: "Professional buyer",
    commercial: "Commercial buyer",
    freeBuyer: "Free buyer",
    distributorText: "Distributor",
    channelText: "Channel business",

    buyerStepDesc1: "Proficient in shoes and clothing collocation, understanding shoes and clothing design concept",
    merchantStepDesc1: "Joint venture with headquarters to register as a subsidiary",
    merchantTitle1: "Joint venture registered subsidiary",
    brandStepDesc1: "Shoes and clothing brand, with a certain visibility and reputation",

    buyerStepDesc2: "Have a certain scale of customer groups",
    merchantStepDesc2: "It has certain advantages of resource allocation in the current region",
    merchantTitle2: "Regional resource advantage",
    brandStepDesc2: "Eager to increase brand exposure and attract high net worth customers",

    buyerStepDesc3: "Experienced and able to speak one or two foreign languages",
    merchantStepDesc3: "Identify with corporate culture concept, full of confidence in products and markets",
    merchantTitle3: "Integration of knowledge and action",
    brandStepDesc3: "Recognize the joint mode, realize the joint suction, cash, resource replacement",

    buyerLabel1: "Make an application",
    merchantLabel1: "Make an application",
    brandLabel1: "Make an application",

    buyerLabel2: "Preliminary negotiation",
    merchantLabel2: "Preliminary negotiation",
    brandLabel2: "Preliminary negotiation",

    buyerLabel3: "Sign a contract",
    merchantLabel3: "Sign a contract",
    brandLabel3: "Sign a contract",

    buyerLabel4: "Planning docking",
    merchantLabel4: "Planning docking",
    brandLabel4: "Brand/joint project docking",

    buyerLabel5: "Merchandise shelf",
    merchantLabel5: "Consignment sale",
    brandLabel5: "Merchandise shelf",

    buyerLabel6: "Profit sharing",
    merchantLabel6: "Profit sharing",
    brandLabel6: "Profit sharing",

    applicationApproved: "Application approved",
    formFieldsTips: "Please complete the required fields before operation"
  },
  AICollocation: {
    collocationText: "AI number collocation",
    designer: "Your own designer",
    digitalFootwear: "Realistic 3D digital footwear",
    dressDesign: "Let you feel the charm of dress design in advance",

    analysis: "Personal image analysis",
    digitalPerson: "Digital person creation",
    intelligentAI: "Intelligent AI wear recommended",
    outfitMatching: "Outfit matching with one click order"
  },
  reservation: {
    measurementBannerText1: "It's based on numbers",
    measurementBannerText2: "Personalized customization",
    measurementBannerText3: "Will be a new luxury",
    measurementBannerText4: "UNI offers you a new custom experience for the future of digital measurement",

    uniOfflineService:"UNI Offline Service Guidelines",
    viewService: "View all offline service points",
    nearest: "Nearest to you",
    surveyService: "Reservation survey service",
    fromYou: "From you",
    digitalMeasurement: "Introduction to digital measurement",
    scannerForBoth: "3D scanner for both feet",
    scannerForBothDesc: "With unique light filtering technology, the scanning process does not need to block, and high-precision 3D data and more than 40 dimensional data are reconstructed within 3-5 seconds. The arch, heel and sole force are automatically evaluated, and the evaluation data are uploaded in real time. The terminal experience is connected with the factory production",
    singleFoot: "Single foot 3D scanner",
    singleFootDesc: "The new multi-vision 360° structure design truly realizes the all-round scanning of the foot, and the high-precision 3D data of the foot can be reconstructed within 3-5 seconds, and the evaluation results can be automatically output",
    gaitAnalyzer: "Gait analyzer",
    gaitAnalyzerDesc: "The process of foot landing was accurately and meticulously recorded, and the biomechanical information of the foot that could not be seen by the naked eye was fed back, including the force size, force area change, force line deviation, and moving speed of different parts (front palm, heel, middle part), different states (standing, walking, running), and different times (landing, supporting, and pushing off)",
    bodyScanner: "3D body scanner",
    bodyScannerDesc: "Based on non-contact optical triangulation technology, multiple safe light sources, multiple lenses and multiple sensors work simultaneously, can quickly, accurately, no dead Angle, no contact to complete the automatic measurement of dozens of key dimensions of the human body, and automatically output anthropometric data according to the measurement scheme",

    smartFoot: "Smart foot measurement services",
    plantarPressure: "Plantar pressure measurement services",
    bodyMeasurementServices: "3D body measurement services",
    surveyServiceIntroduction: "Survey service introduction",
    reservationSurvey: "Reservation survey",
    storeService: "Store service/door-to-door service",
    processGuide: "View the measurement process guide",
    startMeasuring: "Start measuring",
    reportView: "Online measurement report view",
    selfService: "Self-service precision customization service",

    queryServicePoint:"Query service point",
    searchTips: "Enter a keyword for a quick search",
    storeNotData: "There are no stores in this area, please go to other areas",
    serviceType: "Service type",

    name: "Name",
    checkNameTips: "Please enter your name",
    appellation: "appellation",
    checkAppellationTips: "Please select a appellation",
    phone: "phone",
    checkPhoneTips: "Please enter a contact number",
    bookingDate: "Booking date",
    bookingTime: "Booking time",

    checkBookingTime: "Please select the service time",
    contactAddress: "Contact address",
    checkContactAddress: "Please enter the contact address",
    submitAnApplication: "submit an application",
    onSiteService: "On-site service"
  },
  searchPage: {
    searchText: "Click to enter the keyword of the product you are looking for",
    searchResults: "The search results are",
    searchRes: "Search results",
    customizeText: "I want to customize",
    preciseCustomization: "Precise customization",
    notData: "The current keyword did not search content, try another",
    guess: "Guess you like",
    searchTips: "Please enter the search product keywords"
  },
  shopBag: {
      notQuantityd: "There are goods not selected quantity, please check",
      shoppingBag: "Shopping bag",
      edit: "Edit",
      delete: "Delete",
      saveChanges: "Save",
      cancel: "Cancel",
      specification: "The product specification data has been modified",
      pleaseReSelect: "Please re-select",
      quantity: "Quantity",
      notStock: "Insufficient stock",
      notCartData: "Your shopping cart is empty",
      orderSubtotal: "Order subtotal",
      beenSelectedGoods: "{num} items have been selected",
      subtotalGoods: "Subtotal of commodities",
      freight: "Freight charge",
      total: "Total",
      explain: "Explain",
      explainContent: "If the order is not paid within 30 minutes after the order is submitted, the order will be cancelled automatically. Please complete the payment as soon as possible to ensure that the goods can be delivered in time. Off-the-shelf goods and custom manufactured goods are shipped separately",
      continueShopping: "Keep shopping",
      settlement: "Immediate settlement",
      delTips: "It will not be restored after deletion, please confirm whether to delete",
      availableStock: "Available stock has been exceeded",
      modifySuccessfully: "Modify successfully",
      deleteSuccessfully: "Delete successfully",
      settlementCommodity: "Please select settlement commodity",
      notQuantity: "The quantity of some products is 0, please check"
  },
  myAccount: {
    myAccountText: "My account",
    yourInfo: "Your Information",
    personal: "Personal profile picture",
    clickReset: "Click here to reset",
    appellation: "Appellation",
    checkAppellation: "Please choose your salutation",
    surname: "Surname",
    checkSurnameTips:"Please enter your surname",
    name: "Name",
    checkNameTips: "Please enter your name",
    nickname: "Nickname",
    checkNicknameTips: "You can set up a fun nickname",
    birthday: "Birthday",
    checkBirthdayTips: "Please enter your birthday",
    height: "height",
    checkHeightTips: "Please enter your height",
    weight: "weight",
    checkWeightTips: "Please enter your weight",
    leftFoot: "Left foot often wear size",
    checkLeftFootTips: "Please enter the size of your left foot",
    rightFoot: "Right foot often wear size",
    checkRightFootTips: "Please enter the size of your right foot",
    personalInfoTips: "Set up your personal information, so that we can more accurately provide you with more appropriate product guidance",
    country: "Country/Region",
    checkCountryTips: "Please select your country",
    checkCityTips: "Please select your city",
    checkAreaTips: "Please select your area",
    phone: "phone",
    checkPhoneTips: "Please enter your contact number",
    detailsAccount: "Details of the account",
    mobilePhone: "Mobile phone calls",
    uniWebsiteLogin: "This is your mobile phone number for UNI website login",
    email: "email",
    emailLoginTips: "Enter your email, you can use the email to log in",
    privacyRights: "Privacy rights",
    uniInvitationsTips: "I would like to receive updates on UNI's invitations to new events, exclusive products, precisely personalized services, and customer experiences tailored to my interests",
    morePrivacyPractices: "Privacy laws give you a number of rights. You may request us to obtain, delete, modify, correct your personal information, or limit our right to process or transmit your personal information. You may also lodge a complaint with the relevant regulatory authority or withdraw consent or delete your personal account at any time. You can obtain further information about our privacy practices and your rights by email",
    cancelingChanges: "Canceling changes",
    savingChanges: "Saving changes",
    gentleman: "Gentleman",
    lady: "Lady",
    unknown: "Unknown",

    checkNickNameTips: "Please set your nickname",
    selectBirthdayTips: "Please select your date of birth",
    checkAreaCodeTips: "Please select your area code",
    checkCorrectPhoneTips: "Please enter the correct contact number",
    agreeTips: "Please click to agree to the privacy agreement after operation",
    userUpdated: "User information is updated",
    submitFieldsTips: "Please complete the required fields before operation"
  },
  myPurse: {
    myPurseText: "My purse",
    cumulativeRecharge: "Cumulative recharge",
    cumulativeConsumption: "Cumulative consumption",
    totalAssets: "Total assets",
    recharge: "Recharge",
    notData: "No record information is available",
    all: "All",
    billingRecord: "Billing record",
    consumptionRecord: "Consumption record",
    rechargeRecord: "Recharge record",
    rechargeAmount: "Please select the recharge amount",
    gift: "Gift",
    otherAmount: "Other amount",
    ofThisRecharge: "Please enter the amount of this recharge",
    paymentMethod: "Payment method",
    cancel: "cancel",
    close: "close",
    topUpImmediately: "Top up immediately",
    topUpInstructions: "Top-up instructions",
    mallForNegotiation: "The amount of the account can not be withdrawn after the recharge, and can be used for consumption in the mall and offline stores; If there is any problem with the account recharge, please contact the customer service of the mall for negotiation",
    selectThePayment: "Please select the payment method before operation",
    rechargeSuccessfully: "Recharge successfully"
  },
  myShopWindow: {
    backToPersonal: "Back to personal center",
    myShopWindowText: "My shop window",
    loading: "Loading",
    delete: "delete",
    collaborativeCreation: "Collaborative creation",
    preciseCustomization: "Precise customization、personalized customization",
    redesign: "redesign",
    buyNow: "Buy now",
    notData: "Your window is empty",
    delTips: "After it is deleted, it cannot be restored. Please confirm whether to delete it",
    creativeInvitation: "Collaborative creative invitation",
    close: "Close",
    checkAccountTips: "Please enter the designer's phone/email",
    sendInvitation: "Send an invitation",
    invitationIsSuccessful: "The invitation is successful, and the peer party has joined the collaboration",
    personalDesign: "Personal design",
    designerCollaborative: "Designer collaborative creation",
    checkAreaCode: "Please select an area code",
    delItem : "This window item has been deleted",
    onlySelfCreated: "Only self-created records can invite designer assistance"
  },
  myOrder: {
    myOrderText: "My Order",
    orderType: "Order type",
    orderNumber: "Order number",

    notHasStock: "Some goods are out of stock",
    pendingPayment: "Order pending payment",
    orderCancelled: "Order cancelled",
    orderDeleted: "Order deleted",
    notApproved: "The order was not approved",
    pendingReview: "Order pending review",
    orderFilled: "Order to be filled",
    inProduction: "In production",
    leaving: "The order is leaving the warehouse",
    pendingDelivery: "Order pending delivery",
    maintenance: "After maintenance",
    beReceived: "Order to be received",
    beShipped: "Order to be shipped",
    completed: "The order is completed",
    beEvaluated: "Order to be evaluated",
    readyForShipment: "The order is ready for shipment",
    afterSale: "Order after sale",
    refunded: "Refunded",
    refundOfReturned: "Refund of returned goods",
    quantity: "Quantity",
    returnAndExchange: "Return and exchange",
    maintain: "maintain",
    cancelAnOrder: "Cancel an order",
    payImmediately: "Pay immediately",
    buyAgain: "Buy again",
    confirmReceipt: "Confirm receipt of goods",
    orderDetails: "Order Details",
    viewLogistics: "View logistics",
    delete: "delete",
    customerService: "Consult customer service",
    returnOfGoods: "Return of goods",
    notData: "Your order is empty",
    allOrders: "All orders",
    bePaid: "To be paid",
    inProductionText: "In production",
    beReceivedText: "Goods to be received",
    beEvaluatedText: "To be evaluated",
    completedText: "completed",
    shipment: "Ready for shipment",
    standards: "Standards",
    customization: "Customization",
    informationIsWrong: "After sales order information is wrong",
    warmReminder: "Warm reminder",
    interests: "In order to protect the rights and interests, please confirm the receipt of the goods after the confirmation",
    cancellingTips:"After cancelling the order, it will not be restored. Please confirm whether to cancel",
    paymentSuccess: "Payment success",
    back: "Back",
    confirm: "Confirm",
    cancel: "Cancel",
    checkPaymentText: "Please select your payment method",
    orderAmount: "Order amount",
    payType: "Payment method",
    goToPay: "Go to pay",
    balanceWillBePaid: "Please confirm whether the balance will be paid",
    currentBalance: "Current balance",
    balanceAfterPayment: "Balance after payment",
    confirmPayment: "Confirm payment",
    balanceIsInsufficient: "The balance is insufficient, please choose another payment method",
    insufficientBalance: "Insufficient balance",
    anotherPaymentMethod: "Please choose another payment method",
    pleaseSelect: "Please select first",
    topUpNow: "Top Up Now",
    balance: "Balance",
    paymentBeingProcessed: "Payment being processed",
    selectPaypalTips: "Please select your paypal payment method",
    cancelPayment: "Cancel payment",
    viewDetails: "View details",
    ViewOrder: "View order",
    keepOrder: "Keep order",
    close: "close",
    orderSuccessful: "Order successful",
    orderSuccessfulTips: "We will arrange the production of your order as soon as possible, please wait patiently",
    goodsToBeReturned: "Goods to be returned",
    toBeRefunded: "To be refunded",
    applyingForReplacement: "Applying for replacement"
  },
  afterSale: {
    styleNumber: "Style number",
    style: "style",
    size: "size",
    goToOrderDetails: "Go to order details",
    applyMaintenance: "Apply for maintenance",
    applyReturnExchange: "Apply for return or exchange",
    orderNumber: "Order number",
    serviceType: "Service type",
    returnSize: "Return size",
    statusOfGoods: "Status of goods",
    refundAmount: "Refund amount",
    atMost: "Immutable, at most",
    otherInfoPrice: "Including shipping postage ￥0.00, including coupons, credits, etc",
    maintenanceDesc: "Product maintenance Instructions",
    exchangeReason: "Reason for replacement",
    refundReason: "Refund reason",
    checkRepairTips: "Please enter the product repair instructions",
    checkExchangeTips: "Please enter the reason for exchange",
    refundReasonTips: "Please enter the reason for refund",
    uploadCredentials: "Upload credentials",
    credentialsMaxTips: "Upload a maximum of 3 credentials",
    cancel: "Cancel",
    submit: "Submit",

    refundOnly: "Refund only",
    notReceived: "Not received",
    selectSize: "Please select size",
    returnGoodsForRefund: "Return goods for refund",
    exchangeGoods: "Exchange goods",
    receivedGoods: "Received goods",
    appliedForRepair:"Applied for repair",
    appliedForReplacement: "Have applied for replacement",
    refundApplied: "Refund has been applied",
    appliedForReturn: "Refund has been applied for return",

    returnLogisticsInfo: "Return logistics information to fill in",
    logisticsCompany: "Logistics company",
    checkLogisticsCompanyTips: "Please enter logistics company",
    logisticsTrackingNumber: "Logistics tracking number",
    logisticsTrackingNumberTips: "Please enter the tracking number",
    confirmReturn: "Confirm return",
    infoSubmitted: "Information submitted",
    formField:"Please enter the required field before operation",
    beenConfirmed: "Receipt has been confirmed"
  },
  orderDetails: {
    returnToPrevious: "Return to previous page",
    sweetheart: "Sweetheart",
    checkTheFollowing: "Please check the following order information",
    aboutYourOrderInfo: "Here is all the information about your order",

    pendingPayment: "Order pending payment",
    orderCancelled: "Order cancelled",
    orderDeleted: "Order deleted",
    notApproved: "The order was not approved",
    pendingReview: "Order pending review",
    orderFilled: "Order to be filled",
    inProduction: "In production",
    leaving: "The order is leaving the warehouse",
    pendingDelivery: "Order pending delivery",
    maintenance: "After maintenance",
    beReceived: "Order to be received",
    beShipped: "Order to be shipped",
    completed: "The order is completed",
    beEvaluated: "Order to be evaluated",
    readyForShipment: "The order is ready for shipment",
    afterSale: "Order after sale",

    commodityInformation: "Commodity information",
    orderNumber: "Order number",
    customizedForTA: "Customized for TA",
    personalCustomization: "Personal customization",
    viewCustomizationDetails: "View customization details",
    quantity: "Quantity",
    measurementReport: "Measurement report",
    notReport: "You have no measurement report record, click to make an appointment for measurement",
    reselect: "reselect",
    keepChoosing: "Keep choosing",
    selectMeasurementReport: "Select measurement report",
    subtotal: "Subtotal",
    otherInfo: "Other Information",
    deliveryAddress: "Delivery address",
    notAddressData: "You have no address record, please add the delivery address first",
    addAnAddress: "Add address",
    selectAddress: "Select address",
    notBeShippedTips: "Demo orders do not need to be shipped",
    logisticsFreight: "Logistics freight",
    logisticsFreightTips: "The logistics freight service of each country is different, and it will be slightly different according to the delivery address. The specific situation can be consulted by the customer service",
    checkRemarkTips: "Here you can enter the order requirements note information",
    submittingTips: "By submitting an order, you accept our offer",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfSale: "Terms of sale",
    totalOrder: "Total order",
    actualPayment:"Actual payment",
    immediatePayment: "Immediate Payment",
    customerService: "Consult customer service",
    viewLogistics: "View logistics",
    balance: "Balance",
    customInsoles:"Custom insoles",
    checkShippingTips: "Please select your shipping address",
    paymentMethod: "Please select a payment method",
    notCheckPrivacyPolicyTips: "Please check the box to read the Privacy Policy and Terms of Sale before making payment",
    notQuantity: "The quantity of some products is 0, please check",
    checkMeasurementReportTips: "Please select the measurement report before payment",
    checkMeasurementBodyReportTips: "Please select the body measurement report before payment",
    notCheckReportData: "You have not selected the gait measurement report data, may I ask whether you will continue to pay for the order",
    tips: "Tip: After paying the order, you can contact the customer service at 400-686-6899 to reserve the measurement service",
    returnOrder: "Return order",
    continueToPay: "Continue to pay",
    arrangeDelivery: "We will arrange delivery as soon as possible, please wait patiently",
    availableStock: "Available stock has been exceeded",
    uniPrivacyPolicy: "UNI Privacy Policy",
    close: "close",
    personalizationOptions: "Personalization options",
    subtotal2: "subtotal",
    collapseCustomizationDetails: "Click to collapse customization details",
    logisticsSituation: "Logistics situation",
    putAwayLogistics: "Click to put away logistics",
    shipping: "Please select a shipping address",
    addAddress: "Add address",
    default: "Default",
    confirmSelection: "Confirm selection",
    myReport: "My report",
    gauger: "Gauger",
    notReportData: "There is no content in your report",
    footMeasurement: "foot Measurement",
    gaitMeasurement: "Gait measurement",
    confirmBalanceTips: "Please confirm whether the balance will be paid",
    balanceIsInsufficientTips: "The balance is insufficient, please choose another payment method",
    currentBalance: "Current balance",
    insufficientBalance: "insufficient Balance",
    orderAmount: "Order amount",
    balanceAfterPayment: "Balance after payment",
    chooseAnother: "Please choose another payment method",
    selectFirst: "Please select first",
  },
  myReport: {
    myReportTitle: "My report",
    gauger: "Gauger",
    viewDetails: "View details",
    delete: "delete",
    notData: "There is no content in your report",
    delTips: "After it is deleted, it cannot be restored. Please confirm whether to delete it",
    footMeasurement: "Foot measurement",
    gaitMeasurement: "Gait measurement",
    bodyMeasurement: "Body measurement",
    deletedReport: "The measurement report has been deleted",

    back: "Back",
    footMeasurementReport: "Foot measurement report",
    phone: "Phone",
    reportNumber: "Report number",
    date: "Date",
    measurementResult: "Measurement result",
    leftFoot: "Left foot",
    rightFoot: "Right foot",
    reference : "Reference value",
    severeInternal: "Severe internal/eversion",
    moderateInternal: "Moderate internal/eversion",
    mildInternal: "Mild internal/ectropion",
    normalHeel: "Normal heel",
    normal: "Normal",
    mild: "Mild",
    moderate: "Moderate",
    severe: "Severe",
    footData: "Foot data",
    lengthDimension: "Length dimension",
    name:"Name",
    widthSize: "width dimension",
    girthDimension: "Girth dimension",
    heightDimension: "Height dimension",
    heelSize: "Heel size",
    measurementSuggestion: "Measurement Suggestion",
    suggestionTips: "Left foot: 44 size, right foot: 43.5 size, it is recommended to wear appropriate shoes and insole during sports and exercise to protect your ankle and joints",
    threeDFootModel: "ThreeD foot model",
    archReport: "Arch report",
    heelReport: "Heel report",
    bunionValgusReport: "Bunion valgus report",
    footFormReport: "Foot form report",
    ArchType: "Arch type",
    heelType: "Heel type",
    halluxValgus: "Hallux valgus",
    footShape: "Foot shape",
    fiveNormalFoot: "Type 5 normal foot",
    archEvaluation: "Arch evaluation",
    rolloverEvaluation: "Heel rollover evaluation",
    thumbValgus: "Thumb valgus evaluation",
    footShapeEvaluation: "Foot shape evaluation",
    mildFlatFeet: "Type 7 mild flat feet",
    archedFoot: "Arched foot",
    normalFoot: "Normal foot",
    mildFlatFoot: "mild Flat Foot",
    moderateFlatFoot: "moderate Flat Foot",
    severeFlatFoot: "severe Flat Foot",
    squareFoot: "Square foot",
    greekFoot: "Greek foot",
    romanFoot: "Roman foot",
    egyptFoot: "Egypt foot",

    lengthOfFoot: "Length of foot",
    halluxConvex: "Length of the hallux convex point",
    littleToe: "Length of the outer bump of the little toe",
    firstMetatarsophalangeal: "First metatarsophalangeal length",
    fifthMetatarsophalangeal: "Fifth metatarsophalangeal length",
    lumbarFossa: "The length of the lumbar fossa",
    heelCenter: "The length of the heel center",
    tarsalSite: "Length of tarsal site",
    circumference: "Width of plantar circumference",
    halluxIsWide: "The hallux is wide in",
    wideOutside: "The little toe is wide outside",
    firstMetatarsophalangealWidth: "The first metatarsophalangeal is medial wide",
    fifthMetatarsophalangealWidth: "The fifth metatarsophalangeal toe is wide",
    toeWidth: "Toe width",
    toeDiagonalFrame: "Toe diagonal frame",
    plantarCircumference: "The plantar circumference is oblique and wide",
    metatarsophalangealCircumference: "Metatarsophalangeal circumference",
    circumferenceOfTarsus: "Circumference of tarsus",
    pocketRoot: "Circumference of pocket root",
    heightOfPlantar: "Height of plantar circumference",
    heightOfThumb: "Height of thumb",
    heightOfTarsus: "Height of tarsus",
    heelBump: "Height of heel bump",
    lateralMalleolus: "Lateral malleolus height",
    heightOfArch: "Height of arch"
  },
  myAddressList: {
    myAddress: "My address",
    setToDefault: "Set to default",
    delete: "delete",
    clickAddAddress: "Click Add address",
    back: "Back",
    editTheAddress: "Edit the address",
    addNewAddress: "Adding a new address",
    delTips: "It will not be restored after deletion, please confirm whether to delete",
    setDefaultAddress: "The default address has been set successfully",
    cancelDefaultAddress: "The default address has been cancelled",
    beenDeleted: "The address has been deleted",
    warmReminder: "Warm reminder",
    close:"close",
    cancel: "cancel",
    confirm: "confirm"
  },
  addAddressForm:  {
    country: "Country/region",
    checkCountryTips: "Please select a country/region",
    postcode: "Postcode (not required)",
    checkPostcodeTips: "Please enter the postcode",
    appellation: "Appellation",
    checkAppellation: "Please select your appellation",
    surname: "Surname",
    checkSurname: "Please enter your surname",
    name: "Name",
    checkNameTips:"Please enter your name",
    province: "Province",
    checkProvince: "Please select your province",
    city: "City",
    checkCity: "Please select your city",
    area: "Area",
    checkArea: "Please select your district",
    detailedAddress: "Detailed address",
    checkDetailedAddress: "Please enter your address details",
    phone: "Phone",
    checkPhone: "Please enter your phone",

    cancel: "Cancel",
    update: "Update",
    save: "Save",
    gentleman: "Gentleman",
    lady: "Lady",

    updatedAddressTips: "Address information has been updated",
    addedAddressTips: "The address information is added",
    fieldsForm: "Please complete the required fields before operation"
  },
  personal: {
    logOut: "Logout",
    balance: "Balance",
    membershipCard: "Membership card",
    integral: "Integral",
    personalInfo: "Personal Information",
    myShopWindow:"My shop window",
    myOrder: "My order",
    myReport: "My report",
    shipping: "Manage the shipping address"
  },
  footerBar: {
    newsletter: "Subscribe to UNI newsletter",
    bySubscribing: "By subscribing, you accept the terms of UNI's Privacy Policy",
    checkEmail: "Please enter your email address",
    customerService: "Customer service",
    aboutUs: "About Us",
    digitalShoe: "Digital shoe customization service",
    digitalClothing: "Digital clothing customization service",
    brandDigital: "Brand digital empowerment service",
    channelDigital: "Channel digital efficiency service",
    brandHigh: "Brand high definition",
    designerStyle: "Designer style",
    storeInquiry: "Store Inquiry",
    wallOfwWorks: "Wall of works",
    designer: "Designer",
    becomeDealer: "Become a dealer",
    brandCooperation: "Brand cooperation",
    contactUs: "Contact us",
    shipping: "Shipping and Returns",
    payment: "Price and Payment",
    ourHistory: "Our History",
    sustainable: "Sustainable development",
    sendSubscribeTips:"Please enter the email address before operation"
  },
  fixedNextPage:  {
    previousPage: "PreviousPage",
    nextPage: "Next page",
    digitalClothing: "Digital clothing customization service",
    digitalShoe: "Digital shoe customization service",
    brandDigital: "Brand digital empowerment service",
    channelDigital: "Channel digital efficiency service",
    brandHigh: "Brand high definition",
    uniRecommendation: "UNI recommendation",
    designerStyle: "Designer style"
  },
  ourHistory: {
    ourHistoryText: "Our history",
    unique: "UNI represents the uniqueness of the English language",
    attractive: "\"Unique is more attractive than perfect\" is the core value pursued by UNI",
    uniDesc: "We are eager to find a gentler way to communicate in the rapidly expanding rhythm of industry, network, technology, and the interconnection of everything, helping people stop looking and choose to create. We condense complex technology into sophisticated measurements The equipment converts boring data into original custom shoe last, combines technology and craft, personality and character, lets everyone be their own designer, and lets the world embrace diversity and openness!",

    year: "The year 2017",
    mainTitle: "Extraordinary start",
    mainDesc: "Originator Katsuo Saito",
    desc: "Mr. Katsuo Saito formed the core research and development team to explore customized technologies",

    year1: "The year 2021",
    mainTitle1: "Dongguan operation center put into use",

    year2: "June 2021",
    mainTitle2: "UNI Technology was incorporated",

    year3: "August 2021",
    certificate: "UNI Technology Section 1 Patent Certificate",
    firstPatent: "The first patent landed",

    year4: "March 2022",
    pressConference: "Milan press conference",
    mainDesc4: "UNI technology conference in Milan and New York respectively",

    year5: "May 2022",
    mainTitle5: "Smart factory diagram",
    mainDesc5: "Smart factory built",

    year6: "August 2022",
    mainTitle6: "UNI Dongguan flagship store",
    mainDesc6: "Dongguan flagship store and Shenyang flagship store opened",
    desc6: "In the same year, Fengjing pop-up store and Haikou flagship store were opened simultaneously"

  },
  sustainable: {
    responsibility: "Social responsibility",
    responsibilityTips1: "Every day, we strive to create products that are healthy, environmentally friendly and comfortable",
    responsibilityTips2: "We care about the earth and the people who live on it",

    title: "Social responsibility",
    desc: "We are committed to creating products with a story, so that each product from the soul of his shoe last to the exterior color color, from the detail carving to the partial design can show the wearer's unique taste. We eclectically choose Italy, Japan, China and other places of the process technology, in order to meet the consumer's inner desire for the perfect shoes, with quality to elaborate character. We continue to use science and technology to break through the boundaries of imagination, link the z era to master the discourse power of new media, concretize the abstract concepts of the meta-universe and digital images, let the lonely human beings realize space-time dialogue through the network, and make technology no longer distant but real and interesting.",

    title1: "Low carbon and environmental protection",
    desc1: "We were born in the era of calling for earth protection, and naturally pay more attention to biological diversity, advocate energy conservation and emission reduction and promote sustainable development, use environmentally friendly reusable materials to reduce resource costs and losses, and meet the environmental challenges of the new century. UNI started with technology, we use digital measurement and intelligent manufacturing, between traditional custom and industrial production, to achieve a total solution of \"mass customization\". Through the collaboration of the consumer end, the platform end and the production end, the non-essential consumption generated by the industrial age is resolved, and the wearer is committed to meeting the needs of the modern urban population, providing a full range of future customized experience for the wearer.",

    title2: "Healthy ecology",
    desc2: "With Professor Zhang Jianzhong, a famous foot and ankle medical expert as the core, UNI Technology has a professional team of more than 1,000 surgical experts from inside and outside the United Nations to provide technical support from 3D measurement, biomechanical analysis to foot health management. With biomechanics, physical training, rehabilitation AIDS, and advanced physical rehabilitation technology as the core, the company has established extensive cooperative relations with Germany, France, the United States, Italy, Spain and other countries, providing gait analysis, physical training, foot ridge detection, rehabilitation training systems, and providing comprehensive solutions for scientific research, national teams, sports, and medical institutions."
  },
  contactUsPage: {
    contactUs: "Contact us",
    contactUsTips1: "Every day, we strive to create products that are healthy, environmentally friendly and comfortable",
    careAbout: "We care about the earth and the people who live on it",
    serviceHotline: "Service hotline",
    phoneCall: "Mobile phone call",
    landlineCall: "Landline call",
    workTime: "Monday through Friday from 8:25 a.m. to 6:00 p.m",

    alsoInfo: "You can also inform us directly by leaving a message on the website",
    informationNeeded:"Information needed",
    helpTips: "How can we help you",
    problem: "Problem",
    describe: "Describe",
    contactInfo: "Contact information",
    getPhoneTips: "Please inform us of your contact information",
    country: "Country/region",
    checkCountry: "Please select a country/district",
    checkDistrict: "Please select a district",
    name: "Name",
    checkName: "Please enter your name",
    appellation: "Appellation",
    checkAppellation: "Please select a appellation",
    InternationalAreaCode: "International area code",
    checkInternationalAreaCode: "Please select an international area code",
    phone: "Phone",
    checkPhone: "Please enter your phone number",
    email: "Email",
    checkEmails: "Please enter your email number",
    send: "Send",
    gentleman: "Gentleman",
    lady: "Lady",
    applicationApproved: "Application approved",
    formFields: "Please complete the required fields before operation"
  },
  design: {
    fullScreen: "Full screen display",
    modelScreenshot: "Model screenshot",
    designSignature: "Design signature",
    joinShowWindow: "Join the shop window",
    footFitting: "Foot fitting",
    digitalHuman: "Digital human fitting",
    MRFitting: "MR fitting",
    leftClickDrag: "Left-click drag to rotate the 3D model, and the scroll wheel can be scaled to see details",
    selected: "Selected",
    buyNow: "Buy now",
    personalized: "Personalized customization options",
    subtotal: "Subtotal",
    collapseCustomization: "Click to collapse customization details",
    tips: "Tips",
    checkNameTips: "Please select ${name} and regenerate",
    screenshotSuccess: "Screenshot success",
    pleaseLogIn: "Please Login",
    development: "This feature is under development",
    UpdateSuccessfully: "Update successfully",
    joinSuccessfully: "Join successfully",
    warmReminder: "Warm reminder",
    continueEditing: "Continue editing",
    abandonChange: "Abandon change",
    abandonTips: "You have selected different styles of ${curName} design, did not confirm the generation, whether to abandon the change, use the original design content",
    successfulPurchase: "Successful purchase",
    previous: "Previous",
    nextItem: "Next",
    total: "Total",

    signatureDesign: "Signature design",
    checkSignatureDesign: "Please write your signature below",
    enterCheckSignatureDesign: "Please enter your signature below",
    reset: "Reset",
    checkDesignTips: "Please design your signature",
    customizeColor: "You can customize the signature color, please choose a favorite color",
    hidden: "Hidden",
    confirmGeneration: "Confirm generation",
    signatureLocationDesign: "Signature location design",
    mouseTips: "Hold down the left mouse button to drag the position, hover the scrolling pulley to zoom the size",
    printDesign: "Print design",
    favorite: "You can upload your favorite prints in the following ways",
    selectLocalImages: "Select local images",
    transmitPictures: "Scan code to transmit pictures",
    historyTransfer: "History transfer",
    printPositionDesign: "Print position design",
    strugglingToLoad: "Struggling to load",
    pleaseGenerate: "Please generate your print",
    mobileWebsiteL: "Scan the mobile website and upload your favorite customized pictures",
    tip: "Tip: It is recommended to use a transparent background picture, the design effect will be better",
    step: "Step tips",
    tips1: "Open the global website of Youyou on mobile, click to scan, scan the left QR code",
    tips2: "Check your favorite custom images from youralbum",
    confirmSelect: "Confirm the selection and wait for the image to upload",
    tips4: "After the image is uploaded successfully, you can hold down the drag position of the image and zoom in and out",
    uploaded: "You can view your uploaded pictures in the history transfer",
    checkFavorite: "Please select your favorite picture",
    cancel: "Cancel",
    confirmSelection: "Confirm selection",
    checkSignature: "Please enter your signature",
    writeSignatureTips: "Please write signature",
    mapToLoad: "Please wait for the map to load",
    error: "Error",
    notCheckPrintPattern: "No print pattern is selected",
    tipsText:"Tips",
    noHistorical: "No historical transfer content is available",
    selectPicture: "Please select a picture first"
  }
};
