import BasicLayout from "@/layout/basic-layout.vue";
import home from "@/router/modules/home"
import solution from "@/router/modules/solution"
import digitalCustom from "@/router/modules/digitalCustom"
import globalDesigner from "@/router/modules/globalDesigner"
import digitalEmpowermentProgram from "@/router/modules/digitalEmpowermentProgram"
import design from "@/router/modules/design"
import about from "@/router/modules/about"
import user from "@/router/modules/user"
import customerService from "@/router/modules/customerService"
import otherModule from "@/router/modules/otherModule"

const routes = [
  {
    path: "/",
    redirect: "/home/homePage"
  },
  home,
  solution,
  digitalCustom,
  globalDesigner,
  digitalEmpowermentProgram,
  design,
  about,
  user,
  customerService,
  otherModule,
];
export default routes;
