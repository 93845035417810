import BasicLayout from "@/layout/basic-layout.vue"

export default {
  path: "/global_designer",
  name: "globalDesigner",
  component: BasicLayout,
  children: [
    {
      path: "global_designerPage",
      name: "global_designerPage",
      component: () => import("@/views/global_designer/index.vue"),
    },
  ]
}
