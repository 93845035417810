import { createI18n } from "vue-i18n"
import ja from "./ja"
import en from "./en"
import zh from "./zh"

// 定义默认语言为中文
const locale = localStorage.getItem("lang") ? localStorage.getItem("lang") : "zh"
const i18n = createI18n({
  locale,
  legacy: false, // 不使用遗留的api
  globalInjection: true, //全局注入
  messages: {
    ja,
    en,
    zh
  }
})

export default i18n
