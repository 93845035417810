import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "amfe-flexible"
import "@/assets/styles/index.scss"
import utils from "@/utils/index.js"
import i18n from "@/lang"
import lottie from "lottie-web"

// 手动按需引入 UI组件
import naive from "@/utils/on-demand-introduction-naiveui"
import "@/assets/styles/common.scss"
import "@/assets/font/font.css"
import "animate.css";


document.body.style.fontSize = 14 + "px" // 覆盖 12px

const isLoad = sessionStorage.getItem("isLoad")
if (isLoad === null) {
  // 定时器等待dom加载完成
  let timer = setInterval(() => {
    const isLoad = sessionStorage.getItem("isLoad")
    if (isLoad) {
      clearInterval(timer)
      setTimeout(() => {
        const app = createApp(App)
        app.use(naive)
        app.config.globalProperties.$lottie = lottie
        app.config.globalProperties.$px2rem = utils.px2rem
        // getLocation()
        app.use(i18n).use(store).use(router).mount("#app")
      }, 600)
    }
  }, 600)
} else {
  const app = createApp(App)
  app.use(naive)
  app.config.globalProperties.$lottie = lottie
  app.config.globalProperties.$px2rem = utils.px2rem
  // getLocation()
  app.use(i18n).use(store).use(router).mount("#app")
}
