import caches from "@/utils/caches"
import ApiUser from "@/api/user";

const login = {
  state() {
    return {
      // token: "",
      userInfo: null,
      showLoginPanel: false
    }
  },
  getters: {
    userInfo: (state) => {
      return state.userInfo
    }
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
      caches.setCache("userInfo", userInfo)
    },
    LOGIN_OUT(state) {
      state.userInfo = null
      caches.removeCache("global_token")
      caches.removeCache("expires_time")
      caches.removeCache("userInfo")
      window.$router.replace("/home/homePage")
    },
    SET_SHOW_PANEL(state, show) {
      state.showLoginPanel = show
    }
  },
  actions: {
    async updateGetUserInfo({ state, commit }, payload){
      const userInfoRes = await ApiUser.getUserInfos()
      commit("SET_USER_INFO",userInfoRes.data)
    },
  }
}
export default login
