const design = {
  state() {
    return {
      list: [],
      selectObjs: {} // 已设计的模型数据
    }
  },
  getters: {
    designList: (state) => {
      return state.list.filter((item) => {
        if (item.name !== "鞋垫") {
          return item
        }
      })
    },
    selectObjs: (state) => {
      return state.selectObjs
    }
  },
  mutations: {
    SET_DESIGN_DATA(state, obj) {
      state.list.push(obj)
    },
    SET_SELECT_OBJS(state, obj) {
      state.selectObjs[obj.id] = obj
    },
    DEL_SELECT_OBJS(state, key) {
      delete state.selectObjs[key]
    },
    RESET_SELECT_OBJS(state) {
      state.selectObjs = {}
    }
  }
}

export default design
