
const order = {
  state(){
    return {
      orderInfo: {},
      checkOrderInfo: {
        report: null,
        cartList: [],
        addressInfo: null,
        isAgree: null,
        payType: null,
        orderRemark: null,
        currentPay: null
      }
    }
  },
  getters: {
    getCheckOrderInfo: (state) => {
      return state.checkOrderInfo
    }
  },
  mutations: {
    SET_ORDER_DATA_INFO(state,payload){
      state.orderInfo = payload
    },
    SET_REPORT(state,payload) {
      state.checkOrderInfo.report = payload
    },
    SET_CART_LIST(state,payload){
      state.checkOrderInfo.cartList = payload
    },
    SET_ADDRESS(state,payload){
      state.checkOrderInfo.addressInfo = payload
    },
    SET_IS_AGREE(state,payload) {
      state.checkOrderInfo.isAgree = payload
    },
    SET_PAY_TYPE(state,payload){
      state.checkOrderInfo.payType = payload
    },
    SET_ORDER_REMARK(state,payload) {
      state.checkOrderInfo.orderRemark = payload
    },
    SET_CURRENT_PAY(state,payload){
      state.checkOrderInfo.currentPay = payload
    },
    INIT_CHECK_ORDER_INFO(state){
      state.checkOrderInfo.report = null
      state.checkOrderInfo.addressInfo = null
      state.checkOrderInfo.isAgree = null
      state.checkOrderInfo.payType = null
      state.checkOrderInfo.orderRemark = null
      state.checkOrderInfo.cartList = []
      state.checkOrderInfo.currentPay = null
    }
  }
}

export default order
