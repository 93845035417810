import "core-js/modules/es.number.constructor.js";
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "icon"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "message-text"
};
import { onMounted, ref, nextTick, watch } from "vue";

/**
 * 弹窗信息组件
 * @param {object} props
 * @param {Number} duration 弹窗持续时间 千位数 2000
 * @param {string} to 弹窗挂载的元素
 * @param {boolean} showIcon 是否显示icon
 * @param {String} icon 弹窗icon图标地址
 *
 */

export default {
  __name: 'index',
  props: {
    // content: {
    //   type: String,
    //   default: ""
    // },
    duration: {
      type: Number,
      default: 2000
    },
    to: {
      type: String,
      default: ""
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/uni-ui/message-info.svg"
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    var isShow = ref(false); // 控制显示过渡动画
    var uniMessage = ref(null); // 弹窗组件实例
    var content = ref("");
    watch(isShow, function (val) {
      if (val) {
        close(); // 处理弹窗持续时间结束后关闭
      }
    });

    onMounted(function () {
      initAddElement();
    });
    // 初始化弹窗 挂载到页面元素实例
    // const initMessage = (val) => {
    //   initAddElement()
    // }

    // 初始化时如果有存在先删除
    var initRemoveElement = function initRemoveElement() {
      if (uniMessage.value) {
        removeElement();
      }
    };
    var removeElement = function removeElement() {
      var element = document.querySelector(props.to);
      if (!props.to) {
        document.body.removeChild(uniMessage.value);
      } else if (element) {
        element.removeChild(uniMessage.value);
      }
    };
    // 初始化添加
    var initAddElement = function initAddElement() {
      if (!props.to) {
        document.body.appendChild(uniMessage.value);
      } else {
        var element = document.querySelector(props.to);
        element.appendChild(uniMessage.value);
      }
    };

    // 打开信息弹窗 蓝色
    var info = function info(val) {
      content.value = val;
      setTimeout(function () {
        nextTick(function () {
          isShow.value = true;
        });
      }, 50);
    };

    // 处理弹窗持续时间结束后关闭并从元素中移除
    var close = function close() {
      setTimeout(function () {
        isShow.value = false;
        content.value = "";
        if (uniMessage.value) {
          // removeElement()
        }
      }, props.duration);
    };
    expose({
      info: info
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "uniMessage",
        ref: uniMessage,
        class: _normalizeClass(["uni-message uni-message-top", [_unref(isShow) ? 'show-message' : '']])
      }, [props.showIcon ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        style: {
          "vertical-align": "middle"
        },
        width: "20",
        height: "20",
        src: props.icon,
        alt: ""
      }, null, 8, _hoisted_2)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(content)), 1)], 2);
    };
  }
};