import axios from "axios"
// import caches from "@/utils/caches";
import store from "@/store"

const baseApi = process.env.VUE_APP_BASE_API
const baseApiWebsite = process.env.VUE_APP_WEBSITE_API
const outApiWebsite = process.env.VUE_APP_OUT_API
const service = axios.create({
  // baseURL: baseApi,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("global_token")
    let lang = localStorage.getItem("lang")
    let translateObj = {
      translate_params: {
        formatType: "text",
        sourceLanguage: "zh",
        targetLanguage: lang
      }
    }
    if (config.params && config.params.noTranslate) {
      translateObj.translate_params.noTranslate = config.params.noTranslate
    }
    if (!config.urlType) {
      if (!config.url.includes("http")) {
        config.url = baseApi + config.url
      }
      if (token) {
        config.headers["Authori-zation"] = "Bearer " + JSON.parse(token)
      }
      // 不是上传类型请求时才执行多语言赋值
      // if (config.url.indexOf("/upload/") === -1) {
      if (config.isTranslate == undefined) {
        if (config.method === "get") {
          config.params = { ...config.params, translate_params: translateObj.translate_params }
          delete config.params.noTranslate
        } else {
          config.data = { ...config.data, translate_params: translateObj.translate_params }
          delete config.data.noTranslate
        }
      }
    } else if (config.urlType === "website") {
      config.url = baseApiWebsite + config.url
    } else if (config.urlType === "outapi") {
      config.url = outApiWebsite + config.url
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // 未设置状态码则默认成功状态
    const code = response.data.status || 200
    const msg = response.data.msg
    // 获取错误信息
    //const msg = errorCode[code] || response.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (response.request.responseType === "blob" || response.request.responseType === "arraybuffer") {
      return response.data
    }
    switch (code) {
      case 400:
        window.$message.error(msg)
        break
      case 410000:
        // caches.removeCache("expires_time")
        // caches.removeCache("userInfo")
        // caches.removeCache("global_token")
        store.commit("LOGIN_OUT")
        store.commit("SET_SHOW_PANEL", true)
        // location.reload()
        window.$message.error(msg)
        break
    }
    return Promise.resolve(response.data)
    // if (code === 401) {
    //   ElMessage.error("登录状态已过期，请重新登录");
    //   removeToken();
    //   router.push("/login");
    //   return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    // } else if (code === 500) {
    //   ElMessage({ message: msg, type: "error" });
    //   return Promise.reject(new Error(msg));
    // } else if (code === 601) {
    //   ElMessage({ message: msg, type: "warning" });
    //   return Promise.reject(new Error(msg));
    // } else if (code !== 200) {
    //   ElMessage({ message: msg, type: "warning" });
    //   return Promise.reject("error");
    // } else {
    // return Promise.resolve(response.data);
    // }
  },
  (error) => {
    console.log("error", error)
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = `请求错误 ${error.response.data.msg}`
          break
        case 401:
          error.message = "未授权，请登录"
          break
        case 403:
          error.message = "拒绝访问"
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.message = "请求超时"
          break
        case 500:
          error.message = "服务器内部错误"
          break
        case 501:
          error.message = "服务未实现"
          break
        case 502:
          error.message = "网关错误"
          break
        case 503:
          error.message = "服务不可用"
          break
        case 504:
          error.message = "网关超时"
          break
        case 505:
          error.message = "HTTP版本不受支持"
          break
        default:
          break
      }
    }
    // let { message } = error.response.data.msg;
    // if (message == "Network Error") {
    //   message = "后端接口连接异常";
    // } else if (message.includes("timeout")) {
    //   message = "系统接口请求超时";
    // } else if (message.includes("Request failed with status code")) {
    //   console.log(message.substring(message.length - 3, message.length));
    //   message =
    //     "系统接口" +
    //     message.substring(message.length - 3, message.length) +
    //     "异常";
    // }
    // ElMessage({ message: message, type: "error", duration: 5 * 1000 });
    window.$dialog.warning({ title: "请求异常", content: `${error.message}：${error.response.data.msg}` })
    return Promise.reject(error)
  }
)

// function request(params) {
//   if (params.url.includes("http")) {

//   } else {
//     return service(params)
//   }
// }
export default service
