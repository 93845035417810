import BasicLayout from "@/layout/basic-layout.vue"
export default {
  path: "/user",
  name: "user",
  component: BasicLayout,
  children: [
    {
      path: "personal_account",
      name: "personalAccount",
      component: () => import("@/views/user-module/index.vue"),
      redirect: "/user/personal_account/my_account",
      children: [
        {
          path: "my_account",
          name: "myAccount",
          component: () => import("@/views/user-module/my-account.vue")
        },
        {
          path: "order_list",
          name: "orderList",
          component: () => import("@/views/user-module/order-list.vue")
        },
        {
          path: "my_report",
          name: "myReport",
          component: () => import("@/views/user-module/my-report.vue")
        },
        {
          path: "my_delivery_address",
          name: "myDeliveryAddress",
          component: () => import("@/views/user-module/my-delivery-address.vue")
        },
        {
          path: "my_purse",
          name: "myPurse",
          component: () => import("@/views/user-module/my-purse.vue")
        }
      ]
    },
    {
      path: "shopwindow",
      name: "shopWindow",
      component: () => import("@/views/user-module/my-shopwindow.vue")
    },
    {
      path: "shop-bag",
      name: "shopBag",
      component: () => import("@/views/user-module/my-shop-bag.vue")
    },
    {
      path: "order_list/order_details",
      name: "orderDetails",
      meta: {
        keepAlive: true,
      },
      component: () => import("@/views/user-module/order-module/order-details.vue")
    }
  ]
}
