import request from "@/utils/request";

export default class ApiHome {
  // 登录
  static login(data) {
    return request({
      url: "api/v2/login",
      method: "POST",
      data,
    });
  }
  // 验证输入的是邮箱/手机号
  static checkAccount(data) {
    return request({
      url: "api/v2/check_account",
      method: "POST",
      data,
    });
  }
  // 发送验证码
  static sendCode(data) {
    return request({
      url: "api/v2/send_code",
      method: "POST",
      data,
    });
  }
  // 登出
  static logout(data) {
    return request({
      url: "api/logout",
      method: "GET",
      data,
    });
  }
}
