import BasicLayout from "@/layout/basic-layout.vue"

export default {
  path: "/other_module",
  name: "otherModule",
  component: BasicLayout,
  children: [
    {
      path: "reservation_measuremenet",
      name: "reservationMeasuremenet",
      component: () => import("@/views/other_module/reservation-measurement.vue")
    },
    {
      path: "service_point",
      name: "servicePoint",
      component: () => import("@/views/other_module/reservation-measurement-module/store-map.vue")
    },
    {
      path: "ai_digital_collocation",
      name: "aiDigitalCollocation",
      component: () => import("@/views/other_module/ai-digital-collocation.vue")
    },
    {
      path: "search_page",
      name: "searchPage",
      component: () => import("@/views/other_module/search-page.vue")
    },
    {
      path: "privacy",
      name: "privacy",
      meta:{
        isBack: true
      },
      component: () => import("@/views/other_module/privacy-policy.vue")
    }
  ]
}
