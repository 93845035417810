import { createStore } from "vuex"
import design from "./modules/design"
import login from "./modules/login"
import order from "./modules/order"

export default createStore({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: { design, login, order }
})
