import request from "@/utils/request"

export default class ApiUser {
  static getUserInfos(params) {
    let noTranslateObj = {
      noTranslate: {
        data: {
          real_name: "",
          nickname: "",
          first_name: "",
          last_name: ""
        }
      }
    }
    let mergeNoTranslateObj = {
      ...params,
      ...noTranslateObj
    }
    return request({
      url: "api/user",
      method: "GET",
      params: mergeNoTranslateObj
    })
  }
  // 获取用户信息
  static getPersonalInfo(params) {
    let noTranslateObj = {
      noTranslate: {
        data: {
          real_name: "",
          nickname: "",
          first_name: "",
          last_name: ""
        }
      }
    }
    let mergeNoTranslateObj = {
      ...params,
      ...noTranslateObj
    }
    return request({
      url: "api/userinfo",
      method: "GET",
      params: mergeNoTranslateObj
    })
  }
  // 更新用户信息
  static updateUserInfo(data) {
    return request({
      url: "api/v2/user/user_update",
      method: "POST",
      data
    })
  }
  // 上传图片
  static uploadImg(data) {
    return request({
      url: "api/upload/user_image",
      method: "POST",
      isTranslate: false,
      data
    })
  }
  // 发送设计师邀请协作
  static sendInvite(data) {
    return request({
      url: "api/v2/user/design/assist",
      method: "POST",
      data
    })
  }
  // 获取用户会员信息
  static getMemberInfo() {
    return request({
      url: "api/user/level/info",
      method: "GET"
    })
  }
  // 获取用户测量报告列表
  static getMeasurementReport(params) {
    return request({
      url: "api/v2/measure/record/page_new",
      method: "GET",
      params
    })
  }
  // 获取用户测量报告详情信息
  static getMeasurementDetailInfo(id, params) {
    return request({
      url: `api/v2/measure/reportData/${id}`,
      method: "GET",
      params
    })
  }
  // 删除用户测量报告
  static delMeasurement(id) {
    return request({
      url: `api/v2/measure/delete/${id}`,
      method: "DELETE"
    })
  }
  // 获取用户地址列表
  static getAddressList(params) {
    return request({
      url: "api/address/list",
      method: "GET",
      params
    })
  }
  // 添加/更新用户地址列表
  static updateAddress(data) {
    return request({
      url: "api/address/edit",
      method: "POST",
      data
    })
  }
  /**
   * 获取默认地址
   */
  static getAddressDefault(params) {
    return request({
      url: "api/address/default",
      method: "GET",
      params
    })
  }
  /**
   * 设置默认地址
   * @param  { object } data
   * @param  { number } data.id 地址id
   */
  static setAddressDefault(data) {
    return request({
      url: "api/address/default/set",
      method: "POST",
      data
    })
  }
  /**
   * 删除地址
   * @param  { object } data
   * @param  { number } data.id 地址id
   */
  static delAddress(data) {
    return request({
      url: "api/address/del",
      method: "POST",
      data
    })
  }
  /**
   * 获取国家列表
   * @param  {{is_page: number}} params
   * @param  { boolean(number) } params.is_page 是否分页(0|1)
   * @param  { string } params.name 序列化数组国家名称
   */
  static getCountryList(params) {
    return request({
      url: "api/v2/get_country_list",
      method: "GET",
      params
    })
  }
  /**
   * 获取城市列表
   * @param  { object } params
   * @param  { number|string } params.country 城市code
   */
  static getCityList(params) {
    return request({
      url: "api/city_list",
      method: "GET",
      params
    })
  }
  // 获取文章详情
  static getArticleDetails(id) {
    return request({
      url: "api/article/details/" + id,
      method: "GET"
    })
  }
}
