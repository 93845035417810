export default {
  monetaryUnit: "元",
  navMenus: {
    homePage: "首页",
    solution: "解决方案",
    digitalShoe: "数字化鞋履定制服务",
    digitalClothing: "数字化服装定制服务",
    brandDigital: "品牌数字化赋能服务",
    channelDigital: "渠道数字化增效服务",
    digitalCustomization: "数字化定制",
    UNIRecommended: "UNI推荐",
    brandHigh: "品牌高定",
    designer: "设计师款",
    globalDesigner: "全球设计师",
    ET: "数字赋能计划",
    reservationSurvey: "预约测量",
    searchText: "搜索"
  },
  loginModal: {
    memberLogin: "会员登录",
    existingAccountTips: "已有账户请登录",
    account: "账户（手机号/电子邮箱地址）",
    areaCodeTips: "请选择国际区号",
    accountTips: "请输入账号",
    verificationCodeText: "验证码",
    verificationCodeTextTips: "请输入验证码",
    getCodeText: "获取验证码",
    resend: "重新发送",
    loggingTips: "登录即代表您接受我们的",
    privacyPolicy: "隐私政策",
    loginText: "登录",
    registeredNot: "我没有注册过任何账户",
    mainTips: "第一次账户验证登录会自动为您创建MyUNI账户，后续您可以通过任何设备",
    checkTips: "查询您的网上订单以及历史购买记录",
    manageTips: "管理您的个人信息",
    adviceTips: "获取最新的UNI电子咨询",
    designsTips: "储存您的购物袋及个人橱窗设计"
  },
  userModal: {
    hello: "您好",
    clickPurchaseTips: "点击前往查看全部加购记录",
    logOut: "退出登录",
    personalItem: "个人信息",
    myShopWindow: "我的橱窗",
    myOrder: "我的订单",
    myReport: "我的报告",
    manage: "管理收货地址"
  },
  home: {
    notSupportedVideo: "您的浏览器不支持 video 标签",
    pickupText1: "打造鞋服产业C2M",
    pickupText2: "全链数字化定制平台",
    secondDesc1: "实现“一脚一楦”、“一人一版一工艺”柔性制造",
    secondDesc2: "实现大规模鞋服个性化定制服务",
    clickLearnMore: "点击了解更多",

    pickupFootwearText1: "鞋服数字技术服务",
    pickupFootwearText2: "UNI让数字鞋服",
    pickupFootwearText3: "所见即所得",
    pickupFootwearText4: "提供创新且成熟的数字测量、数字3D、数字制造、柔性生产全链路数字化定制服务。",
    costumeTitle: "服饰精准3D设计定制",
    costumeDesc: "实现“一人一版一工艺” 服饰精准定制制造,AI赋能数字服装,智趣创游元宇宙新世界",
    learnMore: "了解更多",
    footwearTitle: "鞋履精准3D设计定制",
    footwearDesc: "实现“一脚一款一楦” 柔性鞋履制造,可实现大规模鞋履个性化精准定制服务",
    coreTitle: "核心技术",
    technologyItem1: "三维精准扫描",
    technologyItem2: "生物力学",
    technologyItem3: "个性化定制",
    technologyItem4: "3D在线实时渲染",
    technologyItem5: "人脸重建技术",
    technologyItem6: "MR穿戴",
    technologyItem7: "脚楦自动转换",
    technologyItem8: "柔性生产",

    digitalText1: "3D数字设计平台赋予创新动力",
    digitalText2: "测量数据模型数字共享随时随地协同创作设计",
    digitalTopDesc: "让设计师焕新设计无限可能，3D在线实时渲染，海量版型、材料素材即搜即用，真实还原设计效果",
    consultation: "合作咨询",
    worksWall: "作品墙",
    stylist: "设计师",

    enabling1: "为各自品牌提供数字技术赋能服务",
    enabling2: "抢占高净值客户群占比、打造私人专属一款一版高定服务。",

    future1: "与UNI共创数字化鞋服定制未来"
  },
  solution: {
    experience: "申请体验",
    digitalShoes: "数字化鞋履",
    customizedService: "定制服务",
    offlineExperience: "一次线下体验  无限线上下单",
    smartFootMeasurement: "数字测量之智能足部测量",
    scanning: "高清三维扫描技术服务",
    footMultiMatrix: "足部多矩阵重建技术服务",
    footThreeD: "脚型3D智能数字化技术",
    footData: "足部数据AI分析与应用",
    plantarPressure: "数字测量之足底压力分析",
    simulation: "仿真生物力学技术",
    highPrecision: "高精度压力传感技术",
    intelligent: "动静足部运动数据智能分析",
    footHealth: "足部健康矫正与定制鞋垫生成技术",
    supportVideoTips: "您的浏览器不支持 video 标签",

    digitalDesignDesc: "自主研发3D个性化定制平台，拥有领先的3D可视化、快速建模、实时追光渲染、2D3D互动转换等核心技术，可实现设计作品的多维度个性化、数字化创作功能",
    digitalDesign: "数字设计",
    multiScreen: "多屏幕协同创作技术",
    humanoid: "数字人元宇宙体验技术",
    ar: "实时AR试穿技术",
    digitalIntelligence: "数字智造",
    automatic: "脚楦自动转换技术",
    automaticOut: "自动出鞋面纸板技术",
    globalDigital: "全球数字智造调配系统",
    scheduling: "全球订单智能排产系统",
    selfDeveloped:
      "自主研发的数字制造系统，将用户数字测量数据与线上数字化定制平台创作数据，通过先进的人工智能AI技术转换成可标准化制作鞋履的定制鞋履、鞋履纸样、个性化配置表等核心制作标准件，实现“一款一脚一楦”、“一人一版一工艺”柔性制作，实现大规模服装个性化定制服务",
    flexibleProduction: "柔性生产"
  },
  digitalClothing: {
    digitalClothingTitle: "数字化服装",
    customizedService: "定制服务",
    fullScene: "全场景数字展销、重塑服饰定制新生态",
    applicationExperience: "申请体验",

    digitalVolume: "数字量体",
    volumeAI: "量体AI分析",
    virtualFitting: "AI虚拟试衣",
    intelligentAiCode: "智能AI放码",
    digitalAutomatic: "数字自动排料",
    flexible: "柔性生产"
  },
  contactUs: {
    contactUsText: "与我们联系",
    submitting: "提交申请后，会有专业客服人员与您联系，感谢您对UNI服务的关注",
    countryText: "国家",
    checkCountryTips: "请选择国家",
    district: "地区",
    checkDistrictTips: "请选择地区",
    type: "类型",
    checkTypeTips: "请选择类型",
    brandName: "品牌名称",
    brandNameCheckTips: "请输入品牌名称",
    enterprise: "企业当前规模",
    checkEnterpriseTips: "请输入企业当前规模",
    appellation: "称呼",
    checkAppellationTips: "请输入您的称呼",
    phone: "联系电话",
    checkPhoneTips: "请输入您的联系电话",
    brandIntroduction: "品牌简介",
    checkBrandIntroductionTips: "品牌简介",
    businessProfile: "经营情况简介",
    checkBusinessProfileTips: "请输入经营情况简介",
    application: "提交申请",
    dealer: "经销商",
    channel: "渠道商",
    applicationApproved: "申请成功",
    formFieldsTips: "请填写完成必填项后再操作"
  },
  applicationModal: {
    contactUsText: "与我们联系",
    submitting: "提交申请后，会有专业客服人员与您联系，感谢您对UNI服务的关注",
    countryText: "国家",
    checkCountryTips: "请选择国家",
    district: "地区",
    checkDistrictTips: "请选择地区",
    enterprise: "企业名称",
    checkEnterpriseTips: "请输入企业名称",
    enterpriseSize: "企业当前规模",
    checkEnterpriseSizeTips: "请输入企业当前规模",
    appellation: "称呼",
    checkAppellationTips: "请输入您的称呼",
    phone: "联系电话",
    checkPhoneTips: "请输入您的联系电话",
    closeText: "关闭",
    application: "提交申请",
    applicationApproved: "申请成功",
    formFieldsTips: "请填写完成必填项后再操作"
  },
  brandServer: {
    cooperationServices: "品牌数字化赋能合作服务",
    brandServerText: "品牌数字化赋能服务",
    brandedThreeServerText: "品牌数字3D服务",
    cardboard: "脚楦、鞋面纸板全球数字传输",
    netWorth: "高净值客户群合作引流"
  },
  efficiencyServices: {
    efficiencyServicesText: "渠道数字化增效服务",
    approach: "渠道营销数字途径",
    rentalService: "3D测量设备租赁服务",
    salesOrder: "销售下单 盈利就是如此简单",
    generation: "全球一件代发 0库存也能抢占先机"
  },
  uniRecommend: {
    uniGolf: "优你高尔夫",
    newShoes: "鞋履新品",
    newScreenings: "全新展映",
    loading: "画像読み込み中です...",

    productGuide: "商品导览",
    searchTips: "点击输入您想寻找的商品关键词",
    wantCustomize: "我要定制",
    customization: "精准定制",
    notDataTips: "当前关键词没有搜索到内容，换一个试试吧",
    checkSearchTips: "请输入商品关键词",
    notStock: "该商品暂无库存",

    threeDisplay: "3D展示",
    rise: "起",
    productID: "商品编号",
    updateShopping: "更新购物袋商品",
    addShopping: "加入购物袋",
    buyNow: "立即购买",
    soldOut: "已售罄",
    moreLeather: "更多皮料、颜色及配件个性化定制，来创造发现吧",
    personalizedCustomization: "个性化定制",
    specifications: "请选择规格参数",
    contactCustomer: "联系客服",
    reservation: "免费预约测量",
    lastsTips: "一款一脚一楦",
    privateCustom: "私人定制设计",
    defaultText: "默认",
    directly: "点击链接直接打开",
    copyTips: "复制成功",
    loginOperation: "请登录后在操作",
    updatedCartInfos: "购物车信息更新成功",
    selectProductTips: "请先选择商品规格再进行加入购物袋",
    purchase: "加购成功",
    purchasingTips: "请先选择商品规格再进行购买",

    goodsDetails: "商品详情",
    packagingInfo: "包装信息",
    shippingInfo: "配送信息",
    cancel: "取消",
    confirm: "确认选择",
    coBranded: "联名鞋履全新上市",
    searchTipsBrand: "点击输入您想寻找的联名商品",
    styleNum: "款号",
    personal: "我要私人高定",
    coBrandedNotDataTips: "当前联名品牌没有关联内容，换一个试试吧",
    warmReminder: "温馨提示",
    customizedTips: "请联系客服人员获取私人高定权限"
  },
  customerModal: {
    closeText: "关闭",
    warmReminder: "温馨提示",
    customizedTips: "请联系客服人员获取私人高定权限",
    designWorksTips: "请您将个人介绍及个人设计作品发至",
    designWorksTips2: "后续将有 客服人员与您联系。感谢您对优你科技的关注"
  },
  designer: {
    mainTitle: "签约设计师合作款",
    searchTips: "点击输入您想寻找的商品关键词",
    designerGauding: "我要设计师高定"
  },
  GlobalDesigner: {
    globalDesignerText: "全球设计师",
    greationPlatform: "创作平台",
    possibilities: "让您的设计换新无限可能",
    continuous: "一次设计持续收益",
    admission: "申请入驻",
    accessPlatform: "进入平台",
    pleaseLogin: "请登录",
    accessPlatformTips: "您还不是入驻设计师，请先提交申请入驻吧，后续将有客服人员与您联系，感谢您对优你科技的关注",
    viewingWall: "看作品墙",
    viewingDesigner: "看设计师",
    searchDesignerTitle: "请输入设计师作品标题",
    searchDesignerName: "请输入设计师名称",
    searchNotData: "当前关键词没有搜索到内容，换一个试试吧",
    concern: "关注",
    followed: "已关注",
    focusOnSuccess: "已关注成功",
    unfollowed: "已取消关注",
    collected: "已收藏成功",
    cancelCollection: "已取消收藏"
  },
  empowerment: {
    digging: "陪你一起挖掘",
    bigHealth: "大健康数字",
    oceanMarket: "亿万蓝海市场",
    metaverse: "数字赋能、布局元宇宙",
    futureShoes: "抢占未来鞋服定制新赛道",
    buyer: "我是买手",
    channeler: "我是经销商/渠道商",
    partner: "我是品牌方"
  },
  empowermentModal: {
    cooperationRequirement: "合作要求",
    conditionOfCooperation: "合作条件",
    cooperationProcess: "合作流程",
    closeText: "关闭",
    contactToApply: "联系申请",
    contactToApplyTips: "提交申请后，会有专业客服人员与您联系，感谢您对UNI服务的关注",
    typeText: "类型",
    brandName: "品牌名称",
    checkBrandNameTips: "请输入品牌名称",
    currentFlowSize: "当前流量规模",
    checkCurrentFlowSizeTips: "请输入企业当前规模",
    appellation: "称呼",
    checkAppellationTips: "请输入称呼",
    phone: "联系电话",
    checkPhoneTips: "请输入联系电话",
    country: "国家",
    checkCountryTips: "请选择国家",
    district: "地区",
    checkDistrictTips: "请选择地区",
    brandEx: "品牌管理经验",
    businessProfile: "经营情况简介",
    brandIntroduction: "品牌简介",
    cancelText: "取消",
    applyNow: "立即申请",
    checkCurrentTraffic: "请输入您当前的流量规模",
    checkBusinessTips: "请输入您的经营情况/品牌简介",
    professional: "专业买手",
    commercial: "商业买手",
    freeBuyer: "自由买手",
    distributorText: "经销商",
    channelText: "渠道商",

    buyerStepDesc1: "精通鞋服搭配、了解鞋服设计理念",
    merchantStepDesc1: "与总部共同合资注册成子公司",
    merchantTitle1: "合资注册子公司",
    brandStepDesc1: "鞋服类品牌、拥有一定知名度及美誉度",

    buyerStepDesc2: "拥有一定规模的客户群体",
    merchantStepDesc2: "具备当前地区一定资源调配优势",
    merchantTitle2: "地区资源优势",
    brandStepDesc2: "渴望品牌曝光度提升、吸引高净值客户群",

    buyerStepDesc3: "经验丰富、懂一至两门外语",
    merchantStepDesc3: "认同企业文化理念、对产品及市场充满信心",
    merchantTitle3: "知行合一",
    brandStepDesc3: "认同联合模式、实现联合吸粉、变现、资源置换",

    buyerLabel1: "提出申请",
    merchantLabel1: "提出申请",
    brandLabel1: "提出申请",

    buyerLabel2: "初步洽谈",
    merchantLabel2: "初步洽谈",
    brandLabel2: "初步洽谈",

    buyerLabel3: "签订合同",
    merchantLabel3: "签订合同",
    brandLabel3: "签订合同",

    buyerLabel4: "企划对接",
    merchantLabel4: "企划对接",
    brandLabel4: "牌款/联名款企划对接",

    buyerLabel5: "商品上架",
    merchantLabel5: "商品代销",
    brandLabel5: "商品上架",

    buyerLabel6: "盈利分红",
    merchantLabel6: "盈利分红",
    brandLabel6: "盈利分润",

    applicationApproved: "申请成功",
    formFieldsTips: "请填写完成必填项后再操作"
  },
  AICollocation: {
    collocationText: "AI数字搭配",
    designer: "您身边的专属设计师",
    digitalFootwear: "逼真的3D数字鞋服",
    dressDesign: "让您提前感受穿搭设计的魅力",

    analysis: "个人形象分析",
    digitalPerson: "数字人创建",
    intelligentAI: "智能AI穿搭推荐",
    outfitMatching: "服装搭配一键下单"
  },
  reservation: {
    measurementBannerText1: "有数字依据的",
    measurementBannerText2: "个性化定制",
    measurementBannerText3: "将是一种新的奢侈",
    measurementBannerText4: "UNI为您提供数字测量未来定制新体验",

    uniOfflineService: "UNI线下服务指引",
    viewService: "查看全部线下服务点",
    nearest: "离你最近",
    surveyService: "预约测量服务",
    fromYou: "距您",
    digitalMeasurement: "数字测量介绍",
    scannerForBoth: "双脚足部3D扫描仪",
    scannerForBothDesc: "采用独特的滤光技术，扫描过程无需遮挡，3-5秒内重建出双脚高精度3D数据及40多项尺寸数据，足弓、足跟、足底受力自动测评，测评数据实时上传，终端体验与工厂生产互联互通",
    singleFoot: "单脚足部3D扫描仪",
    singleFootDesc: "采用全新的多视觉360°结构设计，真正实现了足部的全方位扫描，3-5秒内重建出足部高精度3D数据，并自动输出测评结果",
    gaitAnalyzer: "步态分析仪",
    gaitAnalyzerDesc:
      "对足部着地过程进行精确、细致的记录，并反馈肉眼看不到的足底生物力学信息，包括不同的部位（前掌、后跟、中部）、不同状态（站、走、跑）、不同时间（着地、支撑、蹬离）的受力大小、受力面积变化、力线偏移、移动速度等",
    bodyScanner: "人体3D扫描仪",
    bodyScannerDesc: "通过基于非接触式光学三角测量技术，采用多个安全光源多个镜头和多个传感器同步工作，可快速、精确、无死角，无接触地完成几十项人体关键尺寸的自动测量，自动根据测量方案输出人体测量数据",

    smartFoot: "智能足部测量服务",
    plantarPressure: "足底压力测量服务",
    bodyMeasurementServices: "3D体态测量服务",
    surveyServiceIntroduction: "测量服务介绍",
    reservationSurvey: "预约测量",
    storeService: "门店服务/上门服务",
    processGuide: "观看测量流程指引",
    startMeasuring: "开始测量",
    reportView: "线上测量报告查看",
    selfService: "自助精准定制服务",

    queryServicePoint: "查询服务点",
    searchTips: "输入关键字进行快速搜索",
    storeNotData: "此地区暂未开设门店，请去别的地区看看吧",
    serviceType: "服务类型",

    name: "姓名",
    checkNameTips: "请输入姓名",
    appellation: "称呼",
    checkAppellationTips: "请选择称呼",
    phone: "联系电话",
    checkPhoneTips: "请输入联系电话",
    bookingDate: "预约服务日期",
    bookingTime: "预约服务时间",

    checkBookingTime: "请选择服务时间",
    contactAddress: "联系地址",
    checkContactAddress: "请输入联系地址",
    submitAnApplication: "提交申请",
    onSiteService: "上门服务",
    storeServiceText: "门店服务"
  },
  searchPage: {
    searchText: "点击输入您想寻找的商品关键词",
    searchResults: "的搜索结果有",
    searchRes: "搜索结果",
    customizeText: "我要定制",
    preciseCustomization: "精准定制",
    notData: "当前关键词没有搜索到内容，换一个试试吧",
    guess: "猜你喜欢",
    searchTips: "请输入搜索商品关键词"
  },
  shopBag: {
    notQuantityd: "有商品未选数量，请检查",
    shoppingBag: "购物袋",
    edit: "编辑",
    delete: "删除",
    saveChanges: "保存修改",
    cancel: "取消",
    specification: "商品规格数据有修改",
    pleaseReSelect: "请重新选择",
    quantity: "数量",
    notStock: "库存不足",
    notCartData: "您的购物车暂无内容",
    orderSubtotal: "订单小计",
    beenSelectedGoods: "已选{num}件商品",
    subtotalGoods: "商品小计",
    freight: "运费",
    total: "合计",
    explain: "说明",
    explainContent: "在线支付订单提交之后30 分钟内未付款，订单将被系统自动取消，请你尽快完成支付以确保商品能及时送达。现货商品和定制生产商品是分开寄出",
    continueShopping: "继续购物",
    settlement: "立即结算",
    delTips: "删除后将无法恢复，请确认是否删除",
    availableStock: "已超出可售库存数量",
    modifySuccessfully: "修改成功",
    deleteSuccessfully: "删除成功",
    settlementCommodity: "请选择结算商品",
    notQuantity: "有商品数量为0，请检查"
  },
  myAccount: {
    myAccountText: "我的账户",
    yourInfo: "您的信息",
    personal: "个人头像",
    clickReset: "点击此处重新设置",
    appellation: "称呼",
    checkAppellationTips: "请选择您的称呼",
    surname: "姓",
    checkSurnameTips: "请输入您的姓",
    name: "名",
    checkNameTips: "请输入您的名字",
    nickname: "昵称",
    checkNicknameTips: "您可以设置一个有趣的昵称",
    birthday: "生日",
    checkBirthdayTips: "请输入您的生日",
    height: "身高",
    checkHeightTips: "请输入您的身高",
    weight: "体重",
    checkWeightTips: "请输入您的体重",
    leftFoot: "左脚常穿码数",
    checkLeftFootTips: "请输入您的左脚常穿码数",
    rightFoot: "右脚常穿码数",
    checkRightFootTips: "请输入您的右脚常穿码数",
    personalInfoTips: "设置您的个人信息，以便我们能更准确为您提供更合适的商品引导",
    country: "国家/地区",
    checkCountryTips: "请选择您的国家",
    checkCityTips: "请选择您的城市",
    checkAreaTips: "请选择您的区域",
    phone: "联系电话",
    checkPhoneTips: "请输入您的联系电话",
    detailsAccount: "账户详情",
    mobilePhone: "手机电话",
    uniWebsiteLogin: "这是您的UNI官网登录手机号码",
    email: "邮箱地址",
    emailLoginTips: "输入您的邮箱，即可用邮箱进行登录",
    privacyRights: "隐私权限",
    uniInvitationsTips: "我希望收到关于UNI的新活动邀请、独家产品、精准个性化订制服务以及根据我的兴趣所个性化的客户体验的更新",
    morePrivacyPractices:
      "隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改、更正您的个人信息，或限制我们处理或传输您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息",
    cancelingChanges: "取消修改",
    savingChanges: "保存修改",
    gentleman: "先生",
    lady: "女士",
    unknown: "未知",

    checkNickNameTips: "请设置您的昵称",
    selectBirthdayTips: "请选择您的出生日期",
    checkAreaCodeTips: "请选择您的区号",
    checkCorrectPhoneTips: "请输入正确的联系电话",
    agreeTips: "请点击同意隐私协议后，再操作",
    userUpdated: "用户信息更新完成",
    submitFieldsTips: "请填写完成必填项后再操作"
  },
  myPurse: {
    myPurseText: "我的钱包",
    cumulativeRecharge: "累计充值",
    cumulativeConsumption: "累计消费",
    totalAssets: "总资产",
    recharge: "充值",
    notData: "暂无相关记录信息",
    all: "全部",
    billingRecord: "账单记录",
    consumptionRecord: "消费记录",
    rechargeRecord: "充值记录",
    rechargeAmount: "请选择充值金额",
    gift: "赠",
    otherAmount: "其他金额",
    ofThisRecharge: "请输入本次充值金额",
    paymentMethod: "支付方式",
    cancel: "取消",
    close: "关闭",
    topUpImmediately: "立即充值",
    topUpInstructions: "充值说明",
    mallForNegotiation: "充值后账户的金额不能提现，可用于商城及线下门店消费使用；账户充值出现问题可联系商城客服协商处理",
    selectThePayment: "请选择支付方式后再操作",
    rechargeSuccessfully: "充值成功"
  },
  myShopWindow: {
    backToPersonal: "返回个人中心",
    myShopWindowText: "我的橱窗",
    loading: "加载中",
    delete: "删除",
    collaborativeCreation: "协同创作",
    preciseCustomization: "精准定制、个性化定制",
    redesign: "重新设计",
    buyNow: "立即购买",
    notData: "您的橱窗暂无内容",
    delTips: "删除后将无法恢复，请确认是否删除",
    creativeInvitation: "协同创作邀请",
    close: "关闭",
    checkAccountTips: "请输入设计师手机号/邮箱",
    sendInvitation: "发送邀请",
    invitationIsSuccessful: "邀请成功，对方已加入协作",
    personalDesign: "个人设计款",
    designerCollaborative: "设计师协同创作款",
    checkAreaCode: "请选择区号",
    delItem: "该橱窗商品已删除",
    onlySelfCreated: "只有自己创作的记录才能邀请设计师协助"
  },
  myOrder: {
    myOrderText: "我的订单",
    orderType: "订单类型",
    orderNumber: "订单号",

    notHasStock: "有商品暂无库存",
    pendingPayment: "订单待支付",
    orderCancelled: "订单已取消",
    orderDeleted: "订单已删除",
    notApproved: "订单审核不通过",
    pendingReview: "订单待审核",
    orderFilled: "订单待补充",
    inProduction: "订单生产中",
    leaving: "订单出库中",
    pendingDelivery: "订单待送货",
    maintenance: "维修售后中",
    beReceived: "订单待收货",
    beShipped: "订单待配送",
    completed: "订单已完成",
    beEvaluated: "订单待评价",
    readyForShipment: "订单待发货",
    afterSale: "订单售后中",
    refunded: "已退款",
    refundOfReturned: "已退货退款",
    quantity: "数量",
    returnAndExchange: "退换货",
    maintain: "维修",
    cancelAnOrder: "取消订单",
    payImmediately: "立即支付",
    buyAgain: "再次购买",
    confirmReceipt: "确认收货",
    orderDetails: "订单详情",
    viewLogistics: "查看物流",
    delete: "删除",
    customerService: "咨询客服",
    returnOfGoods: "寄回商品",
    notData: "您的订单暂无内容",
    allOrders: "全部订单",
    bePaid: "待支付",
    inProductionText: "生产中",
    beReceivedText: "待收货",
    beEvaluatedText: "待评价",
    completedText: "已完成",
    shipment: "待发货",
    standards: "标准",
    customization: "定制",
    informationIsWrong: "售后订单信息有误",
    warmReminder: "温馨提示",
    interests: "为保障权益，请收到货确认无误后，再确认收货",
    cancellingTips: "取消订单后，将无法恢复，请确认是否取消",
    paymentSuccess: "支付成功",
    back: "返回",
    confirm: "确认",
    cancel: "取消",
    checkPaymentText: "请选择您的支付方式",
    orderAmount: "订单金额",
    payType: "支付方式",
    goToPay: "去支付",
    balanceWillBePaid: "请确认是否余额支付",
    currentBalance: "当前余额",
    balanceAfterPayment: "支付后剩余",
    confirmPayment: "确认支付",
    balanceIsInsufficient: "余额不足，请选择其他支付方式",
    insufficientBalance: "余额不足",
    anotherPaymentMethod: "请选择其他支付方式",
    pleaseSelect: "请先选择",
    topUpNow: "去充值",
    balance: "余额",
    paymentBeingProcessed: "正在处理付款",
    selectPaypalTips: "请选择您的paypal支付方式",
    cancelPayment: "取消支付",
    viewOrder: "查看订单",
    viewDetails: "查看详情",
    ViewOrder: "查看订单",
    keepOrder: "继续下单",
    close: "关闭",
    orderSuccessful: "下单成功",
    orderSuccessfulTips: "我们将尽快为您安排订单生产，请耐心等候",
    goodsToBeReturned: "商品待退回",
    toBeRefunded: "待退款",
    applyingForReplacement: "申请换货中"
  },
  afterSale: {
    styleNumber: "款号",
    style: "款式",
    size: "尺码",
    goToOrderDetails: "去订单详情",
    applyMaintenance: "申请维修",
    applyReturnExchange: "申请退换",
    orderNumber: "订单编号",
    serviceType: "服务类型",
    returnSize: "退换尺码",
    statusOfGoods: "货物状态",
    refundAmount: "退款金额",
    atMost: "不可修改，最多",
    otherInfoPrice: "含发货邮费￥0.00，其中包含优惠券、积分抵扣等",
    maintenanceDesc: "商品维修说明",
    exchangeReason: "换货原因",
    refundReason: "退款原因",
    checkRepairTips: "请输入商品维修说明",
    checkExchangeTips: "请输入换货原因",
    refundReasonTips: "请输入退款原因",
    uploadCredentials: "上传凭证",
    credentialsMaxTips: "最多上传3张凭证",
    cancel: "取消",
    submit: "提交",

    refundOnly: "仅退款",
    notReceived: "未收到货",
    selectSize: "请选择尺码",
    returnGoodsForRefund: "退货退款",
    exchangeGoods: "换货",
    receivedGoods: "已收到货",
    appliedForRepair: "已申请维修",
    appliedForReplacement: "已申请换货",
    refundApplied: "已申请退款",
    appliedForReturn: "已申请退货退款",

    returnLogisticsInfo: "退回物流信息填写",
    logisticsCompany: "物流公司",
    checkLogisticsCompanyTips: "请输入物流公司",
    logisticsTrackingNumber: "物流单号",
    logisticsTrackingNumberTips: "请输入物流单号",
    confirmReturn: "确认寄回",
    infoSubmitted: "信息已提交",
    formField: "请输入必填项后操作",
    beenConfirmed: "已确认收货"
  },
  orderDetails: {
    returnToPrevious: "返回上一页",
    sweetheart: "亲爱的",
    checkTheFollowing: "请您核对以下订单信息",
    aboutYourOrderInfo: "这是您订单的全部信息",

    pendingPayment: "订单待支付",
    orderCancelled: "订单已取消",
    orderDeleted: "订单已删除",
    notApproved: "订单审核不通过",
    pendingReview: "订单待审核",
    orderFilled: "订单待补充",
    inProduction: "订单生产中",
    leaving: "订单出库中",
    pendingDelivery: "订单待送货",
    maintenance: "维修售后中",
    beReceived: "订单待收货",
    beShipped: "订单待配送",
    completed: "订单已完成",
    beEvaluated: "订单待评价",
    readyForShipment: "订单待发货",
    afterSale: "订单售后中",

    commodityInformation: "商品信息",
    orderNumber: "订单号",
    customizedForTA: "为TA定制",
    personalCustomization: "个人定制",
    viewCustomizationDetails: "查看定制详情",
    quantity: "数量",
    measurementReport: "测量报告",
    notReport: "您暂无测量报告记录，点击前往预约测量",
    reselect: "重新选择",
    keepChoosing: "继续选择",
    selectMeasurementReport: "选择测量报告",
    subtotal: "商品小计",
    otherInfo: "其他信息",
    deliveryAddress: "配送地址",
    notAddressData: "您暂无地址记录，请先添加配送地址",
    addAnAddress: "添加地址",
    selectAddress: "选择地址",
    notBeShippedTips: "演示订单无需发货",
    logisticsFreight: "物流运费",
    logisticsFreightTips: "各个国家的物流运费服务有所不同，依据配送地址会有略微不同，具体情况可点击咨询客服了解",
    checkRemarkTips: "您可在此处输入订单要求备注信息",
    submittingTips: "提交订单即代表您接受我们的",
    privacyPolicy: "隐私政策",
    and: "和",
    termsOfSale: "销售条款",
    totalOrder: "订单合计",
    actualPayment: "实际支付",
    immediatePayment: "立即支付",
    customerService: "咨询客服",
    viewLogistics: "查看物流",
    balance: "余额",
    customInsoles: "定制鞋垫",
    checkShippingTips: "请选择您的配送地址",
    paymentMethod: "请选择支付方式",
    notCheckPrivacyPolicyTips: "请勾选阅读隐私政策和销售条款后再进行支付",
    notQuantity: "有商品数量为0，请检查",
    checkMeasurementReportTips: "请选择足部测量报告后再支付",
    checkMeasurementBodyReportTips: "请选择体态测量报告后再支付",
    notCheckReportData: "您暂未选择步态测量报告数据,请问是否继续支付下单呢",
    tips: "小提示：支付订单后，可致电400-686-6899联系客服预约测量服务",
    returnOrder: "返回订单",
    continueToPay: "继续支付",
    arrangeDelivery: "我们将尽快安排发货，请耐心等候",
    availableStock: "已超出可售库存数量",
    uniPrivacyPolicy: "UNI隐私政策",
    close: "关闭",
    personalizationOptions: "个性化定制选项",
    subtotal2: "小计",
    collapseCustomizationDetails: "点击收起定制详情",
    logisticsSituation: "物流情况",
    putAwayLogistics: "点击收起物流情况",
    shipping: "请选择配送地址",
    addAddress: "新增地址",
    default: "默认",
    confirmSelection: "确认选择",
    myReport: "我的报告",
    gauger: "量体师",
    notReportData: "您的报告暂无内容",
    footMeasurement: "足部测量",
    gaitMeasurement: "步态测量",
    confirmBalanceTips: "请确认是否余额支付",
    balanceIsInsufficientTips: "余额不足，请选择其他支付方式",
    currentBalance: "当前余额",
    insufficientBalance: "余额不足",
    orderAmount: "订单金额",
    balanceAfterPayment: "支付后剩余",
    chooseAnother: "请选择其他支付方式",
    selectFirst: "请先选择"
  },
  myReport: {
    myReportTitle: "我的报告",
    gauger: "量体师",
    viewDetails: "查看详情",
    delete: "删除",
    notData: "您的报告暂无内容",
    delTips: "删除后将无法恢复，请确认是否删除",
    footMeasurement: "足部测量",
    gaitMeasurement: "步态测量",
    bodyMeasurement: "体态测量",
    deletedReport: "该测量报告已删除",

    back: "返回",
    footMeasurementReport: "足部测量报告",
    phone: "电话",
    reportNumber: "报告编号",
    date: "日期",
    measurementResult: "测量结果",
    leftFoot: "左脚",
    rightFoot: "右脚",
    reference: "参考值",
    severeInternal: "重度内/外翻",
    moderateInternal: "中度内/外翻",
    mildInternal: "轻度内/外翻",
    normalHeel: "正常足跟",
    normal: "正常",
    mild: "轻度",
    moderate: "中度",
    severe: "重度",
    footData: "足部数据",
    lengthDimension: "长度尺寸",
    name: "名称",
    widthSize: "宽度尺寸",
    girthDimension: "围度尺寸",
    heightDimension: "高度尺寸",
    heelSize: "脚后跟尺寸",
    measurementSuggestion: "测量建议",
    suggestionTips: "左脚：44码，右脚：43.5码，建议运动和锻炼时配备合适的鞋子和鞋垫，保护好您的足踝和关节",
    threeDFootModel: "3D脚型模型",
    archReport: "足弓报告",
    heelReport: "足跟报告",
    bunionValgusReport: "拇外翻报告",
    footFormReport: "足型报告",

    ArchType: "足弓类型",
    heelType: "足跟类型",
    halluxValgus: "拇外翻",
    footShape: "足型",
    fiveNormalFoot: "5型正常足",
    archEvaluation: "足弓评测",
    rolloverEvaluation: "足跟内外翻评测",
    thumbValgus: "拇指内外翻评测",
    footShapeEvaluation: "足型评测",
    mildFlatFeet: "7型轻度扁平足",
    archedFoot: "弓形足",
    normalFoot: "常态足",
    mildFlatFoot: "轻度扁平足",
    moderateFlatFoot: "中度扁平足",
    severeFlatFoot: "重度扁平足",
    squareFoot: "方形脚",
    greekFoot: "希腊脚",
    romanFoot: "罗马脚",
    egyptFoot: "埃及脚",

    lengthOfFoot: "脚长",
    halluxConvex: "拇趾外凸点长度",
    littleToe: "小趾外凸点长度",
    firstMetatarsophalangeal: "第一跖趾长度",
    fifthMetatarsophalangeal: "第五跖趾长度",
    lumbarFossa: "腰窝部位长度",
    heelCenter: "踵心部位长度",
    tarsalSite: "跗骨部位长度",
    circumference: "跖围宽",
    halluxIsWide: "拇趾里宽",
    wideOutside: "小趾外宽",
    firstMetatarsophalangealWidth: "第一跖趾里宽",
    fifthMetatarsophalangealWidth: "第五跖趾里宽",
    toeWidth: "脚趾宽",
    toeDiagonalFrame: "脚趾斜框",
    plantarCircumference: "跖围斜宽",
    metatarsophalangealCircumference: "跖趾围",
    circumferenceOfTarsus: "跗围",
    pocketRoot: "兜根围",
    heightOfPlantar: "跖围高度",
    heightOfThumb: "拇趾高度",
    heightOfTarsus: "跗围高度",
    heelBump: "后跟凸点高度",
    lateralMalleolus: "外踝骨高度",
    heightOfArch: "足弓高度"
  },
  myAddressList: {
    myAddress: "我的地址",
    setToDefault: "设为默认",
    delete: "删除",
    clickAddAddress: "点击添加地址",
    back: "返回",
    editTheAddress: "编辑地址",
    addNewAddress: "添加新地址",
    delTips: "删除后将无法恢复，请确认是否删除",
    setDefaultAddress: "默认地址已设置成功",
    cancelDefaultAddress: "默认地址已取消",
    beenDeleted: "该地址已删除",
    warmReminder: "温馨提示",
    close: "关闭",
    cancel: "取消",
    confirm: "确定"
  },
  addAddressForm: {
    country: "国家/地区",
    checkCountryTips: "请选择国家/地区",
    postcode: "邮编(非必填)",
    checkPostcodeTips: "请输入邮政编号",
    appellation: "称呼",
    checkAppellation: "请选择您的称呼",
    surname: "姓",
    checkSurname: "请输入您的姓氏",
    name: "名",
    checkNameTips: "请输入您的名字",
    province: "省",
    checkProvince: "请选择您的省份",
    city: "市",
    checkCity: "请选择您的城市",
    area: "区",
    checkArea: "请选择您的区域",
    detailedAddress: "详细地址",
    checkDetailedAddress: "请输入您的详细地址",
    phone: "联系电话",
    checkPhone: "请输入您联系电话",

    cancel: "取消",
    update: "更新",
    save: "保存",
    gentleman: "先生",
    lady: "女士",

    updatedAddressTips: "地址信息已更新",
    addedAddressTips: "地址信息添加完成",
    fieldsForm: "请填写完成必填项后再操作"
  },
  personal: {
    logOut: "退出登录",
    balance: "余额",
    membershipCard: "会员卡",
    integral: "积分",
    personalInfo: "个人信息",
    myShopWindow: "我的橱窗",
    myOrder: "我的订单",
    myReport: "我的报告",
    shipping: "管理收货地址"
  },
  footerBar: {
    newsletter: "订阅UNI电子资讯",
    bySubscribing: "通过订阅，即代表您接受UNI的隐私政策条款",
    checkEmail: "请输入您的邮箱地址",
    customerService: "顾客服务",
    aboutUs: "关于我们",
    digitalShoe: "数字化鞋履定制服务",
    digitalClothing: "数字化服装定制服务",
    brandDigital: "品牌数字化赋能服务",
    channelDigital: "渠道数字化增效服务",
    brandHigh: "品牌高定",
    designerStyle: "设计师款",
    storeInquiry: "门店查询",
    wallOfwWorks: "作品墙",
    designer: "设计师",
    becomeDealer: "成为经销商",
    brandCooperation: "品牌合作",
    contactUs: "联系我们",
    shipping: "运输和退货",
    payment: "价格和付款",
    ourHistory: "我们的历史",
    sustainable: "可持续发展",
    sendSubscribeTips: "请输入邮箱地址后再操作"
  },
  fixedNextPage: {
    previousPage: "上一页",
    nextPage: "下一页",
    digitalClothing: "数字化服装定制服务",
    digitalShoe: "数字化鞋履定制服务",
    brandDigital: "品牌数字化赋能服务",
    channelDigital: "渠道数字化增效服务",
    brandHigh: "品牌高定",
    uniRecommendation: "UNI推荐",
    designerStyle: "设计师款"
  },
  ourHistory: {
    ourHistoryText: "我们的历史",
    unique: "UNI象征英文的Unique",
    // eslint-disable-next-line
    attractive: '"独特比完美更有魅力" 是UNI企业追求的核心价值理念',
    uniDesc:
      "我们渴望在工业，网络，科技，万物互联极速膨胀的节奏中找到更温柔的交流方式，帮助人们停止寻找，选择创造。 我们将复杂的科技浓缩为精巧的测量设备，将枯燥的数据转化为独创的定制鞋楦，将科技与工艺，个性与品格相结合，让每个人做自己的设计师，让世界拥抱多元与开放！",

    year: "2017年",
    mainTitle: "非同寻常的开始",
    mainDesc: "创始人Katsuo Saito",
    desc: "Katsuo Saito先生组建核心研发团队，探索定制技术。",

    year1: "2021年",
    mainTitle1: "东莞运营中心投入使用",

    year2: "2021年6月",
    mainTitle2: "优你科技注册成立",

    year3: "2021年8月",
    certificate: "UNI科技第一款专利证书",
    firstPatent: "第一款专利落地",

    year4: "2022年3月",
    pressConference: "米兰发布会",
    mainDesc4: "优你科技分别在米兰、纽约发布会",

    year5: "2022年5月",
    mainTitle5: "智能工厂图",
    mainDesc5: "智能工厂建成",

    year6: "2022年8月",
    mainTitle6: "UNI东莞旗舰店",
    mainDesc6: "东莞旗舰店和沈阳旗舰店开业",
    desc6: "同年峰景快闪店、海口旗舰店同步开业"
  },
  sustainable: {
    responsibility: "社会责任",
    responsibilityTips1: "每天，我们都在努力打造健康、环保、舒适的产品",
    responsibilityTips2: "以行动表达关爱地球，以及生活在上面的人类",

    title: "社会责任",
    desc: "我们致力于创造出有故事的产品，让每个产品从他的鞋楦灵魂到外部色 彩，从细节雕刻到局部设计都可以展示穿着者的独特品味。我们不拘一 格的选用意大利、日本、中国等多地的工艺技术，以契合消费者内心渴 望的完美鞋履，用品质阐述品格。 我们不断用科技突破想象力边界，联动 z 时代掌握新媒体话语权，将元 宇宙及数字化形象的抽象概念具象化，让形单影只的人类通过网络实现 时空对话，让科技不再遥远而是变得真实跟有趣。",

    title1: "低碳环保",
    desc1:
      "我们生于呼唤地球保护的时代，也自然更关注生物多 样性、倡导节能减排与促进可持续发展，用环保可复用材料减少资源浪 费及损耗，迎接新世纪环境的挑战。 UNI始于科技，我们利用数字测量及智能制造，在传统订制以及工业化生产之间，得到一个『大规模个性化定制』的总体解决方案。通过消费端、平台端与生产端协同，化解工业时代所产生的非必要消耗，致力满足现代都市人群需要，为穿戴者提供全方位的未来订制体验。",

    title2: "健康生态",
    desc2:
      "UNI科技以著名足踝医学专家张建中教授为核心，联合国内外1000多名外科专家组成专业团队，提供从3D测量、生物力学分析到足部健康 管理等技术支持。以生物力学、体能训练、康复辅具、体能康复前沿技术为核心,与德国、法国、美国、 意大利、西班牙等国家建 立广泛合作关系， 提供步态分析、体能训练、足脊检测、康复训练系统，为科研、 国家队、体育、医疗机构应用提供综合性解决方案。"
  },
  contactUsPage: {
    contactUs: "联系我们",
    contactUsTips1: "每天，我们都在努力打造健康、环保、舒适的产品",
    careAbout: "以行动表达关爱地球，以及生活在上面的人类",
    serviceHotline: "服务热线",
    phoneCall: "手机拨打",
    landlineCall: "座机拨打",
    workTime: "星期一到星期五早上8：25点到下午6:00点",

    alsoInfo: "您也可直接在网站上留下信息告知我们",
    informationNeeded: "所需信息",
    helpTips: "请问我们能为您提供什么帮助",
    problem: "问题",
    describe: "描述",
    contactInfo: "联系信息",
    getPhoneTips: "请告知您的联系方式",
    country: "国家/地区",
    checkCountry: "请选择国家/地区",
    checkDistrict: "请选择地区",
    name: "姓名",
    checkName: "请输入姓名",
    appellation: "称呼",
    checkAppellation: "请选择称呼",
    InternationalAreaCode: "国际区号",
    checkInternationalAreaCode: "请选择国际区号",
    phone: "手机号码",
    checkPhone: "请输入手机号",
    email: "电子邮箱",
    checkEmails: "请输入邮箱号",
    send: "发送",
    gentleman: "先生",
    lady: "女士",
    applicationApproved: "申请成功",
    formFields: "请填写完成必填项后再操作"
  },
  design: {
    fullScreen: "全屏展示",
    modelScreenshot: "模型截图",
    designSignature: "设计签名",
    joinShowWindow: "加入橱窗",
    footFitting: "足部试穿",
    digitalHuman: "数字人试穿",
    MRFitting: "MR试穿",
    leftClickDrag: "左键拖动旋转3D模型， 滚轮可缩放查看细节",
    selected: "已选",
    buyNow: "立即购买",
    personalized: "个性化定制选项",
    subtotal: "小计",
    collapseCustomization: "点击收起定制详情",
    tips: "提示",
    checkNameTips: "请选择{name}后再生成",
    screenshotSuccess: "截图成功",
    pleaseLogIn: "请登录",
    development: "该功能研发中",
    UpdateSuccessfully: "更新成功",
    joinSuccessfully: "加入成功",
    warmReminder: "温馨提示",
    continueEditing: "继续编辑",
    abandonChange: "放弃更改",
    abandonTips: "您已选择不同款式的{curName}设计，未确认生成，是否放弃更改，使用原设计内容",
    successfulPurchase: "加购成功",
    previous: "上一项",
    nextItem: "下一项",
    total: "合计",

    signatureDesign: "签名设计",
    checkSignatureDesign: "请在下方写入您的签名",
    enterCheckSignatureDesign: "请在下方输入您的签名",
    reset: "重置",
    checkDesignTips: "请设计您的签名",
    customizeColor: "您可自定义签名颜色，请选择一个喜爱的颜色吧",
    hidden: "隐藏",
    confirmGeneration: "确认生成",
    signatureLocationDesign: "签名位置设计",
    mouseTips: "鼠标左键按住可拖动位置、悬停滚动滑轮可缩放大小",
    printDesign: "印花设计",
    favorite: "您可通过以下方式上传您喜爱的印花图案",
    selectLocalImages: "选择本地图片",
    transmitPictures: "扫码传输图片",
    historyTransfer: "历史传输",
    printPositionDesign: "印花位置设计",
    strugglingToLoad: "正在奋力加载中",
    pleaseGenerate: "请生成您的印花",
    mobileWebsiteL: "移动端官网扫一扫，上传您喜爱的定制图片",
    tip: "小贴士：建议使用透明背景的图片，设计效果会更好哦",
    step: "步骤提示",
    tips1: "移动端打开优你全球官网，点击扫一扫，扫描左侧二维码",
    tips2: "选择您相册中喜爱的定制图片",
    confirmSelect: "确认选择，等待图片上传完成",
    tips4: "图片上传成功后，您可按住图片拖动位置、缩放大小",
    uploaded: "历史传输中可查看您历史上传的图片",
    checkFavorite: "请选择你心仪的图片",
    cancel: "取消",
    confirmSelection: "确认选择",
    checkSignature: "请输入签名",
    writeSignatureTips: "请写入签名",
    mapToLoad: "请等待贴图加载完成",
    error: "错误",
    notCheckPrintPattern: "未选择印花图案",
    tipsText: "提示",
    noHistorical: "暂无历史传输内容",
    selectPicture: "请先选择图片"
  }
}
