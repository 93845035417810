class LocalCache {
  setCache(key, value, duration = 0, isNeedExpires = false) {
    let expires = 0
    if (duration) expires = Date.now() + duration * 1000
    if (isNeedExpires) {
      window.localStorage.setItem(key, JSON.stringify({ key, value }))
    } else {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }
  getCache(key) {
    const value = window.localStorage.getItem(key)
    let data = null
    if(value && value !== "undefined" && value !== undefined) {
      data = JSON.parse(value)
    }
    if (data) {
      if (Object.prototype.toString.call(data) === "[object Object]" && data.key === "expires_time") {
        if (data.value && data.value < Date.now() / 1000) {
          this.removeCache(key)
          return null
        } else {
          return true
        }
      } else {
        return data
      }
    }
  }

  getCacheExpiresTimeIsTrue(key) {
    const value = window.localStorage.getItem(key)
    let data = null
    if(value && value !== "undefined" && value !== undefined) {
      data = JSON.parse(value)
    }
    if (data) {
      if (Object.prototype.toString.call(data) === "[object Object]" && data.key === "expires_time") {
        if (data.value && data.value < Date.now() / 1000) {
          this.removeCache(key)
          return null
        } else {
          return true
        }
      } else {
        return data
      }
    }
  }

  removeCache(key) {
    window.localStorage.removeItem(key)
  }
  clearCache() {
    window.localStorage.clear()
  }
}
export default new LocalCache()
