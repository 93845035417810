import JSZip from "jszip"

export default {
  /**
   * file 转Base64 DataURL
   * @param {File} file
   * @returns
   */
  fileToBase64Async: (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        resolve(e.target.result)
      }
    })
  },
  // base64转blob
  dataURLtoBlob: (url) => {
    let arr = url.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  },
  // blob转file
  blobToFile: (blob, fileName) => {
    blob.lastModifiedDate = new Date() // 文件最后的修改日期
    blob.name = fileName // 文件名
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now()
    })
  },
  /**
   * 对于内联样式做rem转换
   * @param {String} px
   * @returns
   */
  px2rem(px) {
    if (/%/gi.test(px)) {
      // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
      return px
    } else {
      return parseFloat(px) / 192 + "rem"
    }
  },
  /**
   * 简单节流
   * @param {Function} fn
   * @param {number} delay
   * @returns
   */
  throttle(fn, delay = 600) {
    let flag = true
    return function () {
      if (!flag) return
      flag = false
      setTimeout(() => {
        fn.apply(this, arguments)
        flag = true
      }, delay)
    }
  },
  /**
   * 预加载图片
   * @param {Array} list [url,...]
   */
  preloadImages(list, cb) {
    let loadCount = 0
    // let loadingProcess = ""
    for (let img of list) {
      let image = new Image()
      image.src = img
      image.onload = () => {
        // console.log("image.onload:length:" + bgList.length)
        // console.log("image.onload:src:" + image.src)
        loadCount++
        // 计算图片加载的百分数，绑定到percent变量
        // let percentNum = Math.floor((loadCount / list.length) * 100)
        // loadingProcess = percentNum
        //判断是否结束
        if (loadCount >= list.length) {
          // console.log("preloadImages：图片预加载结束")
          cb && cb()
          //loading end
          // console.log("end:" + loadingProcess)
          // loadingShow.value = false
        }
      }
    }
  },
  /**
   * 复制文本
   * @param {string} text 需要复制的文本
   */
  copyText(text) {
    if (!navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText("text")
    } else {
      if (!document.execCommand("copy")) return Promise.reject()
      const textArea = document.createElement("textarea")
      textArea.style.position = "fixed"
      textArea.style.top = textArea.style.left = "-100vh"
      textArea.style.opacity = "0"
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((resolve, reject) => {
        let success = {
          data: {
            msg: "复制成功",
            code: 200
          }
        }
        let err = {
          data: {
            msg: "您的浏览器不支持",
            code: 400
          }
        }
        document.execCommand("copy") ? resolve(success) : reject(err)
        textArea.remove()
      })
    }
  },
  isObserveVisibility(target, options){
    let observer;
    return new Promise((resolve, reject) => {
      observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target)
            resolve(true) // 目标元素可见
          } else {
            resolve(false) // 目标元素不可见
          }
        })
      }, options)
      observer.observe(target)
    })
  }
}
