import BasicLayout from "@/layout/basic-layout.vue"
export default {
  path: "/about",
  name: "about",
  component: BasicLayout,
  children: [
    {
      path: "our_history",
      name: "ourHistory",
      component: () => import("@/views/about/our-history.vue")
    },
    {
      path: "sustainable_development",
      name: "sustainableDevelopment",
      component: () => import("@/views/about/sustainable-development.vue")
    }
  ]
}
