import BasicLayout from "@/layout/basic-layout.vue"

export default {
  path: "/home",
  name: "home",
  component: BasicLayout,
  children: [
    {
      path: "homePage",
      name: "homePage",
      component: () => import("@/views/home/index.vue")
    }
  ]
}
