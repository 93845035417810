import { createApp } from "vue"
import {
  create,
  NButton,
  NSelect,
  NInput,
  NRadioGroup,
  NRadio,
  NRadioButton,
  NForm,
  NFormItem,
  NGrid,
  NGridItem,
  NModal,
  NCollapse,
  NCollapseItem,
  NCarousel,
  NCarouselItem,
  NFormItemGi,
  NDatePicker,
  NAvatar,
  NCard,
  NSteps,
  NStep,
  NSpin,
  NSkeleton,
  NAffix,
  NBadge,
  NTooltip,
  NTabs,
  NTab,
  NTabPane,
  NDropdown,
  NUpload,
  NDrawer,
  NImage
} from "naive-ui"
const naive = create({
  components: [
    NButton,
    NSelect,
    NInput,
    NRadioGroup,
    NRadio,
    NRadioButton,
    NForm,
    NFormItem,
    NGrid,
    NGridItem,
    NModal,
    NCollapse,
    NCarouselItem,
    NCollapseItem,
    NCarousel,
    NFormItemGi,
    NDatePicker,
    NAvatar,
    NCard,
    NSteps,
    NStep,
    NSpin,
    NSkeleton,
    NAffix,
    NBadge,
    NTooltip,
    NTabs,
    NTab,
    NTabPane,
    NDropdown,
    NUpload,
    NDrawer,
    NImage
  ]
})
export default naive
