import BasicLayout from "@/layout/basic-layout.vue"
export default {
  path: "/customer_service",
  name: "customerService",
  component: BasicLayout,
  children: [
    {
      path: "contact_us",
      name: "contactUs",
      component: () => import("@/views/customer_service/contact-us.vue")
    }
  ]
}
